import { createContext, useContext } from "react"

type ContextProps = {
  loading: boolean
  user: any | null
  authenticated: boolean
  setUser: any
  isAdmin: boolean
  deferredPrompt?: any
  setDeferredPrompt?: any
  userId?: string
  setUserId?: any
}

export const AuthContext = createContext<Partial<ContextProps>>({})

type AuthProviderProps = {
  loading: boolean
  user: any | null
  setUser: any
  isAdmin: boolean
  children: React.ReactNode
  deferredPrompt?: any
  setDeferredPrompt?: any
  userId?: string
  setUserId?: any
}

export const AuthProvider = (props: AuthProviderProps) => {
  const {
    loading,
    user,
    setUser,
    children,
    isAdmin,
    deferredPrompt,
    setDeferredPrompt,
    userId,
    setUserId,
  } = props
  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated: user !== null,
        setUser,
        isAdmin,
        deferredPrompt,
        setDeferredPrompt,
        userId,
        setUserId,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const authContext = () => useContext(AuthContext)
