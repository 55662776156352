import * as yup from "yup"
import { t } from "i18next"
import styled from "styled-components"
import { theme } from "@project/shared"

export const burialPlaceOptions = [
  {
    target: "burial_place",
    label: "既に決まっている",
    value: "既に決まっている",
  },
  {
    target: "burial_place",
    label: "まだ決まっていない",
    value: "まだ決まっていない",
  },
]

export const burialMethodOptions = [
  {
    target: "burial_method",
    label: "先祖代々のお墓",
    value: "先祖代々のお墓",
  },
  {
    target: "burial_method",
    label: "自分用のお墓",
    value: "自分用のお墓",
  },
  {
    target: "burial_method",
    label: "樹木葬",
    value: "樹木葬",
  },
  {
    target: "burial_method",
    label: "納骨堂",
    value: "納骨堂",
  },
  {
    target: "burial_method",
    label: "散骨",
    value: "散骨",
  },
  {
    target: "burial_method",
    label: "その他",
    value: "その他",
  },
]

export const wishForBurialMethodOptions = [
  {
    target: "wish_for_burial_method",
    label: "新しくお墓を建てたい",
    value: "新しくお墓を建てたい",
  },
  {
    target: "wish_for_burial_method",
    label: "永代供養してほしい",
    value: "永代供養してほしい",
  },
  {
    target: "wish_for_burial_method",
    label: "散骨してほしい",
    value: "散骨してほしい",
  },
  {
    target: "wish_for_burial_method",
    label: "自宅に置いてほしい",
    value: "自宅に置いてほしい",
  },
  {
    target: "wish_for_burial_method",
    label: "その他",
    value: "その他",
  },
]

export const wishForBurialPlaceOptions = [
  {
    target: "wish_for_burial_place",
    label: "ある",
    value: "ある",
  },
  {
    target: "wish_for_burial_place",
    label: "ない",
    value: "ない",
  },
]

export const wishForSplitBonesOptions = [
  {
    target: "split_bones",
    label: "ある",
    value: "ある",
  },
  {
    target: "split_bones",
    label: "ない",
    value: "ない",
  },
]

export const splitBonesMemorialServiceOptions = [
  {
    target: "split_bones_memorial_service",
    label: "先祖の墓に入れてほしい",
    value: "先祖の墓に入れてほしい",
  },
  {
    target: "split_bones_memorial_service",
    label: "散骨してほしい",
    value: "散骨してほしい",
  },
  {
    target: "split_bones_memorial_service",
    label: "手元供養してほしい",
    value: "手元供養してほしい",
  },
  {
    target: "split_bones_memorial_service",
    label: "本山に納めてほしい",
    value: "本山に納めてほしい",
  },
  {
    target: "split_bones_memorial_service",
    label: "その他",
    value: "その他",
  },
]

export const validationSchema = yup.object().shape({
  burial_place: yup.string().nullable().required(t("Required")),
  burial_method: yup.string().nullable(),
  wish_for_burial_method: yup.string().nullable(),
  location: yup.string(),
  company_name: yup.string(),
  contact: yup.string(),
  others: yup.string(),
  wish_for_burial_place: yup.string().nullable(),
  others_in_details: yup.string(),
  split_bones: yup.string().nullable(),
  split_bones_memorial_service: yup.string().nullable(),
  split_bones_other_details: yup.string(),
})

export const Container = styled.div`
  border: 1px solid #dddcdc;
  border-radius: 10px;
  padding: 0px 40px 40px;

  @media (max-width: 786px) {
    margin-top: 30px;
    border: none;
    padding: 0px;
  }
`
export const TextFieldContainer = styled.div`
  margin-top: 30px;

  .text-field {
    width: 360px;
  }

  .dynamic-textarea {
    width: 360px !important;

    @media (max-width: 786px) {
      width: 100% !important;
    }
  }

  input {
    background-color: ${theme.background.primary};
    border: 1px solid #dedbd5;
    border-radius: 10px;
    box-shadow: none;
  }

  .email_address {
    width: 100%;
  }

  .select-component {
    width: 360px;

    @media (max-width: 786px) {
      width: 100%;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    background: #f8f5ef;
    border: 1px solid #dedbd5;
    border-radius: 10px;

    @media (max-width: 786px) {
      height: 50px;
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    margin-top: 10px;
    font-size: 16px;

    @media (max-width: 786px) {
      font-size: 16px;
    }
  }

  .text-area {
    textarea {
      background-color: ${theme.background.primary};
      border: 1px solid #dedbd5;
      border-radius: 10px;
      box-shadow: none;
    }
  }

  @media (max-width: 786px) {
    .text-field {
      height: 65px;
      width: 100%;
    }
  }
`

export const LabelContainer = styled.div`
  margin-bottom: 20px;
  @media (max-width: 786px) {
    margin-bottom: 14px;
  }
`

export const Label = styled.div`
  font-weight: 500;
  font-size: 16px;

  ::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba(226, 110, 48, 0.2);
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  @media (max-width: 786px) {
    font-size: 16px;

    ::before {
      width: 12px;
      height: 12px;
    }
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ErrorStyled = styled.div`
  font-size: 16px;
  font-family: ${theme.font.NS};
  font-weight: ${theme.text.weight.regular};
  color: ${theme.alert};
  margin-top: 2px;
  margin-left: 2px;
  display: block;
`

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 35px;

  .confirm-btn {
    width: 200px;
    height: 50px;
    font-weight: 700;
    font-size: 18px;

    @media (max-width: 786px) {
      width: 240px;
      height: 60px;
    }
  }
`
