import {
  ADD_USER_AUTH_STATE,
  ADD_USER_DETAIL,
  CLEAR_USER_AUTH_STATE,
  LOCK_CONTENTS,
  MUTATE_LOCKED_DATA,
  OPEN_LOCK_MODAL,
  ADD_VIEWERS,
} from "./Constants"
import React, { useReducer, createContext, useContext } from "react"
import { GlobalStateTypes } from "../interface"
const GlobalContext = createContext(null)

const initialState: GlobalStateTypes = {
  userAuthState: {},
  lockedModalState: false,
  userAccessibilityData: {
    isLoading: true,
  },
  itemsClicked: "",
  viewers: {},
}

/**
 * @params state and action
 * @returns updated state.
 */

const reducer = (state: GlobalStateTypes, action) => {
  switch (action.type) {
    case ADD_USER_AUTH_STATE:
      return {
        ...state,
        userAuthState: action.payload.data,
      }
    case CLEAR_USER_AUTH_STATE:
      return {
        ...state,
        userAuthState: {},
      }
    case ADD_USER_DETAIL:
      return {
        ...state,
        userAccessibilityData: action?.payload?.data,
      }
    case LOCK_CONTENTS:
      return {
        ...state,
        itemsClicked: action?.payload?.key,
      }
    case OPEN_LOCK_MODAL:
      return {
        ...state,
        lockedModalState: !state.lockedModalState,
      }
    case MUTATE_LOCKED_DATA:
      return {
        ...state,
        userAccessibilityData: {
          ...state?.userAccessibilityData,
          ...action?.payload?.data,
        },
      }

    case ADD_VIEWERS:
      return {
        ...state,
        viewers: { ...action?.payload?.data },
      }
  }
}

const GlobalContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  )
}
export default GlobalContextProvider

/**
 *
 *  using the context
 */

export const useGlobalContext = () => useContext(GlobalContext)
