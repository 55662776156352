import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../../components/organisms/RelativesFriendsForm/helper"
import { getConsultMemoById } from "../../../services/consultMemo"
import { useQuery } from "react-query"
import { Button, Loader } from "@project/shared"
import { EditOutlined } from "@ant-design/icons"
import router from "next/router"

const ContentWrapper = styled.div`
  border: 1px solid #dddcdc;
  border-radius: 10px;
  @media (max-width: 786px) {
    margin-top: 30px;
    border: none;
    padding: 0px;
  }
  .info-text {
    color: #d86464;
  }
`
const TitleWrapper = styled.div`
  background: #f8f5ef;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  .content {
    color: var(--dark_gray, #313131);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 46px;
  }
`
const FreeNotesWrapper = styled.div`
  margin: 0 30px;
`
const LoaderWrapper = styled.div`
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
interface iProps {
  id?: any
  isViewer?: boolean
}

const ConsultationMemoDetailForm: React.FC<iProps> = ({ id, isViewer }) => {
  const { t } = useTranslation()

  const {
    data: ConsultationData,
    isLoading,
    isFetching,
  } = useQuery<any, Error>(
    ["consult-memo-by-id", id],
    () => getConsultMemoById(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!id,
      select: ({ data }) => {
        return {
          id: id || "",
          consultation_title: data?.consultation_title || "",
          free_notes: data?.free_notes || [],
        }
      },
    }
  )

  if (isLoading || isFetching) {
    return (
      <LoaderWrapper>
        <Loader size="large" />
      </LoaderWrapper>
    )
  }
  return (
    <>
      <ContentWrapper>
        <TextFieldContainer>
          <TitleWrapper>
            <div className="content">
              {ConsultationData?.consultation_title}
            </div>
            {!isViewer && (
              <div className="edit-btn">
                <Button
                  background={"#E26E30"}
                  className="btn"
                  onClick={() => router.push(`/consultation-memo/edit/${id}`)}
                >
                  <div>
                    <EditOutlined />
                    &nbsp;
                    {t("Edit")}
                  </div>
                </Button>
              </div>
            )}
          </TitleWrapper>
        </TextFieldContainer>
        <FreeNotesWrapper>
          {ConsultationData?.free_notes?.map((note, index) => {
            return (
              <TextFieldContainer key={index}>
                <div className="free-notes">
                  <LabelContainer>
                    <Label>{t("Free notes")}</Label>
                  </LabelContainer>
                  <div className="content padding-left">{note?.note}</div>
                </div>
                <div className="border-bottom"></div>
              </TextFieldContainer>
            )
          })}
        </FreeNotesWrapper>
      </ContentWrapper>
    </>
  )
}

export { ConsultationMemoDetailForm }
