import React, { useState } from "react"
import { Breadcrumb, Grid, Modal } from "antd"
import styled from "styled-components"
import { RightOutlined } from "@ant-design/icons"
import { theme } from "@project/shared"
import { BreadcrumbComponentProps, BreadcrumbItem } from "../../../interface"
import { useTranslation } from "react-i18next"

const { Item } = Breadcrumb

const BreadcrumbStyled = styled(Breadcrumb)`
  display: flex;
  align-items: center;
  min-height: 35px;
  margin: auto;
  width: 100%;
  & a {
    color: ${theme.text.color.light};
  }
  .anticon svg {
    width: 10px;
    height: 10px;
    color: ${theme.text.color.light};
  }
  @media (max-width: 786px) {
    min-height: 0;
    display: none;
  }
`

const ItemStyled = styled(Item)`
  font-size: 16px !important;
  line-height: 19px;
  color: ${theme.text.color.light};
  .display-name {
    font-size: 16px;
    @media (max-width: 786px) {
      font-size: 16px;
    }
  }
`

const IconWrapper = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 10px;
  cursor: pointer;
  @media (max-width: 373px) {
    position: relative;
    bottom: 1px;
  }
`

const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px !important;
    border: 1px solid #808080 !important;
  }
  .ant-modal-close {
    top: -55px !important;
    background: white;
    border-radius: 100px;
    height: 48px !important;
    width: 48px !important;
    line-height: 48px;
  }
  .ant-modal-close-icon {
    position: absolute;
    top: 16px;
    right: 15px;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
`
const ModalBodyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 0 45px 33px;
  text-align: start;
  @media (max-width: 576px) {
    padding: 21px;
  }
`
const ModalTextWrapper = styled.div`
  font-family: ${(props) => props.theme.font.BUS};
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-size: ${(props) => props.theme.text.size.s};
  .icon {
    margin-right: 11px;
    @media (max-width: 576px) {
      position: relative;
      right: 10px;
    }
  }
`
const ModalIconWrapper = styled.img`
  height: 28px;
  width: 28px;
  margin-right: 13px;
`

const renderBreadcrumbItem = (
  items: BreadcrumbItem[],
  showIcon: boolean,
  handleButton: () => void
): JSX.Element[] => {
  const breadcrumbItems: JSX.Element[] = []
  for (const item of items) {
    breadcrumbItems.push(
      <ItemStyled key={items.indexOf(item)} onClick={item.onClick}>
        <a href={item.href} className="display-name">
          {item.name}
        </a>
        {showIcon && items.indexOf(item) === items.length - 1 && (
          <span>
            {" "}
            <IconWrapper
              onClick={handleButton}
              src="/assets/icons/info_icon.svg"
            />
          </span>
        )}
      </ItemStyled>
    )
  }
  return breadcrumbItems
}

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = ({
  items,
  showInfoIcon = false,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const { t } = useTranslation()
  const { useBreakpoint } = Grid
  const breakPoint = useBreakpoint()

  const handleButton = () => {
    setShowModal((prev) => !prev)
  }

  return (
    <BreadcrumbStyled separator={<RightOutlined width={8} height={10} />}>
      {items && renderBreadcrumbItem(items, showInfoIcon, handleButton)}
      <ModalWrapper
        closable={true}
        visible={showModal}
        onCancel={handleButton}
        width={breakPoint.xs ? "350px" : "729px"}
        centered
        footer={null}
      >
        <ModalBodyWrapper>
          <ModalIconWrapper src="/assets/icons/info_icon.svg" />
          <ModalTextWrapper>
            {t(
              "You can add new information and decide to share or private information with family"
            )}
          </ModalTextWrapper>
        </ModalBodyWrapper>
      </ModalWrapper>
    </BreadcrumbStyled>
  )
}

export { BreadcrumbComponent }
