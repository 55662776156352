import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 16px 0 40px 0;

  .backBtn {
    @media (max-width: 786px) {
      display: none;
    }
  }

  @media (max-width: 786px) {
    margin: 0px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 786px) {
    display: none;
  }
`
export const ImgWrapper = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.table.headerBackground};
  min-width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

export const Title = styled.div`
  font-size: ${(props) => props.theme.text.size.sm};
  color: ${(props) => props.theme.text.color.light};
  margin-left: 14px;
`

export const SuffixIconWrapper = styled.div`
  margin-left: 35px;
  position: relative;
  top: 3px;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  @media (max-width: 786px) {
    margin-top: 0px;
    justify-content: flex-end;
  }

  .rightBtn {
    border: 1px solid #dddcdc;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
    height: 60px;
    width: 250px;
    font-weight: 500;
    font-size: 16px;

    :hover {
      border: 1px solid #dddcdc;
    }

    @media (max-width: 786px) {
      height: 50px;
      margin-bottom: 20px;
      margin-top: 30px;

      span,
      .anticon svg {
        font-size: 16px;
      }
    }
  }
`

export const LockUnLock = styled.div`
  margin-left: 15px;
  cursor: pointer;
`
export const LinkBox = styled.div`
  display: flex;
  align-items: baseline;
  cursor: pointer;
`
export const LinkText = styled.div`
  font-family: ${(props) => props.theme.font.BUPS};
  font-size: ${(props) => props.theme.text.size.s};
  line-height: 24px;
  font-weight: ${(props) => props.theme.text.weight.medium};
  text-decoration: underline;
  word-break: keep-all;
  @media (max-width: 786px) {
    margin-top: 19px;
  }
`
export const IconBox = styled.div`
  margin-left: 22px;
  height: 8px;
  width: 13px;
`
