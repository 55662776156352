import {
  CARE,
  HOSPITALIZATION,
  OTHERS,
} from "@project/shared/src/utils/constant"
import { t } from "i18next"

/**
 * HOSPITALIZATION CARE TYPES OPTIONS
 */
export const hospitalizationOrCareType = [
  { label: t("Hospitalization"), value: HOSPITALIZATION },
  { label: t("Care house"), value: CARE },
]

/**
 * SERIOUSLY ILL OPTIONS
 */
export const seriouslyIllOption = [
  { label: t("Don’t hide and tell me"), value: "包み隠さず教えてほしい " },
  { label: t("Don’t tell anyone"), value: "告知しないでほしい " },
  { label: t("Only tell hospital name"), value: "病名だけは教えてほしい" },
  { label: t("Only tell life expectancy"), value: "余命だけは教えてほしい" },
  { label: t("Others"), value: OTHERS },
]

/**
 * LIFE PROLONGING TREATMENT OPTIONS
 */
export const lifeProlongingTreatmentOptions = [
  {
    label: t("Treatment is not necessary if no chance of recovery"),
    value: "延命治療してほしい",
  },
  {
    label: t("Treatment if chance of recovery"),
    value: "回復の見込みがあればする",
  },
  {
    label: t("Treatment in any situation"),
    value: "延命治療はしない",
  },
  { label: t("Others"), value: OTHERS },
]

/**
 * MONEY FOR HOSPITALIZATION OPTIONS
 */
export const moneyForHospitalization = [
  { label: t("Use my deposit"), value: "私の預金を使ってほしい" },
  { label: t("Use my insurance"), value: "保険を使用してほしい" },
  { label: t("Not prepared in particular"), value: "特に用意していない" },
  { label: t("Others"), value: OTHERS },
]

/**
 * BODY ORGAN DANATION OPTIONS
 */
export const bodyOrganDonationOption = [
  {
    label: t("Registered as a donor for organ donation"),
    value: "臓器提供についてドナー登録している",
  },
  {
    label: t("I have a body donation registration"),
    value: "献体登録をしている",
  },
  { label: t("Nothing in particular"), value: "特に希望しない" },
]

/**
 * LONG TERM CARE OPTIONS
 */
export const longTermCareOption = [
  {
    label: t("Want to be looked by family at home"),
    value: "自宅で家族に見てほしい ",
  },
  {
    label: t("Want to be looked by a helper at home"),
    value: "自宅でヘルパーさんに見てほしい",
  },
  {
    label: t("I want to enter the facility and take care"),
    value: "施設に入って介護してほしい",
  },
  { label: t("Leave it to family"), value: "家族の判断に任せる" },
]

/**
 * CARE HOUSE LOCATION OPTIONS
 */
export const careHouseOption = [
  {
    label: t("Where families can easily come to see"),
    value: "家族が会いに来やすいところ",
  },
  { label: t("A lush and quiet place"), value: "緑豊かで静かな場所" },
  {
    label: t("Convenient place with a station and shopping street"),
    value: "駅や商店街があり生活に便利なところ",
  },
  {
    label: t("A lively place adjacent to a nursery school"),
    value: "保育園や学校が隣接しているにぎやかな場所",
  },

  {
    label: t("Places where can take a walk, park or a plaza nearby"),
    value: "近くに公園や広場など散歩ができる場所",
  },
  { label: t("No particular desire"), value: "特にこだわりはない" },

  { label: t("Others"), value: OTHERS },
]

/**
 * MEALS ALLERGIES OPTIONS
 */
export const mealsAllergiesOptions = [
  { label: t("Yes"), value: "ある" },
  { label: t("No"), value: "ない" },
]

/**
 * CARE FACILITY COST OPTIONS
 */
export const careFacilityCostOption = [
  { label: t("I have cash"), value: "現金を用意している" },
  { label: t("Enrolled in insurance"), value: "保険に加入している" },
  { label: t("Not prepared in particular"), value: "特に用意していない" },
  { label: t("Others"), value: OTHERS },
]

export const careHouseFacility = [
  {
    label: t("Facility or room where i can enjoy my hobbies"),
    value: "趣味ができる設備・部屋がほしい",
  },
  { label: t("A room with a garden"), value: "庭がついている部屋がいい" },
  {
    label: t("Full or recreations and events"),
    value: "レクリエーションやイベントが充実している",
  },
  {
    label: t("Where alcohol and cigarettes are ok"),
    value: "お酒やたばこなど趣向品OK",
  },
  {
    label: t("Where is food is delicious"),
    value: "食事が美味しいところ",
  },
  {
    label: t("Where clean and equipped"),
    value: "設備がきれいで整っている",
  },
  { label: t("Not specific"), value: "特にこだわりはない" },

  { label: t("Others"), value: OTHERS },
]
