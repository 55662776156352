import router from "next/router"
import styled from "styled-components"
import Link from "next/link"
import React from "react"

import { authContext } from "../../../utils"

const FooterWrapper = styled.div`
  height: 100px;
  background-color: ${(props) => props.theme.background.primary};
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  .link {
    margin-left: 40px;
    font-size: 16px;
    text-decoration: none;
    border-bottom: black 1px solid;
    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 720px) {
    height: 80px;
    .link {
      margin-left: 15px;
      font-size: 16px;
    }
  }
  @media (max-width: 375px) {
    .link {
      font-size: 16px;
    }
  }

  .logo-wrapper {
    &:hover {
      cursor: pointer;
    }
  }
`

const Logo = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 0px 0px;
  padding: 30px 35px;
  bottom: 0;
  left: 20px;

  .mobile-logo {
    display: none;
  }

  .desktop-logo {
    width: 210px;
    height: 61px;
  }
  @media (max-width: 720px) {
    padding: 8px 18px;
    .desktop-logo {
      display: none;
    }
    .mobile-logo {
      display: block;
      width: 94px;
      height: 74px;
    }
  }
  @media (max-width: 375px) {
    left: 15px;
  }
  @media (max-width: 345px) {
    padding: 8px 8px;
  }
`

const Wrapper = styled.div`
  display: flex;
  margin-right: 40px;

  .last-links {
    display: flex;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    margin-right: 20px;
    .first-link {
      width: 150px;
    }
    .last-links {
      margin-top: 15px;
    }
  }
  @media (max-width: 375px) {
    margin-right: 0;
  }

  & a {
    :hover {
      color: rgba(0, 0, 0, 0.85);
    }
  }
`

const Footer: React.FC = () => {
  const { authenticated } = authContext()

  const onClickHandler = () => {
    if (authenticated) {
      router.push("/top")
    } else {
      router.push("/")
    }
  }

  return (
    <FooterWrapper>
      <Logo onClick={onClickHandler} className="logo-wrapper">
        <img
          className="desktop-logo"
          src="/assets/imgs/footer-logo.svg"
          alt="logo"
        />
        <img
          className="mobile-logo"
          src="/assets/imgs/footer-logo-mobile.svg"
          alt="logo"
        />
      </Logo>
      <Wrapper>
        <Link href="https://www.sunlife.jp/privacy_policy/">
          <a target="_blank" rel="noopener noreferrer">
            <div className="link first-link">プライバシポリシー</div>
          </a>
        </Link>
        <div className="last-links">
          <Link href="https://www.sunlife.jp/kakioki/kiyaku.html">
            <a target="_blank" rel="noopener noreferrer">
              <div className="link">利用規約</div>
            </a>
          </Link>
          <Link href="https://www.sunlife.jp/company">
            <a target="_blank" rel="noopener noreferrer">
              <div className="link">運営会社情報</div>
            </a>
          </Link>
        </div>
      </Wrapper>
    </FooterWrapper>
  )
}

export { Footer }
