import React, { useEffect } from "react"
import { t } from "i18next"
import Router from "next/router"
import { useFormik } from "formik"
import { useMutation, useQuery } from "react-query"
import { TextField, Button, theme, Loader, Select } from "@project/shared"
import { notification } from "antd"
import {
  getGraveAndOfferingById,
  createGraveAndOffering,
  updateGraveAndOffering,
} from "../../../services/graveAndOfferings"
import {
  Label,
  Container,
  ButtonWrapper,
  LoaderWrapper,
  LabelContainer,
  validationSchema,
  TextFieldContainer,
  burialPlaceOptions,
  burialMethodOptions,
  wishForBurialPlaceOptions,
  wishForBurialMethodOptions,
  wishForSplitBonesOptions,
  splitBonesMemorialServiceOptions,
} from "./helper"
import FormExternalMenus from "../FormExternalMenus"

interface Props {
  id?: string | string[]
}

const GraveAndOfferingForm: React.FC<Props> = ({ id }) => {
  const {
    data: graveAndOfferingData,
    isLoading: graveAndOfferingLoading,
    isFetching: graveAndOfferingFetching,
  } = useQuery(
    ["graveAndOffering", id],
    () => id && getGraveAndOfferingById(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      select: (response: any) => {
        return {
          burial_place: response?.data?.burial_place,
          burial_method: response?.data?.burial_method,
          burial_method_wish: response?.data?.burial_method_wish,
          graveyard_location: response?.data?.graveyard_location,
          graveyard_company: response?.data?.graveyard_company,
          contact_info_graveyard: response?.data?.contact_info_graveyard,
          other_method_details: response?.data?.other_method_details,
          burial_place_wish: response?.data?.burial_place_wish,
          other_place_details: response?.data?.other_place_details,
          split_bones: response?.data?.split_bones,
          split_bones_memorial_service:
            response?.data?.split_bones_memorial_service,
          split_bones_other_details: response?.data?.split_bones_other_details,
        }
      },
    }
  )

  const initialValues = graveAndOfferingData || {
    burial_place: null,
    burial_method: null,
    burial_method_wish: null,
    graveyard_location: "",
    graveyard_company: "",
    contact_info_graveyard: "",
    other_method_details: "",
    burial_place_wish: null,
    other_place_details: "",
    split_bones: null,
    split_bones_memorial_service: null,
    split_bones_other_details: "",
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (id) {
        mutate({ ...values, id: id })
      } else {
        mutate(values)
      }
    },
  })
  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])
  const { mutate, isLoading } = useMutation(
    id ? updateGraveAndOffering : createGraveAndOffering,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: t("Thoughts on grave and offerings has been updated."),
          })
        } else {
          notification.success({
            message: t("Thoughts on grave and offerings has been added."),
          })
        }
        Router.push("/my-thoughts/graves")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )

  const onChangeHandler = (name, value) => {
    formik.setFieldValue(name, value)

    if (name === "burial_place") {
      if (value === "既に決まっている") {
        formik.setFieldValue("burial_method_wish", null)
      } else if (value === "まだ決まっていない") {
        formik.setFieldValue("burial_method", null)
      }
    }
  }

  if (graveAndOfferingLoading || graveAndOfferingFetching)
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )

  /**
   * External link menusItems.
   */
  const externalMenusItem = [
    {
      title: "自宅に持つお墓「永遠の住処」",
      path: "/preparing",
      image: "image8.jpg",
    },
    {
      title: "海の散骨/山の散骨",
      path: "https://www.moshimo.net/plan/nature/",
      image: "image9.jpg",
    },
    {
      title: "高級公園墓地　東京霊園",
      path: "https://www.tokyoreien.or.jp/",
      image: "image10.jpg",
    },
  ]
  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Burial place for ashes")}</Label>
          </LabelContainer>
          <Select
            type="validate"
            name="burial_place"
            className="select-component"
            allowClear
            value={formik.values.burial_place || null}
            options={burialPlaceOptions}
            onChange={(value) => onChangeHandler("burial_place", value)}
            placeholder={t("Burial place for ashes")}
            error={
              formik.touched.burial_place && formik.errors?.burial_place
                ? formik.errors?.burial_place
                : false
            }
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>
              {t("(If you select 'Already decided') Burial method")}
            </Label>
          </LabelContainer>
          <Select
            type="validate"
            name="burial_method"
            allowClear
            className="select-component"
            value={formik.values.burial_method || null}
            options={burialMethodOptions}
            onChange={(value) => onChangeHandler("burial_method", value)}
            placeholder={t("Burial place")}
            error={
              formik.touched.burial_method && formik.errors?.burial_method
                ? formik.errors?.burial_method
                : false
            }
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Location of burial site")}</Label>
          </LabelContainer>
          <TextField
            name="graveyard_location"
            placeholder={t("Location of burial site")}
            value={formik.values.graveyard_location}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.graveyard_location &&
              formik.errors?.graveyard_location
            }
            showCounting
            maxLength={50}
            className="dynamic-textarea"
            type="dynamic-textarea"
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Company name / Management of burial place")}</Label>
          </LabelContainer>
          <TextField
            name="graveyard_company"
            placeholder={t("Company name / Management of burial place")}
            value={formik.values.graveyard_company}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.graveyard_company &&
              formik.errors?.graveyard_company
            }
            showCounting
            maxLength={50}
            className="dynamic-textarea"
            type="dynamic-textarea"
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Contact information of burial place")}</Label>
          </LabelContainer>
          <TextField
            name="contact_info_graveyard"
            placeholder={t("Contact information of burial place")}
            value={formik.values.contact_info_graveyard}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.contact_info_graveyard &&
              formik.errors?.contact_info_graveyard
            }
            showCounting
            maxLength={50}
            className="dynamic-textarea"
            type="dynamic-textarea"
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>
              {t("(If you select 'Not decided') Wish for burial method")}
            </Label>
          </LabelContainer>
          <Select
            type="validate"
            name="burial_method_wish"
            allowClear
            className="select-component"
            value={formik.values.burial_method_wish || null}
            options={wishForBurialMethodOptions}
            onChange={(value) => onChangeHandler("burial_method_wish", value)}
            placeholder={"遺骨の埋葬方法"}
            error={
              formik.touched.burial_method_wish &&
              formik.errors?.burial_method_wish
                ? formik.errors?.burial_method_wish
                : false
            }
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Others in details")}</Label>
          </LabelContainer>
          <TextField
            name="other_method_details"
            placeholder={t("Others in details")}
            type="textarea"
            rows={4}
            className="text-area"
            value={formik.values.other_method_details}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.other_method_details &&
              formik.errors?.other_method_details
            }
            showCounting
            maxLength={300}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Wish for a burial place")}</Label>
          </LabelContainer>
          <Select
            type="validate"
            name="burial_place_wish"
            allowClear
            className="select-component"
            value={formik.values.burial_place_wish || null}
            options={wishForBurialPlaceOptions}
            onChange={(value) => onChangeHandler("burial_place_wish", value)}
            placeholder={t("Burial place")}
            error={
              formik.touched.burial_place_wish &&
              formik.errors?.burial_place_wish
                ? formik.errors?.burial_place_wish
                : false
            }
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Others in details")}</Label>
          </LabelContainer>
          <TextField
            name="other_place_details"
            placeholder="例（散骨の場合）相模湾 例（お墓の場合）○○市内の霊園"
            type="textarea"
            rows={4}
            className="text-area"
            value={formik.values.other_place_details}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.other_place_details &&
              formik.errors?.other_place_details
            }
            showCounting
            maxLength={300}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Wish for a split bones")}</Label>
          </LabelContainer>
          <Select
            type="validate"
            name="split_bones"
            allowClear
            className="select-component"
            value={formik.values.split_bones || null}
            options={wishForSplitBonesOptions}
            onChange={(value) => onChangeHandler("split_bones", value)}
            placeholder={t("Wish for a split bones")}
            error={
              formik.touched.split_bones && formik.errors?.split_bones
                ? formik.errors?.split_bones
                : false
            }
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Memorial service for a split bones")}</Label>
          </LabelContainer>
          <Select
            type="validate"
            name="split_bones_memorial_service"
            allowClear
            className="select-component"
            value={formik.values.split_bones_memorial_service || null}
            options={splitBonesMemorialServiceOptions}
            onChange={(value) =>
              onChangeHandler("split_bones_memorial_service", value)
            }
            placeholder={t("Memorial service for a split bones")}
            error={
              formik.touched.split_bones_memorial_service &&
              formik.errors?.split_bones_memorial_service
                ? formik.errors?.split_bones_memorial_service
                : false
            }
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Split bones other details")}</Label>
          </LabelContainer>
          <TextField
            name="split_bones_other_details"
            placeholder={t("Others in details")}
            type="textarea"
            rows={4}
            className="text-area"
            value={formik.values.split_bones_other_details}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.split_bones_other_details &&
              formik.errors?.split_bones_other_details
            }
            showCounting
            maxLength={300}
          />
        </TextFieldContainer>
        <ButtonWrapper>
          <Button
            htmlType="submit"
            background={theme.button.primary}
            className="confirm-btn"
            loading={isLoading}
          >
            {id ? t("Update") : t("Add")}
          </Button>
        </ButtonWrapper>
        {/**
         * External menus
         */}

        <FormExternalMenus menuItems={externalMenusItem} />
      </form>
    </Container>
  )
}

export { GraveAndOfferingForm }
