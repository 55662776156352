import { Carousel } from "antd"
import React, { useRef } from "react"
import styled from "styled-components"
import { theme } from "@project/shared"

const CarouselComponent = styled(Carousel)`
  > .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #dbdbdb;
  }
  > .slick-dots li.slick-active button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #808080;
  }

  @media (max-width: 750px) {
    > .slick-dots li button {
      display: none;
    }
  }
`

const SliderContainer = styled.div`
  .ant-carousel {
    cursor: grab;
    :active {
      cursor: grabbing;
    }
  }
  position: relative;

  .arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
  }

  .prev {
    left: 0px;
  }

  .next {
    right: 0px;
  }
  .text-desktop {
    font-size: 22px;
    line-height: 37px;
  }
  @media (max-width: 1070px) {
    .arrows {
      img {
        width: 40px;
      }
    }
  }

  @media (max-width: 750px) {
    .arrows {
      img {
        width: 30px;
      }
    }
  }
`

const SliderWrapper = styled.div`
  height: 580px;
  color: #000;
  background: ${theme.background.primary};
  position: relative;

  .text-wrapper {
    position: absolute;
    top: 120px;
    left: 200px;

    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: ${theme.background.navBar.secondary};
    }
    .break-out {
      display: none;
    }
    .slider-title-mobile {
      display: none;
    }

    .text {
      width: 500px;
    }
  }
  .text-mobile {
    display: none;
  }

  img {
    position: absolute;
    right: 0;
    bottom: 0px;
  }

  .mobile-img {
    display: none !important;
  }

  .slider1 {
    width: 1000px;
  }

  .slider2 {
    width: 700px;
    bottom: -30px;
  }

  .slider3 {
    width: 600px;
    right: 50px;
  }

  .slider4 {
    width: 750px;
    bottom: 30px;
  }

  @media (max-width: 1370px) {
    .text-wrapper {
      left: 100px;

      .text {
        width: 440px;
      }
    }

    .slider2 {
      width: 630px;
      bottom: -30px;
    }

    .slider4 {
      right: -50px;
    }
  }

  @media (max-width: 1200px) {
    .text-wrapper {
      left: 50px;
    }

    .slider2 {
      width: 550px;
      bottom: -0px;
    }

    .slider3 {
      width: 550px;
      right: 30px;
    }

    .slider4 {
      width: 750px;
      bottom: 30px;
    }
  }

  @media (max-width: 1070px) {
    .text-wrapper {
      .title {
        font-size: 22px;
        line-height: 35px;
      }

      .text {
        width: 380px;
      }

      .text-desktop {
        font-size: 20px;
        line-height: 32px;
      }
    }

    .slider1 {
      width: 850px;
      bottom: 15px;
    }

    .slider2 {
      width: 550px;
      bottom: 20px;
    }

    .slider3 {
      width: 490px;
    }

    .slider4 {
      width: 650px;
      bottom: 50px;
    }
  }

  @media (max-width: 950px) {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    text-align: left;
    height: 631px;

    .text-wrapper {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0 20px;

      .title {
        width: 100%;
      }

      .text-desktop {
        display: none;
      }
    }

    .text-mobile {
      display: block;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      padding: 0 20px;
    }

    .slider2-text {
      margin-top: -50px;
    }

    img {
      position: relative;
      left: 0;
    }

    .slider1 {
      width: 750px;
      bottom: 10px;
    }

    .slider2 {
      width: 450px;
      top: -40px;
    }

    .slider3 {
      width: 420px;
      bottom: 10px;
    }

    .slider4 {
      width: 550px;
      bottom: 0px;
    }
  }

  @media (max-width: 750px) {
    position: relative;

    .slider1 {
      width: 100%;
      bottom: 10px;
    }

    .slider2 {
      width: 430px;
      top: 0px;
    }

    .slider3 {
      width: 420px;
      bottom: 40px;
      left: 10px;
    }

    .slider4 {
      width: 550px;
      bottom: 20px;
      left: 10px;
    }
    .text-wrapper {
      position: absolute;
      .title {
        position: absolute;
        top: 37px;
      }

      .slider-title-mobile {
        display: block;
      }

      .slider-title {
        display: none;
      }
    }
    .text-mobile {
      position: absolute;
      bottom: 37px;
      padding: 0 20px;
    }
  }

  @media (max-width: 500px) {
    height: 700px;
    .desktop-img {
      display: none !important;
    }
    .mobile-img {
      display: block !important;
    }
    .slider1 {
      width: 350px;
      bottom: 32px;
    }
    .slider3 {
      width: 350px;
    }
    .slider4 {
      width: 500px;
    }
    .text-mobile {
      bottom: 40px;
    }
  }
  @media (max-width: 405px) {
    .text-mobile {
      bottom: 35px;
    }
  }
  @media (max-width: 374px) {
    .slider1 {
      width: 100%;
      bottom: 50px;
    }
    .slider2 {
      top: -20px;
    }
    .slider3 {
      width: 100%;
    }
    .text-mobile {
      bottom: 30px;
    }
    .text-wrapper {
      .title {
        font-size: 22px;
        line-height: 30px;
      }
      .break-out {
        display: inline-block;
      }
    }
  }
  @media (max-width: 370px) {
    .slider1 {
      width: 100%;
      bottom: 75px;
    }
  }
`

const Slider = () => {
  const slider = useRef(null)

  return (
    <SliderContainer>
      <div className="arrows prev" onClick={() => slider.current.prev()}>
        <img src="/assets/icons/prev.svg" alt="prev" />
      </div>
      <CarouselComponent
        autoplay
        autoplaySpeed={5000}
        ref={slider}
        swipeToSlide
        draggable
      >
        <SliderWrapper>
          <div className="text-wrapper">
            <p className="title">
              忘れちゃいけない
              <br />
              ことをKaki-Oki(書置き)する
              <br className={"break-out"} />
              ノート
            </p>
            <div className="text">
              <span className="text-desktop">
                印鑑・通帳の保管場所、パスワードや連絡先など、記録しておけば大事な時に「思い出せない…」なんてことがなくなり安心。ノートをシェアしていても、知られたくない情報にはカギをかけておけば見られる心配もありません。
              </span>
            </div>
          </div>
          <img
            className="slider1 desktop-img"
            src="/assets/imgs/slider1.png"
            alt="slider1"
          />
          <img
            className="slider1 mobile-img"
            src="/assets/imgs/slider1mobile.png"
            alt="slider1mobile"
          />

          <span className="text-mobile">
            印鑑・通帳の保管場所、パスワードや連絡先など、記録しておけば大事な時に「思い出せない…」なんてことがなくなり安心。ノートをシェアしていても、知られたくない情報にはカギをかけておけば見られる心配もありません。
          </span>
        </SliderWrapper>
        <SliderWrapper>
          <div className="text-wrapper ">
            <p className="title slider-title">
              思い立ったらすぐにメモ、
              <br />
              いつも手元にあるから安心
            </p>
            <p className="title slider-title-mobile">
              思い立ったらすぐにメモ、
              <br />
              いつも手元にあるから安心
            </p>
            <div className="text">
              <span className="text-desktop">
                スマホで管理ができるので、自宅にいなくても思い立ったらすぐにメモできて便利。紙のように「あれ？どこいった？」がないので、保管場所を考える必要もなし。
              </span>
            </div>
          </div>
          <img
            className="slider2"
            src="/assets/imgs/slider2.png"
            alt="slider2"
          />
          <span className="text-mobile slider2-text">
            スマホで管理ができるので、自宅にいなくても思い立ったらすぐにメモできて便利。紙のように「あれ？どこいった？」がないので、保管場所を考える必要もなし。
          </span>
        </SliderWrapper>
        <SliderWrapper>
          <div className="text-wrapper">
            <p className="title">
              LINEでもSMSでも、
              <br />
              家族にすぐシェアできて
              <br className={"break-out"} />
              かんたん
            </p>
            <div className="text">
              <span className="text-desktop">
                自分のやりやすい方法で簡単にKaki-Okiをシェア。LINEやSMSなので、家族がその場にいなくても、離れて住んでいる家族にも、いつでもどこでもシェアすることができます。
              </span>
            </div>
          </div>
          <img
            className="slider3"
            src="/assets/imgs/slider3.png"
            alt="slider3"
          />
          <span className="text-mobile">
            自分のやりやすい方法で簡単にKaki-Okiをシェア。LINEやSMSなので、家族がその場にいなくても、離れて住んでいる家族にも、いつでもどこでもシェアすることができます。
          </span>
        </SliderWrapper>
        <SliderWrapper>
          <div className="text-wrapper">
            <p className="title">
              写真や動画を残して
              <br />
              日記のように使うことも
            </p>
            <div className="text">
              <span className="text-desktop">
                写真や動画と一緒にメッセージも添えられます。これからも見返したい大切な思い出はもちろん、「このレシピ写真と一緒に記録しておこう」なんて使い方も。
              </span>
            </div>
          </div>
          <img
            className="slider4"
            src="/assets/imgs/slider4.png"
            alt="slider4"
          />
          <span className="text-mobile">
            写真や動画と一緒にメッセージも添えられます。これからも見返したい大切な思い出はもちろん、「このレシピ写真と一緒に記録しておこう」なんて使い方も。
          </span>
        </SliderWrapper>
      </CarouselComponent>
      <div className="arrows next" onClick={() => slider.current.next()}>
        <img src="/assets/icons/next.svg" alt="next" />
      </div>
    </SliderContainer>
  )
}

export default Slider
