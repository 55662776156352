import { API } from "@project/shared"

export const getConsultMemoById = async (id) => {
  return API.get(`/consultations/${id}`)
}

export const createConsultMemo = async (values): Promise<any> => {
  return API.post(`/consultations`, values)
}

export const updateConsultMemo = async (values) => {
  const id = values.id
  return API.put(`/consultations/${id}`, values)
}
