import React from "react"
import Header from "./header"
import Slider from "./slider"
import Signup from "./signup"
import HomepageFooter from "../HomepageFooter"

const Landing = () => {
  return (
    <div>
      <Header />
      <Slider />
      <Signup />
      <HomepageFooter />
    </div>
  )
}

export { Landing }
