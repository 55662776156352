import { LockItemsTypes } from "../../../interface"

export const checkAccessibilityCondition = (
  data: LockItemsTypes
): Array<boolean> => {
  const boolArray = [true, true, true, true]
  if (
    Boolean(data?.assests) ||
    Boolean(data?.friends) ||
    Boolean(data?.insurance) ||
    Boolean(data?.storage)
  ) {
    boolArray[0] = false
  }
  if (
    Boolean(data?.pets) ||
    Boolean(data?.hospitals) ||
    Boolean(data?.funeral) ||
    Boolean(data?.grave)
  ) {
    boolArray[1] = false
  }
  if (data?.medias) {
    boolArray[2] = false
  }
  if (data?.consultations) {
    boolArray[3] = false
  }

  return boolArray
}
