/* eslint-disable no-console */
import Head from "next/head"
import { Footer } from "../components"
import * as Sentry from "@sentry/node"
import { AppProps } from "next/app"
import { useRouter } from "next/router"
import { LanguageOption, auth, theme } from "@project/shared"
import { useEffect, useState } from "react"
import { signOut, onAuthStateChanged } from "firebase/auth"
import { message, BackTop } from "antd"
import { CloseCircleFilled, UpOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { QueryClient, QueryClientProvider } from "react-query"
import "../utils/css-imports"
import { GlobalStyles, AuthProvider } from "../utils"
import styled, { ThemeProvider } from "styled-components"
import GlobalContextProvider from "../context/GlobalContextProvider"
const queryClient = new QueryClient({ defaultOptions: {} })

const BackToTopIcon = styled.div`
  height: 50px;
  width: 50px;
  background: #808080;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 50px;

  @media (max-width: 786px) {
    height: 40px;
    width: 40px;
    line-height: 40px;

    .anticon svg {
      font-size: 16px;
    }
  }
`

const BackTopContainer = styled.div`
  .ant-back-top {
    bottom: 130px;
    right: 30px;

    @media (max-width: 720px) {
      bottom: 95px;
      right: 15px;
    }
  }
`

const ComponentWrapper = styled.div`
  min-height: calc(100vh - 100px);

  @media (max-width: 720px) {
    min-height: calc(100vh - 80px);
  }
`

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled:
      process.env.ENVIRONMENT === "development" ||
      process.env.ENVIRONMENT === "production",
    environment: `kaki-oki-front-user-${process.env.ENVIRONMENT}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [userId, setUserId] = useState(null)
  const [deferredPrompt, setDeferredPrompt] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault()
      setDeferredPrompt(e)
    })

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((reg) => console.log("sw worker registered", reg))
        .catch(() => console.log("failed"))
    }

    return () => {
      window.removeEventListener("beforeinstallprompt", null)
    }
  })

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      routers.events.on("routeChangeComplete", () => {
        window.scrollTo(0, 0)
      })
    }
  }, [])

  const initialLoad = () => {
    onAuthStateChanged(auth, async (user) => {
      try {
        if (user !== null) {
          const idToken = await user!.getIdTokenResult()
          if (idToken.claims["role"] === "user") {
            setUser(user)
          } else {
            signOut(auth)
            setUser(null)
            message.error({
              key: "01",
              icon: <CloseCircleFilled onClick={() => message.destroy("01")} />,
              content: t("Unauthorized user"),
            })
          }
        } else {
          signOut(auth)
          setUser(null)
        }
        setLoading(false)
      } catch (error) {
        Sentry.captureException(error)
        message.error({
          key: "02",
          content: t("An error has occurred. Please try again later."),
          icon: <CloseCircleFilled onClick={() => message.destroy("02")} />,
        })
      }
    })
  }

  useEffect(() => {
    initialLoad()
  }, [])

  return (
    <>
      <Head>
        <title>{t("Kaki oki")}</title>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalContextProvider>
            <AuthProvider
              loading={loading}
              user={user}
              isAdmin={false}
              setUser={setUser}
              userId={userId}
              setUserId={setUserId}
              deferredPrompt={deferredPrompt}
              setDeferredPrompt={setDeferredPrompt}
            >
              <BackTopContainer>
                <BackTop>
                  <BackToTopIcon>
                    <UpOutlined />
                  </BackToTopIcon>
                </BackTop>
              </BackTopContainer>
              <ComponentWrapper>
                <Component {...pageProps} />
                {process.env.ENVIRONMENT === "development" ||
                process.env.ENVIRONMENT === "local" ? (
                  <LanguageOption />
                ) : (
                  <></>
                )}
              </ComponentWrapper>
              {routers.pathname !== "/" &&
                routers.pathname !== "/how-to-use" && <Footer />}
            </AuthProvider>
          </GlobalContextProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  )
}

export default MyApp
