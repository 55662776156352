import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation } from "react-query"
import * as yup from "yup"
import { notification, Spin } from "antd"
import { TextField, Button, Select } from "@project/shared"
import { HOSPITALIZATION } from "@project/shared/src/utils/constant"
import {
  bodyOrganDonationOption,
  lifeProlongingTreatmentOptions,
  moneyForHospitalization,
  seriouslyIllOption,
} from "../../../../constants/hospitalization&care"
import {
  createHospitalizationCare,
  getHospitalizationCareById,
  updateHospitalizationCare,
} from "../../../../services/hospitalization-care"
import {
  ButtonWrapper,
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../RelativesFriendsForm/helper"
import { useEffect } from "react"

interface Props {
  id?: string | string[]
}

const HospitalizationForm: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const {
    data: hospitalizationData,
    isLoading: realStateLoading,
    isFetching: realStateFetching,
  } = useQuery<any, Error>(
    ["hospitalization", id],
    () => id && getHospitalizationCareById(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) => {
        return {
          on_ill: data?.hospitalization?.on_ill,
          other: data?.hospitalization?.other,
          life_prolonging_treatment:
            data?.hospitalization?.life_prolonging_treatment,
          other_treatment: data?.hospitalization?.other_treatment,
          hospitalization_payment:
            data?.hospitalization?.hospitalization_payment,
          other_payment_method: data?.hospitalization?.other_payment_method,
          body_organ_donation: data?.hospitalization?.body_organ_donation,
          document_stored_location:
            data?.hospitalization?.document_stored_location,
        }
      },
    }
  )

  const handleChangeOption = (value, fieldName) => {
    formik.setFieldValue(fieldName, value)
  }

  const initialValues = hospitalizationData || {
    on_ill: null,
    other: "",
    life_prolonging_treatment: null,
    other_treatment: "",
    hospitalization_payment: null,
    other_payment_method: "",
    body_organ_donation: null,
    document_stored_location: "",
  }

  const validationSchema = yup.object().shape({
    on_ill: yup.string().nullable().required(t("Required")),
    other: yup.string(),
    life_prolonging_treatment: yup.string().nullable(),
    other_treatment: yup.string(),
    hospitalization_payment: yup.string().nullable(),
    other_payment_method: yup.string(),
    body_organ_donation: yup.string().nullable(),
    document_stored_location: yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id) {
        mutate({ type: HOSPITALIZATION, hospitalization: values, id: id })
      } else {
        mutate({ type: HOSPITALIZATION, hospitalization: values })
      }
    },
  })
  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])

  const { mutate, isLoading, isSuccess } = useMutation(
    id ? updateHospitalizationCare : createHospitalizationCare,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: t("Hospitalization information has been updated"),
          })
        } else {
          notification.success({
            message: t(
              "Hospitalization information has been successfully created"
            ),
          })
        }
        router.push("/my-thoughts/hospitalization-care-house")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )
  if (realStateFetching || realStateLoading)
    return (
      <div className="loader-wrapper">
        <Spin />
      </div>
    )

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("When seriously ill")}</Label>
        </LabelContainer>
        <Select
          type="validate"
          name="on_ill"
          value={formik.values.on_ill || null}
          options={seriouslyIllOption}
          onChange={(value) => handleChangeOption(value, "on_ill")}
          placeholder={t("When seriously ill")}
          className="select-component"
          error={
            formik.touched.on_ill && formik.errors?.on_ill
              ? formik.errors?.on_ill
              : false
          }
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Other in details")}</Label>
        </LabelContainer>
        <TextField
          name="other"
          value={formik.values.other}
          onChange={formik.handleChange}
          error={formik.touched.other && formik.errors?.other}
          onBlur={formik.handleBlur}
          placeholder={t("Other in details")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Life-prolonging treatment")}</Label>
        </LabelContainer>
        <Select
          type="validate"
          name="life_prolonging_treatment"
          value={formik.values.life_prolonging_treatment || null}
          options={lifeProlongingTreatmentOptions}
          onChange={(value) =>
            handleChangeOption(value, "life_prolonging_treatment")
          }
          placeholder={t("Life-prolonging treatment")}
          className="select-component"
          error={
            formik.touched.life_prolonging_treatment &&
            formik.errors?.life_prolonging_treatment
              ? formik.errors?.life_prolonging_treatment
              : false
          }
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Other in details")}</Label>
        </LabelContainer>
        <TextField
          name="other_treatment"
          value={formik.values.other_treatment}
          onChange={formik.handleChange}
          error={
            formik.touched.other_treatment && formik.errors?.other_treatment
          }
          onBlur={formik.handleBlur}
          placeholder={t("Other in details")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Money for hospitalization")}</Label>
        </LabelContainer>
        <Select
          type="validate"
          name="hospitalization_payment"
          value={formik.values.hospitalization_payment || null}
          options={moneyForHospitalization}
          onChange={(value) =>
            handleChangeOption(value, "hospitalization_payment")
          }
          placeholder={t("Money for hospitalization")}
          className="select-component"
          error={
            formik.touched.hospitalization_payment &&
            formik.errors?.hospitalization_payment
              ? formik.errors?.hospitalization_payment
              : false
          }
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Other in details")}</Label>
        </LabelContainer>
        <TextField
          name="other_payment_method"
          value={formik.values.other_payment_method}
          onChange={formik.handleChange}
          error={
            formik.touched.other_payment_method &&
            formik.errors?.other_payment_method
          }
          onBlur={formik.handleBlur}
          placeholder={t("Other in details")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Body and organ donation")}</Label>
        </LabelContainer>
        <Select
          type="validate"
          name="body_organ_donation"
          value={formik.values.body_organ_donation || null}
          options={bodyOrganDonationOption}
          onChange={(value) => handleChangeOption(value, "body_organ_donation")}
          placeholder={t("Body and organ donation")}
          className="select-component"
          error={
            formik.touched.body_organ_donation &&
            formik.errors?.body_organ_donation
              ? formik.errors?.body_organ_donation
              : false
          }
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Document / card storage location")}</Label>
        </LabelContainer>
        <TextField
          name="document_stored_location"
          value={formik.values.document_stored_location}
          onChange={formik.handleChange}
          error={
            formik.touched.document_stored_location &&
            formik.errors?.document_stored_location
          }
          onBlur={formik.handleBlur}
          placeholder={t("Document / card storage location")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>
      <ButtonWrapper>
        <Button
          htmlType="submit"
          loading={isLoading}
          disabled={isSuccess}
          width="200px"
          height="50px"
          background="#E26E30"
        >
          {id ? t("Update") : t("Add")}
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export { HospitalizationForm }
