import React, { useEffect } from "react"
import { t } from "i18next"
import Router from "next/router"
import { useFormik } from "formik"
import { useMutation, useQuery } from "react-query"
import { othersOptions, validationSchema } from "./helper"
import { TextField, theme, Button, Select } from "@project/shared"
import { notification, Spin } from "antd"
import {
  createAsset,
  getAssetById,
  updateAsset,
} from "../../../../services/asset"

import {
  ButtonWrapper,
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../RelativesFriendsForm/helper"

interface Props {
  id?: string | string[]
}

const OthersForm: React.FC<Props> = ({ id }) => {
  const {
    data: othersData,
    isLoading: othersLoading,
    isFetching: othersFetching,
  } = useQuery(["othersData", id], () => id && getAssetById(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: ({ data }) => {
      return {
        id: data?.id,
        kind: data?.others.kind,
        details: data?.others.details,
        location: data?.others.location,
        memo_notes: data?.others.memo_notes,
      }
    },
  })

  const initialValues = othersData || {
    kind: null,
    details: "",
    location: "",
    memo_notes: "",
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id) {
        mutate({ assets_type: "その他", others: values, id: id })
      } else {
        mutate({ assets_type: "その他", others: values })
      }
    },
  })

  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])

  const { mutate, isLoading } = useMutation(id ? updateAsset : createAsset, {
    onSuccess: () => {
      if (id) {
        notification.success({
          message: t("Others information has been updated"),
        })
      } else {
        notification.success({
          message: t("Others information has been added"),
        })
      }
      Router.push("/friends-property/asset-management")
    },
  })

  const handleKindsChange = (value) => {
    formik.setFieldValue("kind", value)
  }

  if (othersLoading || othersFetching)
    return (
      <div className="loader-wrapper">
        <Spin />
      </div>
    )

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Kinds")}</Label>
        </LabelContainer>
        <Select
          type="validate"
          name="kind"
          className="select-component"
          value={formik.values.kind || null}
          options={othersOptions}
          onChange={handleKindsChange}
          placeholder={t("Kinds")}
          error={
            formik.touched.kind && formik.errors?.kind
              ? formik.errors?.kind
              : false
          }
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Details")}</Label>
        </LabelContainer>
        <TextField
          name="details"
          placeholder={t("Details")}
          value={formik.values.details}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.details && formik.errors?.details}
          showCounting
          maxLength={50}
          className="dynamic-textarea"
          type="dynamic-textarea"
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Location・Stored")}</Label>
        </LabelContainer>
        <TextField
          name="location"
          placeholder={t("Location・Stored")}
          value={formik.values.location}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.location && formik.errors?.location}
          showCounting
          maxLength={50}
          className="dynamic-textarea"
          type="dynamic-textarea"
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Memo・Notes")}</Label>
        </LabelContainer>
        <TextField
          name="memo_notes"
          placeholder={t("Memo・Notes")}
          type="textarea"
          rows={4}
          className="text-area"
          value={formik.values.memo_notes}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.memo_notes && formik.errors?.memo_notes}
          showCounting
          maxLength={300}
        />
      </TextFieldContainer>
      <ButtonWrapper>
        <Button
          htmlType="submit"
          background={theme.button.primary}
          className="save-btn"
          loading={isLoading}
        >
          {id ? t("Update") : t("Add")}
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export { OthersForm }
