import { Button, Select, TextField, theme } from "@project/shared"
import React, { useEffect } from "react"
import { t } from "i18next"
import { useFormik } from "formik"
import { InsuranceType } from "../../../interface/insurance"
import {
  ButtonWrapper,
  Container,
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../RelativesFriendsForm/helper"
import { validationSchema } from "./helpers"
import FormExternalMenus from "../FormExternalMenus"

interface InsuranceFormProps {
  handleSubmit?: (values) => void
  loading?: boolean
  data?: InsuranceType
}

const InsuranceForm: React.FC<InsuranceFormProps> = ({
  handleSubmit,
  loading,
  data,
}) => {
  const formik = useFormik<InsuranceType>({
    initialValues: data
      ? data
      : {
          insurance_type: null,
          company_name: "",
          contractor: "",
          receiver: "",
          contents: "",
          contact_pic: "",
          memo_notes: "",
          document_storage: "",
        },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  })
  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])
  const insurance_data = [
    { value: "生命保険", name: t("Life insurance") },
    { value: "医療保険", name: t("Medical insurance") },
    { value: "個人年金保険", name: t("Pension insurance") },
    { value: "介護保険", name: t("Care insurance") },
    { value: "自動車保険", name: t("Vehicle insurance") },
    { value: "共済", name: t("Mutual aid") },
    { value: "互助会", name: t("Mutual aid Society") },
    { value: "その他", name: t("Others") },
  ]
  /**
   * External link menusItems.
   */
  const externalMenusItem = [
    {
      title: "お得な制度「サン・ライフメンバーズ」について知る",
      path: "/preparing",
      image: "image5.jpg",
    },
  ]

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Type")}</Label>
          </LabelContainer>
          <Select
            type="validate"
            name="insurance_type"
            className="select-field"
            placeholder={t("Type")}
            allowClear
            value={formik.values.insurance_type || null}
            error={
              formik.touched.insurance_type && formik.errors.insurance_type
            }
            onChange={(value) => {
              formik.setFieldValue("insurance_type", value)
            }}
            options={insurance_data}
          />
        </TextFieldContainer>

        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Company Name")}</Label>
          </LabelContainer>
          <TextField
            name="company_name"
            placeholder={t("Company Name")}
            disableboxshadow
            height="50px"
            borderradius="10px"
            value={formik.values.company_name}
            onChange={formik.handleChange}
            maxLength={30}
            showCounting
            className="dynamic-textarea"
            type="dynamic-textarea"
          />
        </TextFieldContainer>

        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Contractor")}</Label>
          </LabelContainer>
          <TextField
            name="contractor"
            placeholder={t("Contractor")}
            disableboxshadow
            height="50px"
            borderradius="10px"
            className="dynamic-textarea"
            type="dynamic-textarea"
            value={formik.values.contractor}
            onChange={formik.handleChange}
            maxLength={30}
            showCounting
          />
        </TextFieldContainer>

        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Receiver")}</Label>
          </LabelContainer>
          <TextField
            name="receiver"
            placeholder={t("Receiver")}
            className="dynamic-textarea"
            type="dynamic-textarea"
            disableboxshadow
            height="50px"
            borderradius="10px"
            value={formik.values.receiver}
            onChange={formik.handleChange}
            maxLength={30}
            showCounting
          />
        </TextFieldContainer>

        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Contents")}</Label>
          </LabelContainer>
          <TextField
            name="contents"
            placeholder={t("Contents")}
            disableboxshadow
            borderradius="10px"
            type="textarea"
            maxLength={300}
            rows={4}
            showCounting
            value={formik.values.contents}
            onChange={formik.handleChange}
          />
        </TextFieldContainer>

        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("(Documents)Storage place")}</Label>
          </LabelContainer>
          <TextField
            name="document_storage"
            placeholder={t("(Documents)Storage place")}
            disableboxshadow
            borderradius="10px"
            type="textarea"
            maxLength={300}
            rows={4}
            showCounting
            value={formik.values.document_storage}
            onChange={formik.handleChange}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{`${t("Contact")}・${t("PIC")}`}</Label>
          </LabelContainer>
          <TextField
            name="contact_pic"
            placeholder={t("Contact")}
            disableboxshadow
            borderradius="10px"
            maxLength={50}
            showCounting
            value={formik.values.contact_pic}
            onChange={formik.handleChange}
            className="dynamic-textarea"
            type="dynamic-textarea"
          />
        </TextFieldContainer>

        <TextFieldContainer>
          <LabelContainer>
            <Label>{`${t("Memo")}・${t("Notes")}`}</Label>
          </LabelContainer>
          <TextField
            name="memo_notes"
            placeholder={`${t("Memo")}・${t("Notes")}`}
            disableboxshadow
            borderradius="10px"
            type="textarea"
            rows={4}
            maxLength={300}
            showCounting
            value={formik.values.memo_notes}
            onChange={formik.handleChange}
          />
        </TextFieldContainer>
        <ButtonWrapper>
          <Button
            background={theme.background.navBar.secondary}
            className="save-btn"
            loading={loading}
            htmlType="submit"
          >
            {data ? t("Update") : t("Save")}
          </Button>
        </ButtonWrapper>
        <FormExternalMenus menuItems={externalMenusItem} />
      </form>
    </Container>
  )
}

export { InsuranceForm }
