import { Loader } from "@project/shared"
import { Empty } from "antd"
import { t } from "i18next"
import React, { useState } from "react"
import styled from "styled-components"
import { AccessibilityTypes } from "../../../interface"
import SearchBox from "../../molecules/SearchBox"
import SubRouteComponent from "../../molecules/SubRouteComponent"
import { Content, StyledPageHeader } from "../../molecules/TopPage/HowToUse"

export interface iFamily {
  name?: string
  id?: string
  accessibility?: AccessibilityTypes
}
export interface ViewerFamilyListProps {
  familyData?: iFamily[]
}

const ListWrapper = styled.div`
  display: grid;
  margin-top: 30px;

  .viewer-family {
    margin-bottom: 50px;

    :last-child {
      margin-bottom: 0px;
    }
  }

  @media (max-width: 768px) {
    .viewer-family {
      margin-bottom: 20px;

      :last-child {
        margin-bottom: 0px;
      }
    }
  }
`
const ViewerFamilyList: React.FC<ViewerFamilyListProps> = ({ familyData }) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchLoading, setSearchLoading] = useState(false)
  const filterFamilyData = familyData?.filter((item) => {
    return searchQuery
      ? item.name.toLowerCase().includes(searchQuery.toLowerCase())
      : true
  })

  const onSearchHandler = (e) => {
    setSearchQuery(e.target.value)

    setTimeout(() => {
      setSearchLoading(true)
    }, 100)
    setTimeout(() => {
      setSearchLoading(false)
    }, 1200)
  }

  return (
    <Content>
      <StyledPageHeader
        icon={
          <img
            src="/assets/icons/family.svg"
            alt="note edit icon"
            height={32}
            width={32}
          />
        }
        alignment="flex-start"
        headerTitle={t("Family information")}
      />
      <SearchBox
        value={searchQuery}
        suffix={
          <img
            src="/assets/icons/search-icon.svg"
            height={17}
            width={17}
            alt="search icon"
          />
        }
        label={t("Name")}
        onChange={onSearchHandler}
      />
      {searchLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <ListWrapper>
          {filterFamilyData?.length ? (
            filterFamilyData.map((family, index) => (
              <SubRouteComponent
                key={index}
                text={`${family.name} さんの情報`}
                prefix={
                  <img
                    className="component-icon"
                    src="/assets/icons/profile.svg"
                  />
                }
                path={`/family-information/${family.id}`}
                className="viewer-family"
              />
            ))
          ) : (
            <Empty description={t("No data")} />
          )}
        </ListWrapper>
      )}
    </Content>
  )
}

export { ViewerFamilyList }
