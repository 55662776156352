import { DownOutlined, RightOutlined } from "@ant-design/icons"
import { theme } from "@project/shared"
import Router from "next/router"
import React from "react"
import styled from "styled-components"

interface ISubRouteComponent {
  width?: string
  height?: string
  prefix?: React.ReactNode
  mobileWidth?: string
  mobileHeight?: string
  text?: string
  path?: string
  LockIcon?: React.ReactNode
  onLockClick?: () => void
  onClickComponent?: () => void
  showLockIcon?: boolean
  onClickBox?: () => void
  isLockedPath?: boolean
  isActive?: boolean
  fromViewer?: boolean
  className?: string
}

interface IStyled {
  hasIcon?: boolean
  isActive?: boolean
  height?: string
  mobileHeight: string
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const ComponentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 20px;
  flex-basis: 100%;
  justify-content: ${(props: IStyled) =>
    props.hasIcon ? "space-between" : "space-around"};
  height: ${(props: IStyled) => props.height ?? "100px"};
  background: #ffffff;
  border: ${(props: IStyled) =>
    props.isActive ? "1px solid #E26E30" : "1px solid #dddcdc"};
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  @media (max-width: 767px) {
    height: ${(props: IStyled) => props.mobileHeight ?? "80px"};
  }
  @media (min-width: 768px) {
    min-width: 300px;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
  :hover {
    cursor: pointer;
  }
  .component-icon {
    height: 29px;
    width: 30px;
  }
  svg {
    fill: ${(props: IStyled) => (props.isActive ? "#E26E30" : null)} !important;
  }
`
const IconAndTextBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const IconBox = styled.div`
  background: #f8f5ef;
  border-radius: 100px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  @media (max-width: 767px) {
    max-width: 45px;
    min-width: 45px;
    height: 45px;
  }
  .component-icon {
    height: 29px;
    @media (max-width: 786px) {
      height: 23px;
    }
  }
  .insurance-icon {
    height: 40px;
    @media (max-width: 786px) {
      height: 28px;
    }
  }
  .home-icon {
    height: 28px;
    @media (max-width: 786px) {
      height: 20px;
    }
  }
`
const TextBox = styled.div`
  font-family: ${theme.font.BUS};
  font-weight: ${theme.text.weight.regular};
  color: ${theme.text.color.dark};
  font-size: ${theme.text.size.s};
  margin-left: 22px;
  text-align: center;
  @media (max-width: 576px) {
    font-size: 18px;
    margin-left: 10px;
    word-break: keep-all;
    text-align: left;
    line-height: 24px;
  }
`

const LockIconBox = styled.div`
  margin-left: 20px;
  cursor: pointer;
  :hover {
    transform: scale(1.2);
  }
`
const LockIconBoxViewer = styled.div`
  margin-left: 20px;
  :hover {
    transform: none;
  }
`
const RightIconBox = styled.div`
  cursor: pointer;
`

const SubRouteComponent: React.FC<ISubRouteComponent> = (props) => {
  const {
    text,
    prefix,
    path,
    showLockIcon = false,
    onLockClick,
    isActive = false,
    onClickComponent,
    LockIcon,
    onClickBox,
    isLockedPath = false,
    fromViewer = false,
    height,
    mobileHeight,
    className,
  } = props

  const handleClick = () => {
    if (fromViewer) {
      isLockedPath ? onClickBox() : Router.push(path)
    } else {
      onClickComponent ? onClickComponent() : Router.push(path)
    }
  }
  return (
    <Wrapper className={className}>
      <ComponentBox
        onClick={handleClick}
        hasIcon={prefix ? true : false}
        isActive={isActive}
        height={height}
        mobileHeight={mobileHeight}
      >
        <IconAndTextBox>
          {prefix && <IconBox>{prefix}</IconBox>}
          <TextBox>{text}</TextBox>
        </IconAndTextBox>
        <RightIconBox>
          {isActive ? <DownOutlined /> : <RightOutlined />}
        </RightIconBox>
      </ComponentBox>
      {showLockIcon && !fromViewer ? (
        <LockIconBox onClick={onLockClick}>{LockIcon}</LockIconBox>
      ) : (
        <LockIconBoxViewer onClick={onLockClick}>{LockIcon}</LockIconBoxViewer>
      )}
    </Wrapper>
  )
}

export default SubRouteComponent
