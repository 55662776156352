import {
  Button,
  downloadFileCRP,
  getSignedObjectUrl,
  TextField,
  theme,
} from "@project/shared"
import { Image, Modal, notification, Skeleton, Spin } from "antd"
import { useFormik } from "formik"
import { t } from "i18next"
import moment from "moment"
import React, { useEffect, useState, useRef } from "react"
import { useMutation } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
import { updateMedia } from "../../../services/picture-memories"
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons"
import ReactHlsPlayer from "react-hls-player"
import { videoFetch } from "../../../services/video-file"
interface iCard {
  data: any
  onDeleteClick?: (id: string) => void
  fileType?: string
}

interface IButtonColor {
  delete?: boolean
}

const Wrapper = styled.form`
  width: 100%;
  padding: 18px 20px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`
const CustomModal = styled(Modal)`
  .ant-btn-primary {
    border-radius: 100px;
    padding: 0px 20px;
    background: rgb(226, 110, 48);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(226, 110, 48);
    width: 92px;
  }
  .ant-btn-default {
    border-radius: 100px;
    padding: 0px 20px;
    background: rgb(196, 196, 196);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(196, 196, 196);
    width: auto;
  }
`
const ImageWrapper = styled.div`
  background-color: #e3e0d9;
  width: 100%;
  height: 166px;
  img {
    object-fit: contain;
    background-color: #e3e0d9;
  }

  .thumbnail {
    position: relative;
    .play {
      position: absolute;
      background-color: transparent;
      top: calc(50% - 25px);
      right: calc(50% - 22px);
      cursor: pointer;
      &:hover {
        transform: scale(1.5);
      }
    }
  }
`
const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  .text-field {
    width: 100%;
  }
  input {
    background-color: #f8f5ef;
  }
  textarea {
    background-color: #f8f5ef;
  }
  .show-counting {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #8d8686;
  }
`
const BottomComponent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 19px;
  align-items: center;
  .date {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #8d8686;
  }
  .delete-section {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 11px;
    margin-right: 5px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ButtonWrapper = styled.div<IButtonColor>`
  min-width: 40px;
  min-height: 40px;
  padding: 8px 0px 8px 0px;
  margin-left: 14px;
  border: 1px solid;
  border-color: ${(props) => (props.delete ? "#EABDBD" : "#e0e0e0")};
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 390px) {
    margin-left: 6px;
  }

  .icon {
    color: ${(props) => (props.delete ? " #e71c1c " : "#E26E30")};
  }
`

const SaveButtonWrapper = styled.div`
  text-align: center;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  .confirm-btn {
    font-weight: 700;
    font-size: 18px;
    align-items: center;
    text-align: center;
  }
`

const PictureCard: React.FC<iCard> = ({ data, onDeleteClick, fileType }) => {
  const playerRef = useRef<HTMLVideoElement>(null)
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)
  const [thumbnail, setThumbnail] = useState<string>()
  const [pictureCardData, setPictureCardData] = useState({
    id: data?.id,
    created_date: data?.created_at,
    title: data?.title,
    caption: data?.caption,
    image: data?.image?.url,
  })
  const [loadImage, setLoadImage] = useState(false)
  const [isFileDownloading, setIsFileDownloading] = useState(false)
  const handleDelete = () => {
    onDeleteClick(data?.id)
  }
  const [url, setUrl] = useState<string | null>(null)
  const initialValues = pictureCardData || {
    id: "",
    created_date: "",
    title: "",
    caption: "",
    image: "",
  }

  const validationSchema = yup.object().shape({
    id: yup.string(),
    created_date: yup.string(),
    title: yup.string(),
    caption: yup.string(),
    image: yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (
        Object.entries(pictureCardData).toString() !==
        Object.entries(values).toString()
      ) {
        mutate(values)
        setPictureCardData(values)
      }
    },
  })

  const { mutate } = useMutation(updateMedia, {
    onSuccess: () => {
      notification.success({
        message: `${t("Share important pictures and memories")} ${t(
          "Information has been added"
        )}`,
      })

      //checks if there is an active element, and if so, calls its blur() method to remove focus
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur()
      }
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("An error has occurred. Please try again later."),
      })
    },
  })

  const { mutate: getSignedVideoUrl, data: signedVideoURL } = useMutation(
    videoFetch,
    {
      onSuccess: () => {
        showModal()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )

  const handleFileDownload = async (url, filename) => {
    try {
      setIsFileDownloading(true)
      let mimeType = ""
      const filenameArray = filename.split(".")
      const extension = filenameArray[filenameArray.length - 1]
      if (fileType === "image") {
        mimeType = "image/" + extension
      } else {
        mimeType = "video/" + extension
      }
      await downloadFileCRP({ url, filename, fileType: mimeType })
      setIsFileDownloading(false)
    } catch (error) {
      setIsFileDownloading(false)
      notification.error({
        message: t("An error has occurred. Please try again later."),
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      setLoadImage(true)
      if (data?.image?.url) {
        const imageUrl = await getSignedObjectUrl(data?.image.url)
        setUrl(imageUrl)
      }
      setLoadImage(false)
    })()
  }, [data?.image])

  useEffect(() => {
    ;(async () => {
      setLoadImage(true)
      if (data?.video?.thumbnail) {
        const imageUrl = await getSignedObjectUrl(data?.video?.thumbnail)
        setThumbnail(imageUrl)
      }
      setLoadImage(false)
    })()
  }, [data?.video?.thumbnail])
  if (loadImage) {
    return <Skeleton />
  }
  const showModal = () => {
    setIsVideoModalVisible(true)
  }

  const handleOk = () => {
    playVideo()
  }

  const handleCancel = () => {
    setIsVideoModalVisible(false)
    pauseVideo()
  }
  function pauseVideo() {
    playerRef && playerRef?.current?.pause()
  }
  function playVideo() {
    playerRef && playerRef?.current?.play()
  }

  const submitForm = () => formik.handleSubmit

  return (
    <Wrapper onSubmit={formik.handleSubmit}>
      <CustomModal
        visible={isVideoModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        okText={t("Play")}
        cancelText={t("Cancel")}
      >
        <ReactHlsPlayer
          playerRef={playerRef}
          src={signedVideoURL?.data?.signed_url}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        />
      </CustomModal>
      <ImageWrapper>
        {data?.video && (
          <div className="thumbnail">
            <img
              width="100%"
              height={166}
              src={
                thumbnail ||
                "https://images.freeimages.com/images/large-previews/e4e/circulate-abstract-1562332.jpg"
              }
            />
            <img
              src="/assets/icons/play.png"
              className="play"
              onClick={() => {
                getSignedVideoUrl(data?.video?.url)
              }}
            />
          </div>
        )}
        {data?.image && (
          <Image
            src={url}
            width="100%"
            height={166}
            preview={{
              mask: (
                <span>
                  <EyeOutlined />
                  &nbsp;
                  {t("Preview")}
                </span>
              ),
            }}
          />
        )}
      </ImageWrapper>
      <TextFieldContainer className="name">
        <TextField
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && formik.errors?.title}
          placeholder={t("Please enter title here")}
          onBlur={formik.handleBlur}
          className="text-field"
          height="50px"
          borderradius="10px"
          showCounting
          maxLength={30}
          bordercolor="#DEDBD5"
          disableboxshadow
          enterKeyHint={"done"}
        />
      </TextFieldContainer>
      <SaveButtonWrapper>
        <Button
          htmlType="submit"
          background={theme.button.primary}
          className="confirm-btn"
          onClick={() => submitForm()}
        >
          {t("Save")}
        </Button>
      </SaveButtonWrapper>
      <TextFieldContainer>
        <TextField
          name="caption"
          type="textarea"
          rows={4}
          value={formik.values.caption}
          onChange={formik.handleChange}
          error={formik.touched.caption && formik.errors?.caption}
          onBlur={formik.handleBlur}
          placeholder={t("Please enter caption here")}
          height="50px"
          width="100%"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          showCounting
          disableboxshadow
          enterKeyHint={"done"}
        />
      </TextFieldContainer>
      <SaveButtonWrapper>
        <Button
          htmlType="submit"
          background={theme.button.primary}
          className="confirm-btn"
          onClick={() => submitForm()}
        >
          {t("Save")}
        </Button>
      </SaveButtonWrapper>
      <BottomComponent>
        <div className="date">
          登録日: {moment(data?.created_at).format("YYYY/MM/DD")}
        </div>
        <ButtonsContainer>
          <ButtonWrapper
            onClick={() => {
              handleFileDownload(
                data?.image?.url || data?.video?.url,
                data?.video?.name || data?.image?.name
              )
            }}
          >
            {isFileDownloading ? (
              <Spin size="small" />
            ) : (
              <DownloadOutlined className="icon" />
            )}
          </ButtonWrapper>

          <ButtonWrapper delete onClick={() => handleDelete()}>
            <div className="delete-section">
              <DeleteOutlined className="icon" />
            </div>
          </ButtonWrapper>
        </ButtonsContainer>
      </BottomComponent>
    </Wrapper>
  )
}

export { PictureCard }
