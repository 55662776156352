import React from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { theme } from "@project/shared"
import { ButtonComponent } from "@project/shared/src/components/atom/Button"
import router from "next/router"

interface Props {
  title?: string
  body?: string
  link?: string
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const BodyWrapper = styled.div`
  border: 1px solid ${theme.darkGrey};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 100%;

  @media (max-width: 375px) {
    border: none;
  }

  p {
    font-size: 15px;
    font-weight: bold;
    max-width: 320px;
    text-align: center;
    margin-top: 40px;
    color: #313131;
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }

  .top-page-btn {
    margin-top: 95px;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 23px;
  color: #313131;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`

const DeleteSucessPage: React.FC<Props> = ({ title, body }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <BodyWrapper>
        <Title>{t(`${title}`)}</Title>
        <p className="text">{body}</p>
        <ButtonComponent
          htmlType="submit"
          height="50px"
          width="200px"
          background={theme.button.primary}
          className={"top-page-btn"}
          onClick={() => router.push("/")}
        >
          {t("Go to Top page")}
        </ButtonComponent>
      </BodyWrapper>
    </Container>
  )
}

export { DeleteSucessPage }
