import styled from "styled-components"
import { theme } from "@project/shared"

export const TitleWrapper = styled.div`
  background-color: ${theme.background.primary};
  padding: 18px 0;
  text-align: center;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: ${theme.button.primary};

  @media (max-width: 416px) {
    font-size: 20px;
  }
`

export const BodyWrapper = styled.div`
  width: 706px;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;

  .text,
  span {
    font-weight: 500;
    font-size: 18px;
  }

  .share-btn {
    background-color: #06c755 !important;
    width: 340px;
    height: 50px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;

    .text,
    span {
      font-weight: 700;
      font-size: 18px;
      color: ${theme.base};
    }
    img {
      margin-right: 18px;
    }

    @media (max-width: 786px) {
      margin-top: 20px;
      width: 335px;
      height: 60px;

      img {
        margin-right: 14px;
        width: 32px;
      }
    }
    @media (max-width: 416px) {
      width: 100%;
      .text,
      span {
        font-size: 15px;
      }
    }
  }

  .small-text {
    margin-bottom: 0px;
    margin-top: 15px;
    font-weight: 400;
    font-size: 16px;

    @media (max-width: 416px) {
      font-size: 16px;
    }
  }

  @media (max-width: 1020px) {
    width: 550px;
  }

  @media (max-width: 786px) {
    width: 100%;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 786px) {
    display: none;
  }
`

export const MobileButtonWrapper = styled.div`
  display: none;

  @media (max-width: 786px) {
    display: flex;
    justify-content: center;
  }
`
