import { API } from "@project/shared"

export interface IReturnpicture {
  count?: number
  data?: any
  nextPage?: number
}

interface IpictureQueryParam {
  page?: string | number
  pageSize?: string
  role?: string
  keyword?: string
  status?: string
  user_id: string
}

export const fetchMedias = async ({ queryKey }): Promise<IReturnpicture> => {
  const params = {} as IpictureQueryParam
  params.page = queryKey[1].page
  params.pageSize = queryKey[1].pageSize
  return API.get(`/gallery`, {
    params,
  })
}
export const fetchInfiniteMedias = async ({
  queryKey,
  pageParam = 1,
}): Promise<IReturnpicture> => {
  const params = {} as IpictureQueryParam
  params.page = pageParam
  params.pageSize = queryKey[1].pageSize
  if (queryKey[1].user_id) {
    params.user_id = queryKey[1]?.user_id
  }
  const result = await API.get("/gallery", {
    params,
  })
  result["nextPage"] = Number(pageParam) * Number(queryKey[1].pageSize) + 1
  return result
}

export const getPicturesById = async (id: string | string[]) => {
  return API.get(`/gallery/${id}`)
}

export const createMedia = async (values): Promise<any> => {
  return API.post(`/gallery`, values)
}

export const updateMedia = async (values) => {
  const id = values.id
  return API.put(`/gallery/${id}`, values)
}

export const deleteMedia = async (id) => {
  return API.delete(`/gallery/${id}`)
}
