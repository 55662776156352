import { API } from "@project/shared"

export interface IReturnHospitalizationCare {
  count?: number
  data?: any
  nextPage?: number
}

interface IHospitalizationCareQueryParam {
  page?: string | number
  pageSize?: string
  role?: string
  keyword?: string
  status?: string
  user_id?: string
}

export const fetchInfiniteHospitalizationCare = async ({
  queryKey,
  pageParam = 1,
}): Promise<IReturnHospitalizationCare> => {
  const params = {} as IHospitalizationCareQueryParam
  params.page = pageParam
  params.pageSize = queryKey[1].pageSize
  params.keyword = queryKey[1].keyword
  if (queryKey[1].user_id) {
    params.user_id = queryKey[1]?.user_id
  }
  const result = await API.get("/hospitals-carehouses", {
    params,
  })
  result["nextPage"] = Number(pageParam) * Number(queryKey[1].pageSize) + 1
  return result
}

export const getHospitalizationCareById = async (id: string | string[]) => {
  return API.get(`/hospitals-carehouses/${id}`)
}

export const createHospitalizationCare = async (values): Promise<any> => {
  return API.post(`/hospitals-carehouses`, values)
}

export const updateHospitalizationCare = async (values) => {
  const id = values.id
  return API.put(`/hospitals-carehouses/${id}`, values)
}

export const deleteHospitalizationCare = async (id) => {
  return API.delete(`/hospitals-carehouses/${id}`)
}
