import { Button, Loader, Required, TextField, theme } from "@project/shared"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { useFormik } from "formik"
import * as yup from "yup"
import {
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../../components/organisms/RelativesFriendsForm/helper"
import { ButtonComponent } from "@project/shared/src/components/atom/Button"
import { PlusCircleOutlined } from "@ant-design/icons"
import { useMutation, useQuery } from "react-query"
import {
  createConsultMemo,
  updateConsultMemo,
  getConsultMemoById,
} from "../../../services/consultMemo"
import { notification } from "antd"
import router from "next/router"

const ContentWrapper = styled.div`
  border: 1px solid #dddcdc;
  border-radius: 10px;
  padding: 40px 50px;

  @media (max-width: 786px) {
    margin-top: 30px;
    border: none;
    padding: 0px;
  }
  .info-text {
    color: #d86464;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
const LoaderWrapper = styled.div`
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ButtonCompWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
`

interface iProps {
  id?: any
}

const ConsultationMemoForm: React.FC<iProps> = ({ id }) => {
  const { t } = useTranslation()

  const [items, setItems] = useState([{ note: "" }]) // Initial item

  const handleInputChange = (index, evt) => {
    const newItems = [...items]
    newItems[index][evt.target.name] = evt.target.value
    setItems(newItems)
    if (newItems?.length > 0) {
      formik?.setFieldValue("free_notes", newItems)
    }
  }

  const {
    data: ConsultationData,
    isLoading,
    isFetching,
  } = useQuery<any, Error>(
    ["consult-memo-by-id", id],
    () => getConsultMemoById(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      enabled: !!id,
      select: ({ data }) => {
        return {
          id: id || "",
          consultation_title: data?.consultation_title || "",
          free_notes: data?.free_notes || [],
        }
      },
      onSuccess: (data) => setItems(data?.free_notes),
    }
  )

  const validationSchema = yup.object().shape({
    consultation_title: yup
      .string()
      .required(t("Please enter consult memo title")),
  })

  const formik = useFormik<any>({
    initialValues: ConsultationData || {
      consultation_title: "",
      free_notes: [],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate(values)
    },
  })

  const handleAddItem = () => {
    const newItems = [...items, { note: "" }]
    setItems(newItems)
  }
  const { mutate, isLoading: isMutating } = useMutation(
    id ? updateConsultMemo : createConsultMemo,
    {
      onSuccess: () => {
        notification.success({
          message: id
            ? t("Consultation Memo updated successfully")
            : t("Consultation Memo added successfully"),
        })
        router.push("/consultation-memo/user")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )

  if (isLoading || isFetching) {
    return (
      <LoaderWrapper>
        <Loader size="large" />
      </LoaderWrapper>
    )
  }
  return (
    <>
      <ContentWrapper>
        <TextFieldContainer>
          <LabelContainer>
            <Label>
              {t("Title")}
              <Required>{"*"}</Required>
            </Label>
          </LabelContainer>
          <TextField
            name="consultation_title"
            error={
              formik.touched.consultation_title &&
              formik.errors.consultation_title
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.consultation_title}
            className="consolt-text-field-2"
            disableboxshadow
            height="50px"
            width="360px"
            borderradius="10px"
            maxLength={30}
            showCounting
            placeholder={t("Enter consult memo title")}
          />
        </TextFieldContainer>

        {items.map((item, index) => (
          <TextFieldContainer key={index}>
            <LabelContainer>
              <Label>{t("Free notes")}</Label>
            </LabelContainer>
            <TextField
              className="text-field-2"
              placeholder={t("Enter your remarks")}
              name="note"
              error={formik.touched.free_notes && formik.errors?.free_notes}
              onChange={(e) => handleInputChange(index, e)}
              onBlur={formik.handleBlur}
              value={item?.note}
              disableboxshadow
              borderradius="10px"
              type="textarea"
              maxLength={500}
              rows={4}
              showCounting
            />
          </TextFieldContainer>
        ))}
        <ButtonCompWrapper>
          <div>
            <Button
              background={theme.base}
              color={theme.text.color.light}
              borderradius="10px"
              className="rightBtn"
              fontSize="18px"
              height="60px"
              onClick={handleAddItem}
            >
              {t("Add  Free Notes")}
              <PlusCircleOutlined className="icon" />
            </Button>
          </div>
          <ButtonWrapper>
            <ButtonComponent
              height="50px"
              width="200px"
              background={theme.button.primary}
              onClick={() => formik.handleSubmit()}
              loading={isMutating}
              htmlType="submit"
            >
              {t("Save")}
            </ButtonComponent>
          </ButtonWrapper>
        </ButtonCompWrapper>
      </ContentWrapper>
    </>
  )
}

export { ConsultationMemoForm }
