import * as yup from "yup"
import { t } from "i18next"
import styled from "styled-components"
import { theme } from "@project/shared"

export const validationSchema = yup.object().shape({
  relation: yup.string().nullable().required(t("Required")),
  name: yup.string().nullable(),
  phone: yup.string().matches(/^\d*$/, t("Phone number can only be number")),
  email: yup.string().email(t("Email address format is incorrect.")),
})

export const Container = styled.div`
  border: 1px solid #dddcdc;
  border-radius: 10px;
  padding: 40px 50px;

  @media (max-width: 786px) {
    margin-top: 30px;
    border: none;
    padding: 0px;
  }
  .info-text {
    color: #d86464;
  }
  & .loader-wrapper {
    display: grid;
    place-items: center;
    min-height: 50vh;
  }
  .default-message {
    color: #d86464;
    margin-top: 4px;
  }
  @media (max-width: 470px) {
    white-space: pre-wrap;
  }
  @media (min-width: 332px) {
    .overflow-message-control {
      display: none !important;
    }
  }
`

export const TextFieldContainer = styled.div`
  margin-bottom: 30px;
  .info-text {
    font-size: 16px;
    color: #d86464;
  }
  .dynamic-textarea {
    width: 360px !important;

    @media (max-width: 786px) {
      width: 100% !important;
    }
  }
  .consolt-text-field-2 {
    @media (max-width: 465px) {
      width: 100% !important;
      .ant-input {
        width: 100% !important;
      }
    }
  }
  .ant-radio-wrapper {
    margin-right: 35px;
  }
  .ant-radio-inner::after {
    background-color: #d86464;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #d86464;
  }
  .text-field {
    height: 57px;
    width: 360px;
  }

  .address {
    width: 100%;
  }

  .select-field {
    height: 50px;
    width: 360px;
    background-color: ${theme.background.primary};

    @media (max-width: 786px) {
      width: 100%;
    }
  }

  .text-area {
    textarea {
      background-color: ${theme.background.primary};
      border: 1px solid #dedbd5;
      border-radius: 10px;
      box-shadow: none;
    }
  }

  input {
    background-color: ${theme.background.primary};
    border: 1px solid #dedbd5;
    border-radius: 10px;
    box-shadow: none;
  }

  .email_address {
    width: 100%;
  }

  .select-component {
    width: 360px;

    @media (max-width: 786px) {
      width: 100%;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 50px;
    background: #f8f5ef;
    border: 1px solid #dedbd5;
    border-radius: 10px;

    @media (max-width: 786px) {
      min-height: 50px;
    }
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    margin-top: 10px;
    font-size: 16px;
  }

  .optional,
  .optionalDown {
    font-weight: 400;
    font-size: 16px;
    color: #d86464;
  }

  .optionalDown {
    margin-top: 16px;
  }

  @media (max-width: 786px) {
    .text-field {
      height: 65px;
      width: 100%;
    }
  }

  .upload-btn {
    width: 100%;
    max-width: 360px;
    height: 50px;
    border-radius: 10px;
    background-color: rgba(70, 150, 196, 0.15);
    border-width: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #4696c3;
      font-size: 16px;
    }

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .border-bottom {
    border-bottom: 1px solid #dddcdc;
    padding: 10px;
  }
  & :last-child .border-bottom {
    border-bottom: none;
    padding: 0;
  }
  .padding-left {
    padding-left: 25px;
    word-break: break-word;
    white-space: break-spaces;
  }
`

export const LabelContainer = styled.div`
  margin-bottom: 20px;
  @media (max-width: 786px) {
    margin-bottom: 14px;
  }
`

export const Label = styled.div`
  font-weight: 500;
  font-size: 16px;

  ::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba(226, 110, 48, 0.2);
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  @media (max-width: 786px) {
    font-size: 16px;

    ::before {
      width: 12px;
      height: 12px;
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  .save-btn {
    width: 160px;
    height: auto;
    padding: 15px 0;
    border: transparent;

    @media (max-width: 786px) {
      width: 240px;
    }
  }
`
export const ErrorCounterWrapper = styled.div`
  height: 5px;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
`
export const ErrorStyled = styled.span`
  font-size: 16px;
  font-family: ${(props) => props.theme.font.NS};
  font-weight: ${(props) => props.theme.text.weight.regular};
  color: ${(props) => props.theme.alert};
  margin-top: 2px;
  margin-left: 2px;
`
