import { API } from "@project/shared"

export interface IReturnAsset {
  count?: number
  data?: any
  nextPage?: number
}

interface IAssetQueryParam {
  page?: string | number
  pageSize?: string
  role?: string
  keyword?: string
  status?: string
  user_id?: string
}

export const fetchInfiniteAsset = async ({
  queryKey,
  pageParam = 1,
}): Promise<IReturnAsset> => {
  const params = {} as IAssetQueryParam
  params.page = pageParam
  params.pageSize = queryKey[1].pageSize
  params.keyword = queryKey[1].keyword
  if (queryKey[1].user_id) {
    params.user_id = queryKey[1]?.user_id
  }
  const result = await API.get("/assets", {
    params,
  })
  result["nextPage"] = Number(pageParam) * Number(queryKey[1].pageSize) + 1
  return result
}

export const getAssetById = async (id: string | string[]) => {
  return API.get(`/assets/${id}`)
}

export const createAsset = async (values): Promise<any> => {
  return API.post(`/assets`, values)
}

export const updateAsset = async (values) => {
  const id = values.id
  return API.put(`/assets/${id}`, values)
}

export const deleteAsset = async (id) => {
  return API.delete(`/assets/${id}`)
}
