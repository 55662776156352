import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation } from "react-query"
import * as yup from "yup"
import { notification, Spin, Select as AntSelect } from "antd"
import { TextField, Button, Select } from "@project/shared"
import { CARE } from "@project/shared/src/utils/constant"
import {
  careFacilityCostOption,
  careHouseFacility,
  careHouseOption,
  longTermCareOption,
  mealsAllergiesOptions,
} from "../../../../constants/hospitalization&care"
import {
  createHospitalizationCare,
  getHospitalizationCareById,
  updateHospitalizationCare,
} from "../../../../services/hospitalization-care"
import {
  ButtonWrapper,
  ErrorCounterWrapper,
  ErrorStyled,
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../RelativesFriendsForm/helper"
import { useEffect } from "react"

interface Props {
  id?: string | string[]
}

const CareForm: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const {
    data: careHomeData,
    isLoading: careHomeLoading,
    isFetching: careHomeFetching,
  } = useQuery<any, Error>(
    ["careHome", id],
    () => id && getHospitalizationCareById(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) => {
        return {
          about: data?.carehouse?.about,
          judgement_holder: data?.carehouse?.judgement_holder,
          desire: data?.carehouse?.desire,
          care_type: data?.carehouse?.care_type,
          food_allergy: data?.carehouse?.food_allergy,
          allergy_detail: data?.carehouse?.allergy_detail,
          other_location: data?.carehouse?.other_location,
          other_care_type: data?.carehouse?.other_care_type,
          carehouse_payment: data?.carehouse?.carehouse_payment,
          care_house_location: data?.carehouse?.care_house_location,
          about_other_location: data?.carehouse?.about_other_location,
          other_payment_method: data?.carehouse?.other_payment_method,
        }
      },
    }
  )

  const initialValues = careHomeData || {
    judgement_holder: "",
    care_type: null,
    other_care_type: "",
    care_house_location: [],
    other_location: "",
    about: [],
    about_other_location: "",
    food_allergy: null,
    allergy_detail: "",
    carehouse_payment: null,
    other_payment_method: "",
    desire: "",
  }

  const validationSchema = yup.object().shape({
    judgement_holder: yup.string(),
    care_type: yup.string().nullable().required(t("Required")),
    other_care_type: yup.string(),
    care_house_location: yup.array().nullable(),
    other_location: yup.string(),
    about: yup.array().nullable(),
    about_other_location: yup.string(),
    food_allergy: yup.string().nullable(),
    allergy_detail: yup.string(),
    carehouse_payment: yup.string().nullable(),
    other_payment_method: yup.string(),
    desire: yup.string(),
  })
  const handleChangeOption = (value, fieldName) => {
    formik.setFieldValue(fieldName, value)
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id) {
        mutate({ type: CARE, carehouse: values, id: id })
      } else {
        mutate({ type: CARE, carehouse: values })
      }
    },
  })
  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])
  const { mutate, isLoading, isSuccess } = useMutation(
    id ? updateHospitalizationCare : createHospitalizationCare,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: t("Care house information has been updated"),
          })
        } else {
          notification.success({
            message: t("Care house information has been successfully created"),
          })
        }
        router.push("/my-thoughts/hospitalization-care-house")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )
  if (careHomeLoading || careHomeFetching) {
    return (
      <div className="loader-wrapper">
        <Spin />
      </div>
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldContainer>
        <LabelContainer>
          <Label>
            {t("Who would you like to take care of if you lose your judgment?")}
          </Label>
        </LabelContainer>
        <TextField
          name="judgement_holder"
          value={formik.values.judgement_holder}
          onChange={formik.handleChange}
          error={
            formik.touched.other_care_type && formik.errors?.other_care_type
          }
          onBlur={formik.handleBlur}
          placeholder={t(
            "Who would you like to take care of if you lose your judgment?"
          )}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("What do you want if you need long-term care?")}</Label>
        </LabelContainer>
        <Select
          name="care_type"
          type="validate"
          value={formik.values.care_type || null}
          options={longTermCareOption}
          onChange={(value) => handleChangeOption(value, "care_type")}
          placeholder={t("What do you want if you need long-term care?")}
          className="select-component"
          error={
            formik.touched.care_type && formik.errors?.care_type
              ? formik.errors?.care_type
              : false
          }
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Other details if any")}</Label>
        </LabelContainer>
        <TextField
          name="other_care_type"
          value={formik.values.other_care_type}
          onChange={formik.handleChange}
          error={
            formik.touched.other_care_type && formik.errors?.other_care_type
          }
          onBlur={formik.handleBlur}
          placeholder={t("Other details if any")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Care house facility location")}</Label>
        </LabelContainer>
        <AntSelect
          value={formik.values.care_house_location}
          options={careHouseOption}
          onChange={(value) => handleChangeOption(value, "care_house_location")}
          mode="multiple"
          placeholder={t("Care house facility location")}
          className="select-component"
          style={{ width: "100%" }}
          showArrow
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Other in details")}</Label>
        </LabelContainer>
        <TextField
          name="other_location"
          value={formik.values.other_location}
          onChange={formik.handleChange}
          error={formik.touched.other_location && formik.errors?.other_location}
          onBlur={formik.handleBlur}
          placeholder={t("Other in details")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("About the care house facility")}</Label>
        </LabelContainer>
        <AntSelect
          value={formik.values.about}
          options={careHouseFacility}
          onChange={(value) => handleChangeOption(value, "about")}
          mode="multiple"
          placeholder={t("About the care house facility")}
          className="select-component"
          style={{ width: "100%" }}
          showArrow
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Other in details")}</Label>
        </LabelContainer>
        <TextField
          name="about_other_location"
          value={formik.values.about_other_location}
          onChange={formik.handleChange}
          error={
            formik.touched.about_other_location &&
            formik.errors?.about_other_location
          }
          onBlur={formik.handleBlur}
          placeholder={t("Other in details")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Meals allergies")}</Label>
        </LabelContainer>
        <Select
          type="validate"
          value={formik.values.food_allergy || null}
          options={mealsAllergiesOptions}
          onChange={(value) => handleChangeOption(value, "food_allergy")}
          placeholder={t("Meals allergies")}
          className="select-component"
        />
        <ErrorCounterWrapper>
          {formik.errors.food_allergy && formik.touched.food_allergy && (
            <ErrorStyled>{formik.errors.food_allergy}</ErrorStyled>
          )}
        </ErrorCounterWrapper>
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("「If yes」Other in details")}</Label>
        </LabelContainer>
        <TextField
          name="allergy_detail"
          value={formik.values.allergy_detail}
          onChange={formik.handleChange}
          error={formik.touched.allergy_detail && formik.errors?.allergy_detail}
          onBlur={formik.handleBlur}
          placeholder={t("「If yes」Other in details")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Care house facility cost")}</Label>
        </LabelContainer>
        <Select
          type="validate"
          name="carehouse_payment"
          value={formik.values.carehouse_payment || null}
          options={careFacilityCostOption}
          onChange={(value) => handleChangeOption(value, "carehouse_payment")}
          placeholder={t("Care house facility cost")}
          className="select-component"
          error={
            formik.touched.carehouse_payment && formik.errors?.carehouse_payment
              ? formik.errors?.carehouse_payment
              : false
          }
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Other in details")}</Label>
        </LabelContainer>
        <TextField
          name="other_payment_method"
          value={formik.values.other_payment_method}
          onChange={formik.handleChange}
          error={
            formik.touched.other_payment_method &&
            formik.errors?.other_payment_method
          }
          onBlur={formik.handleBlur}
          placeholder={t("Other in details")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Other things I want, don’t want you to do")}</Label>
        </LabelContainer>
        <TextField
          name="desire"
          value={formik.values.desire}
          onChange={formik.handleChange}
          error={formik.touched.desire && formik.errors?.desire}
          onBlur={formik.handleBlur}
          placeholder={t("Other things I want, don’t want you to do")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>
      <ButtonWrapper>
        <Button
          htmlType="submit"
          loading={isLoading}
          disabled={isSuccess}
          width="200px"
          height="50px"
          background="#E26E30"
        >
          {id ? t("Update") : t("Add")}
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export { CareForm }
