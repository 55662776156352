import React from "react"
import Router from "next/router"
import { useTranslation } from "react-i18next"
import { Button, theme } from "@project/shared"
import {
  LeftOutlined,
  PlusCircleOutlined,
  RightOutlined,
} from "@ant-design/icons"
import {
  Wrapper,
  Header,
  ImgWrapper,
  Title,
  BottomContainer,
  LinkText,
  IconBox,
  LinkBox,
  SuffixIconWrapper,
} from "./styles"

/* 

* for back button inside detail page of viewer image and title also made optional
*/
interface Props {
  img?: string
  title?: string
  button?: boolean
  buttonText?: string
  route?: string
  backRoute?: string
  linkBox?: boolean
  linkText?: string
  link?: string
  noBack?: boolean
  showLockIcon?: boolean
  LockIcon?: React.ReactNode
  onClick?: () => void
  handleBackButtonClick?: () => void
}

const TopPage = ({
  img,
  title,
  button,
  buttonText,
  route,
  backRoute,
  linkText,
  link,
  linkBox = false,
  noBack,
  handleBackButtonClick,
  onClick,
  showLockIcon = false,
  LockIcon,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      {!noBack && (
        <Button
          background={theme.background.primary}
          color={theme.text.color.light}
          bordercolor="transparent"
          borderradius="10px"
          onClick={() => {
            if (backRoute) {
              Router.push(backRoute)
            } else {
              handleBackButtonClick()
            }
          }}
          className="backBtn"
        >
          <LeftOutlined />
          {t("Back")}
        </Button>
      )}
      <BottomContainer>
        <Header>
          {img && (
            <ImgWrapper>
              <img
                src={`/assets/icons/${img}.svg`}
                alt="icon"
                height={32}
                width={32}
              />
            </ImgWrapper>
          )}
          {title && <Title>{t(`${title}`)}</Title>}
          {showLockIcon && (
            <SuffixIconWrapper onClick={onClick}>{LockIcon}</SuffixIconWrapper>
          )}
        </Header>

        {button && (
          <Button
            background={theme.base}
            color={theme.text.color.light}
            borderradius="10px"
            className="rightBtn"
            fontSize="18px"
            onClick={() => Router.push(`${route}`)}
          >
            {t(`${buttonText}`)}
            <PlusCircleOutlined className="icon" />
          </Button>
        )}
        {linkBox && (
          <LinkBox onClick={() => Router.push(link)}>
            <LinkText>{linkText}</LinkText>
            <IconBox>
              <RightOutlined />
            </IconBox>
          </LinkBox>
        )}
      </BottomContainer>
    </Wrapper>
  )
}

export { TopPage }
