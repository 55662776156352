import React, { useEffect } from "react"
import { t } from "i18next"
import { FormikProps } from "formik"
import { TextField, Button, theme, Select } from "@project/shared"
import {
  TextFieldContainer,
  LabelContainer,
  Label,
  ButtonWrapper,
  relationOptions,
} from "./helper"

interface Props {
  formik: FormikProps<any>
}

const InviteFamilyForm: React.FC<Props> = ({ formik }) => {
  const handleRelationChange = (value) => {
    formik.setFieldValue("relation", value)
  }
  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Inviting name")}</Label>
        </LabelContainer>
        <TextField
          name="name"
          placeholder={t("Name")}
          className="text-field"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && formik.errors?.name}
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Mobile number")}</Label>
        </LabelContainer>
        <TextField
          name="phone"
          placeholder={t("Mobile number")}
          className="text-field"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && formik.errors?.phone}
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Email address")}</Label>
        </LabelContainer>
        <TextField
          name="email"
          placeholder={t("Email address")}
          className="text-field email_address"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors?.email}
        />
        <div
          className={
            formik.errors?.email && formik.touched?.email
              ? "optionalDown"
              : "optional"
          }
        >
          {t("※Email address is optional if phone number is entered")}
        </div>
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Relation")}</Label>
        </LabelContainer>

        <Select
          type="validate"
          name="relation"
          className="select-component"
          value={formik.values.relation || null}
          options={relationOptions}
          onChange={handleRelationChange}
          placeholder={t("Relation")}
          error={
            formik.touched.relation && formik.errors?.relation
              ? formik.errors?.relation
              : false
          }
        />
      </TextFieldContainer>

      <ButtonWrapper>
        <Button
          htmlType="submit"
          background={theme.button.primary}
          className="confirm-btn"
          onClick={() => window.scroll(0, 0)}
        >
          {t("Confirm details")}
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export { InviteFamilyForm }
