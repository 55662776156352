import React from "react"
import { t } from "i18next"
import { Button, theme } from "@project/shared"
import { IInviteFamily } from "../../../interface"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import {
  Label,
  LabelContainer,
  RowContainer,
  Text,
  Notes,
  ButtonWrapper,
} from "./helper"

interface Props {
  userData: IInviteFamily
  goBack: () => void
  handleRegister: () => void
  isLoading: boolean
}

const FamilyShareConfirmation: React.FC<Props> = ({
  userData,
  goBack,
  handleRegister,
  isLoading,
}) => {
  return (
    <div>
      <RowContainer>
        <LabelContainer>
          <Label>{t("Name")}</Label>
        </LabelContainer>
        <Text>{userData?.name}</Text>
      </RowContainer>
      <RowContainer>
        <LabelContainer>
          <Label>{t("Mobile number")}</Label>
        </LabelContainer>
        <Text>{userData?.phone}</Text>
      </RowContainer>
      {userData?.email && (
        <RowContainer>
          <LabelContainer>
            <Label>{t("Email address")}</Label>
          </LabelContainer>
          <Text>{userData?.email}</Text>
        </RowContainer>
      )}
      <RowContainer>
        <LabelContainer>
          <Label>{t("Relation")}</Label>
        </LabelContainer>
        <Text>{userData?.relation}</Text>
      </RowContainer>

      <a href="/careful-notes" target="_blank">
        <Notes>
          <ExclamationCircleOutlined />
          <span>
            {t("Please read the careful notes when inviting a family")}
          </span>
        </Notes>
      </a>

      <ButtonWrapper>
        <Button
          background={theme.button.secondary}
          onClick={goBack}
          className="btn"
        >
          {t("Correct")}
        </Button>
        <Button
          background={theme.button.primary}
          onClick={handleRegister}
          loading={isLoading}
          className="btn"
        >
          {t("Invite")}
        </Button>
      </ButtonWrapper>
    </div>
  )
}

export { FamilyShareConfirmation }
