import { useTranslation } from "react-i18next"
import { Radio } from "antd"
import { Button } from "@project/shared"
import { hospitalizationOrCareType } from "../../../../constants"
import { useState } from "react"
import {
  ButtonWrapper,
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../RelativesFriendsForm/helper"

interface iHospitalizationSelectionForm {
  setHospitalizationType?: React.Dispatch<React.SetStateAction<string>> | any
  value?: string
  disableRadio?: boolean
}

const HospitalizationSelectionForm: React.FC<iHospitalizationSelectionForm> = ({
  setHospitalizationType,
  value,
  disableRadio,
}) => {
  const { t } = useTranslation()
  const [hospitalization, setHospitalization] = useState(value)

  const handleChangeOption = (evt) => {
    setHospitalization(evt.target.value)
  }

  return (
    <>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Type")}</Label>
        </LabelContainer>
        <Radio.Group
          options={hospitalizationOrCareType}
          onChange={handleChangeOption}
          value={hospitalization || null}
          disabled={!!disableRadio}
        />
      </TextFieldContainer>
      <ButtonWrapper>
        <Button
          htmlType="button"
          width="200px"
          height="50px"
          background="#E26E30"
          onClick={() => setHospitalizationType(hospitalization)}
        >
          {t("Next")}
        </Button>
      </ButtonWrapper>
    </>
  )
}

export { HospitalizationSelectionForm }
