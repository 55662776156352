import React from "react"
import Router from "next/router"
import styled from "styled-components"
import { Button, theme } from "@project/shared"
import { t } from "i18next"

const SignupContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 12px 0 80px 0;

  @media (max-width: 750px) {
    margin: 0px 0 50px;
  }
`

const SignupWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn {
    width: 650px;
    height: 80px;
    border: transparent;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      font-weight: 500;
      font-size: 24px;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  .text-wrapper {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      text-decoration: underline;
      margin-right: 8px;
      font-weight: 400;
      font-size: 20px;
    }

    .img {
      height: 20px;
      width: 20px;
    }
  }

  @media (max-width: 750px) {
    padding: 0 10px;

    .btn {
      margin-top: 12px;
      width: 100%;
      height: 60px;
      > div {
        justify-content: center;
        font-weight: 500;
        font-size: 16px;
        width: 100%;
        img {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
    }
  }

  @media (max-width: 350px) {
    .btn {
      > div {
        font-size: 15px;
      }
    }
  }
`
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding-top: 45px;

  .btn-how-to-use,
  .btn-experience {
    min-width: 160px;
    display: flex;
    padding: 10px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid #e26e30;
    background: #f7f5f0;
    cursor: pointer;
    > span {
      color: #e26e30;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    @media (min-width: 750px) {
      min-width: 230px;
    }
    @media (max-width: 360px) {
      min-width: 140px;
    }
  }

  @media (max-width: 750px) {
    padding-top: 30px;
  }
`
const Signup = () => {
  const redirectToLink = () => {
    window.open(
      "https://www.moshimo.net/line/kaki-oki/index.html?utm_source=social&utm_medium=line&utm_campaign=kak-oki_taikendan",
      "_blank"
    )
  }
  return (
    <>
      <ButtonContainer>
        <div
          className="btn-how-to-use"
          onClick={() => Router.push("/how-to-use")}
        >
          <img src="/assets/icons/cam.svg" alt="btnImg" />
          <span>{t("How to use")}</span>
        </div>
        <div className="btn-experience" onClick={redirectToLink}>
          <img src="/assets/icons/messagecircle.svg" alt="btnImg" />
          <span>{t("Experience")}</span>
        </div>
      </ButtonContainer>
      <SignupContainer>
        <SignupWrapper>
          <Button
            background={theme.background.navBar.secondary}
            className="btn"
            onClick={() => Router.push("/signup")}
          >
            <div>
              <img src="/assets/icons/ico.svg" alt="btnImg" />
              初めての方はこちら（新規登録）
            </div>
          </Button>

          <div className="text-wrapper" onClick={() => Router.push("/login")}>
            <span>はじめる（ログイン）</span>
            <img className="img" src="/assets/icons/login.svg" alt="logout" />
          </div>
        </SignupWrapper>
      </SignupContainer>
    </>
  )
}

export default Signup
