export const LOCKITEMS_TYPES = {
  friends_properties: "friends_properties",
  my_thoughts: "my_thoughts",
  friends: "friends",
  assets: "assets",
  insurance: "insurance",
  storage: "storage",
  medias: "medias",
  pets: "pets",
  hospitals: "hospitals",
  funeral: "funeral",
  grave: "grave",
  consultations: "consultations",
}
