import styled from "styled-components"

export const Content = styled.div`
  margin-top: 30px;
  width: 100%;

  .second-section {
    margin-top: 56px;
  }
  @media (min-width: 786px) {
    margin-top: 0;
    margin-left: 50px;
    .second-section {
      margin-top: 66px;
    }
  }
  @media (min-width: 950px) {
    margin-left: 100px;
  }
`

export const Notification = styled.div`
  width: 100%;
  padding: 15px;
  color: #e26e30;
  font-size: 16px;
  border-radius: 10px;
  text-align: center;
  border: 1px solid #e26e30;

  @media (max-width: 786px) {
    font-size: 16px;
    padding: 10px;
  }
`

export const Body = styled.div`
  width: 100%;
  margin-top: 32px;

  @media (max-width: 786px) {
    margin-top: 0px;
  }
`

export const ImageWrapper = styled.div`
  margin-top: 20px;
  background-color: rgba(70, 150, 196, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 582px;

  img {
    width: 335px;
    height: 498.1px;
  }

  @media (max-width: 786px) {
    margin-top: 30px;
    position: absolute;
    left: 0;
  }

  @media (max-width: 380px) {
    height: 500px;

    img {
      width: 90%;
      height: 450px;
    }
  }
`

export const DescriptionWrapper = styled.div`
  background-color: #f8f5ef;
  border-radius: 10px;
  margin-top: 40px;
  padding: 30px 25px;

  @media (max-width: 786px) {
    padding: 25px 20px;
    margin-top: 640px;
  }

  @media (max-width: 380px) {
    margin-top: 560px;
  }
`

export const Title = styled.p`
  font-weight: 500;
  font-size: 15px;

  @media (max-width: 786px) {
    font-size: 16px;
  }
`

export const List = styled.ol`
  margin: 30px 0;
  margin-left: 30px;

  div {
    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
    }
  }

  @media (max-width: 786px) {
    margin: 15px 0;
    margin-left: 15px;

    div {
      span {
        font-size: 16px;
      }
    }
  }
`

export const Footer = styled.span`
  font-weight: 500;
  font-size: 15px;

  @media (max-width: 786px) {
    font-size: 16px;
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
  height: 100vh;
`

export const CheckBox = styled.div`
  .icon {
    margin-right: 5px;
    color: #0dde61;
  }
`
