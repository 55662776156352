import { t } from "i18next"
import Router from "next/router"
import { Button, theme } from "@project/shared"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"

export const Buttons = ({ id, setIsModalVisible, setID, editRoute }) => {
  const handleDelete = () => {
    setIsModalVisible(true)
    setID(id)
  }

  return (
    <>
      <Button
        background={theme.background.navBar.secondary}
        className="btn"
        onClick={() => Router.push(`${editRoute}`)}
      >
        <div>
          <EditOutlined />
          {t("Edit")}
        </div>
      </Button>

      <Button background="#808080" className="btn" onClick={handleDelete}>
        <div>
          <DeleteOutlined />
          {t("Delete")}
        </div>
      </Button>
    </>
  )
}
