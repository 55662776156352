import React from "react"
import { t } from "i18next"
import { Modal } from "antd"
import styled from "styled-components"
import { Button, theme } from "@project/shared"

interface Props {
  isVisible: boolean
  onCancelHandler: () => void
  onDeleteHandler: () => void
  isLoading: boolean
}

const ModalContainer = styled(Modal)`
  .ant-modal-content {
    border: 1px solid #808080 !important;
    border-radius: 10px !important;

    @media (max-width: 786px) {
      margin: 0px 20px;
    }
  }
`

const ModalBodyWrapper = styled.div`
  padding: 15px;
  text-align: center;

  .title {
    color: ${theme.alert};
  }

  .button-wrapper {
    display: flex;
    margin-top: 15px;
    justify-content: center;

    .btn {
      margin-right: 20px;
      padding: 12px 20px;
      height: auto;
      width: 160px;
    }

    @media (max-width: 786px) {
      flex-direction: column;
      align-items: center;

      .btn {
        width: 240px;
        margin-right: 0px;
      }

      .btn:last-child {
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 786px) {
    padding: 0 10px;
  }
`

const DeleteModal: React.FC<Props> = ({
  isVisible,
  onCancelHandler,
  onDeleteHandler,
  isLoading,
}) => {
  return (
    <ModalContainer
      visible={isVisible}
      onCancel={onCancelHandler}
      centered
      footer={null}
      closable={false}
      className="modal-class"
    >
      <ModalBodyWrapper>
        <span className="title">
          {t("Are you sure you want to delete this information?")}
        </span>
        <div className="button-wrapper">
          <Button
            background="#808080"
            className="btn"
            onClick={onCancelHandler}
          >
            {t("Cancel")}
          </Button>
          <Button
            background={theme.background.navBar.secondary}
            className="btn"
            onClick={onDeleteHandler}
            loading={isLoading}
          >
            {t("Delete")}
          </Button>
        </div>
      </ModalBodyWrapper>
    </ModalContainer>
  )
}

export { DeleteModal }
