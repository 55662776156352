import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { Button, RELATIONAPPROVED, RELATIONREJECTED } from "@project/shared"
import { t } from "i18next"
import router from "next/router"
import React, { useContext } from "react"
import styled from "styled-components"
import { relationType } from "../../../constants"
import { LineShare } from "../../organisms"
import { AuthContext } from "../../../utils"

interface iFamilyCard {
  indexNumber: number
  name?: string
  relation?: string
  invitedDate?: string
  approveStatus?: string
  id?: string
  phone?: string
  email?: string
  showEdit?: boolean
  showLine?: boolean
  invitationLink?: string
  isViewer?: boolean
}

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #dddcdc;
  border-radius: 10px;
`
const Header = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  padding-left: 21px;
  padding-right: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid #dddcdc;
`

const Number = styled.div`
  display: contents;
`
const ButtonWrapper = styled.div`
  @media (max-width: 485px) {
    display: none;
  }
`

const MobileButtonWrapper = styled.div`
  display: none;
  @media (max-width: 485px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`
const MobileBtnContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .text {
    display: flex;
    margin: auto;
  }
`
const BtnContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  .text {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    margin: auto;
  }
  .line-icon {
    width: 18px;
    height: 18px;
  }
`
const Content = styled.div`
  margin: 33px 40px 45px 40px;
  .no-border {
    border: none;
  }
  @media (max-width: 485px) {
    margin: 27px 20px 32px 20px;
    .no-border {
      border-bottom: 1px solid #dddcdc;
    }
  }
`
const Row = styled.div`
  border-bottom: 1px solid #dddcdc;
`
const TH = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-top: 18px;
  @media (max-width: 485px) {
    font-size: 16px;
  }
`

const TD = styled.div`
  margin-left: 23px;
  margin-top: 15px;
  margin-bottom: 15px;
`

const Status = styled.div`
  color: #4696c3;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  svg {
    margin-right: 5px;
  }
`
const getApproveStatus = (status: string) => {
  switch (status) {
    case RELATIONAPPROVED:
      return (
        <Status>
          <CheckCircleOutlined />
          {t("Approved")}
        </Status>
      )
    case RELATIONREJECTED:
      return (
        <Status style={{ color: "#ED1212" }}>
          <CloseCircleOutlined />
          {t("No access")}
        </Status>
      )
    default:
      return (
        <Status style={{ color: "#808080" }}>
          <CheckCircleOutlined />
          {t("Not approved")}
        </Status>
      )
  }
}
const getRelationship = (relation) => {
  for (let item = 0; item < relationType?.length; item++) {
    if (relationType[item].value === relation) {
      return relationType[item].label
    }
  }
}
export const FamilyCard: React.FC<iFamilyCard> = ({
  indexNumber,
  name,
  relation,
  invitedDate,
  approveStatus,
  id,
  phone,
  email,
  showEdit,
  showLine,
  invitationLink,
  isViewer,
}) => {
  const { user } = useContext(AuthContext)

  const openShare = () => {
    window.open(
      `http://line.me/R/msg/text/?${user?.displayName}様よりデジタルエンディングノート「Kaki-Oki」（かきおき）の招待が届いています。ノートの閲覧、作成はこちらからURL「Kaki-Oki（かきおき）」とは、自分にもしものことがあったときに残された家族が困ることが無いよう、必要な情報を書き残しておくデジタルエンディングノートです。困らせたくない家族を「招待」することで、元気なうちから情報共有ができるシニア向け終活ツールです。${invitationLink}`,
      "_blank"
    )
  }

  return (
    <Wrapper>
      <Header>
        <Number>No.{indexNumber}</Number>
        <ButtonWrapper>
          {showEdit ? (
            <Button
              width="96px"
              height="34px"
              background="#E26E30"
              onClick={() => router.push(`/family/${id}`)}
            >
              <BtnContent>
                <img src="/assets/icons/edit-icon.svg" alt="edit icon" />
                <div className="text">{t("Edit")}</div>
              </BtnContent>
            </Button>
          ) : (
            !isViewer &&
            showLine && (
              <LineShare
                title={`${user?.displayName}様よりデジタルエンディングノート「Kaki-Oki」（かきおき）の招待が届いています。ノートの閲覧、作成はこちらからURL「Kaki-Oki（かきおき）」とは、自分にもしものことがあったときに残された家族が困ることが無いよう、必要な情報を書き残しておくデジタルエンディングノートです。困らせたくない家族を「招待」することで、元気なうちから情報共有ができるシニア向け終活ツールです。`}
                url={invitationLink}
              >
                <Button height="34px" width="220px" background="#06C755">
                  <BtnContent>
                    <img
                      src="/assets/icons/line.svg"
                      alt="line icon"
                      className="line-icon"
                    />
                    <div className="text">{t("Resend Invitation URL")}</div>
                  </BtnContent>
                </Button>
              </LineShare>
            )
          )}
        </ButtonWrapper>
      </Header>
      <Content>
        <Row>
          <TH>
            <span
              style={{ marginRight: "5px", color: "rgba(226, 110, 48, 0.2)" }}
            >
              ●
            </span>
            {t("Name")}
          </TH>
          <TD>{name}</TD>
        </Row>
        <Row>
          <TH>
            <span
              style={{ marginRight: "5px", color: "rgba(226, 110, 48, 0.2)" }}
            >
              ●
            </span>
            {t("Relation")}
          </TH>
          <TD>{getRelationship(relation)}</TD>
        </Row>
        {phone && (
          <Row>
            <TH>
              <span
                style={{ marginRight: "5px", color: "rgba(226, 110, 48, 0.2)" }}
              >
                ●
              </span>
              {t("Phone")}
            </TH>
            <TD>{phone}</TD>
          </Row>
        )}
        {email && (
          <Row>
            <TH>
              <span
                style={{ marginRight: "5px", color: "rgba(226, 110, 48, 0.2)" }}
              >
                ●
              </span>
              {t("Email address")}
            </TH>
            <TD style={{ wordBreak: "break-all" }}>{email}</TD>
          </Row>
        )}
        <Row>
          <TH>
            <span
              style={{ marginRight: "5px", color: "rgba(226, 110, 48, 0.2)" }}
            >
              ●
            </span>
            {t("Invited date & time")}
          </TH>
          <TD>{invitedDate}</TD>
        </Row>
        {approveStatus && (
          <Row className="no-border">
            <TH>
              <span
                style={{ marginRight: "5px", color: "rgba(226, 110, 48, 0.2)" }}
              >
                ●
              </span>
              {t("Approve status")}
            </TH>
            <TD>{getApproveStatus(approveStatus)}</TD>
          </Row>
        )}
        {showEdit ? (
          <MobileButtonWrapper>
            <Button
              width="240px"
              height="50px"
              background="#E26E30"
              onClick={() => router.push(`/family/${id}`)}
            >
              <MobileBtnContent>
                <img src="/assets/icons/edit-icon.svg" alt="edit icon" />
                <div className="text">{t("Edit")}</div>
              </MobileBtnContent>
            </Button>
          </MobileButtonWrapper>
        ) : (
          !isViewer &&
          showLine && (
            <MobileButtonWrapper>
              <Button
                width="240px"
                height="50px"
                background="#06C755"
                onClick={openShare}
              >
                <MobileBtnContent>
                  <img
                    src="/assets/icons/line.svg"
                    alt="line icon"
                    width={23}
                    height={23}
                  />
                  <div className="text">{t("Resend Invitation URL")}</div>
                </MobileBtnContent>
              </Button>
            </MobileButtonWrapper>
          )
        )}
      </Content>
    </Wrapper>
  )
}
