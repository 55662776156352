import styled from "styled-components"
import { theme } from "@project/shared"

export const Container = styled.div`
  border: 1px solid #dddcdc;
  border-radius: 10px;
  margin-bottom: 40px;

  @media (max-width: 786px) {
    margin-bottom: 30px;
  }
`

export const TitleWrapper = styled.div`
  padding: 13px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${theme.background.primary};
  border-bottom: 1px solid #dddcdc;

  @media (max-width: 786px) {
    padding: 13px 20px;
  }
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
`

export const ButtonWrapper = styled.div`
  .btn {
    padding: 0px 25px;
    height: 40px;
    border: transparent;

    > div {
      font-weight: 400;
      font-size: 16px;

      span {
        margin-right: 8px;
      }
    }

    :last-child {
      margin-left: 15px;
    }
  }

  @media (max-width: 786px) {
    display: none;
  }
`

export const Body = styled.div`
  padding: 32px 42px;

  .eachBody {
    border-bottom: 1px solid #dddcdc;
    margin-bottom: 20px;

    .text {
      margin-left: 25px;
      word-break: break-word;

      @media (max-width: 786px) {
        margin-left: 23px;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 786px) {
    padding: 30px 28px 10px;
  }
`

export const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 15px;

  ::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba(226, 110, 48, 0.2);
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  @media (max-width: 786px) {
    font-size: 16px;

    ::before {
      width: 12px;
      height: 12px;
    }
  }
`

export const MobileButtonWrapper = styled.div`
  display: none;

  @media (max-width: 786px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 34px;
    align-items: center;

    .btn {
      width: 240px;
      height: 50px;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
      }

      :first-child {
        margin-bottom: 10px;
      }
    }
  }
`
