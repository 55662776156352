import { LockItemsTypes } from "../interface"
import {
  ADD_USER_AUTH_STATE,
  ADD_USER_DETAIL,
  CLEAR_USER_AUTH_STATE,
  LOCK_CONTENTS,
  MUTATE_LOCKED_DATA,
  OPEN_LOCK_MODAL,
  ADD_VIEWERS,
} from "./Constants"
import { checkAccessibilityCondition } from "../utils/helpers/accessibility/checkLockedItem"

export const addUserAuthState = (data: any) => {
  return {
    type: ADD_USER_AUTH_STATE,
    payload: { data },
  }
}

export const clearUserAuthState = (data: any) => {
  return {
    type: CLEAR_USER_AUTH_STATE,
    payload: { data },
  }
}

export const lockContent = (key: string) => {
  return {
    type: LOCK_CONTENTS,
    payload: { key },
  }
}

export const addUserDetail = (data: LockItemsTypes) => {
  const boolArray: Array<boolean> = checkAccessibilityCondition(data)
  return {
    type: ADD_USER_DETAIL,
    accessibilityArray: boolArray,
    payload: { data },
  }
}

export const openLockedModal = () => {
  return {
    type: OPEN_LOCK_MODAL,
    payload: {},
  }
}

export const mutateLockedData = (data) => {
  return {
    type: MUTATE_LOCKED_DATA,
    payload: { data },
  }
}

export const addViewers = (data: any) => {
  return {
    type: ADD_VIEWERS,
    payload: { data },
  }
}
