import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation } from "react-query"
import * as yup from "yup"
import { notification, Spin } from "antd"
import { TextField, Button } from "@project/shared"
import {
  createAsset,
  getAssetById,
  updateAsset,
} from "../../../../services/asset"
import { CREDITCARD } from "@project/shared/src/utils/constant"
import {
  ButtonWrapper,
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../RelativesFriendsForm/helper"
import { useEffect } from "react"

interface Props {
  id?: string | string[]
}

const CreditForm: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const {
    data: creditCardData,
    isLoading: creditCardLoading,
    isFetching: creditCardFetching,
  } = useQuery<any, Error>(["creditCard", id], () => id && getAssetById(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: ({ data }) => {
      return {
        id: id,
        name: data?.credit_cards?.name,
        brand: data?.credit_cards?.brand,
        nominee: data?.credit_cards?.nominee,
        use: data?.credit_cards?.use,
        contact_on_lost: data?.credit_cards?.contact_on_lost,
        memo_notes: data?.credit_cards?.memo_notes,
      }
    },
  })

  const initialValues = creditCardData || {
    name: "",
    brand: "",
    nominee: "",
    use: "",
    contact_on_lost: "",
    memo_notes: "",
  }

  const validationSchema = yup.object().shape({
    name: yup.string(),
    brand: yup.string(),
    nominee: yup.string(),
    use: yup.string(),
    contact_on_lost: yup.string(),
    memo_notes: yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id) {
        mutate({ assets_type: CREDITCARD, credit_cards: values, id: id })
      } else {
        mutate({ assets_type: CREDITCARD, credit_cards: values })
      }
    },
  })
  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])

  const { mutate, isLoading, isSuccess } = useMutation(
    id ? updateAsset : createAsset,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: t("Credit Card information has been updated"),
          })
        } else {
          notification.success({
            message: t("Credit Card information has been successfully created"),
          })
        }
        router.push("/friends-property/asset-management")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )
  if (creditCardLoading || creditCardFetching) {
    return (
      <div className="loader-wrapper">
        <Spin />
      </div>
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldContainer className="name">
        <LabelContainer>
          <Label>{t("Name on card")}</Label>
        </LabelContainer>
        <TextField
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && formik.errors?.name}
          onBlur={formik.handleBlur}
          placeholder={t("Name on card")}
          height="50px"
          borderradius="10px"
          showCounting
          maxLength={30}
          bordercolor="#DEDBD5"
          disableboxshadow
          className="dynamic-textarea"
          type="dynamic-textarea"
        />
      </TextFieldContainer>

      <TextFieldContainer className="name">
        <LabelContainer>
          <Label>{t("Brand")}</Label>
        </LabelContainer>
        <TextField
          name="brand"
          value={formik.values.brand}
          onChange={formik.handleChange}
          error={formik.touched.brand && formik.errors?.brand}
          onBlur={formik.handleBlur}
          placeholder={t("例：VISA,MASTER,AMEX")}
          height="50px"
          className="dynamic-textarea"
          type="dynamic-textarea"
          borderradius="10px"
          showCounting
          maxLength={15}
          bordercolor="#DEDBD5"
          disableboxshadow
        />
      </TextFieldContainer>

      <TextFieldContainer className="name">
        <LabelContainer>
          <Label>{t("Nominee")}</Label>
        </LabelContainer>
        <TextField
          name="nominee"
          value={formik.values.nominee}
          onChange={formik.handleChange}
          error={formik.touched.name && formik.errors?.nominee}
          onBlur={formik.handleBlur}
          placeholder={t("Nominee")}
          height="50px"
          borderradius="10px"
          showCounting
          maxLength={30}
          bordercolor="#DEDBD5"
          disableboxshadow
          className="dynamic-textarea"
          type="dynamic-textarea"
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Use")}</Label>
        </LabelContainer>
        <TextField
          name="use"
          value={formik.values.use}
          onChange={formik.handleChange}
          error={formik.touched.use && formik.errors?.use}
          onBlur={formik.handleBlur}
          placeholder={t("Use")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={5}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Contact when lost")}</Label>
        </LabelContainer>
        <TextField
          name="contact_on_lost"
          value={formik.values.contact_on_lost}
          onChange={formik.handleChange}
          error={
            formik.touched.contact_on_lost && formik.errors?.contact_on_lost
          }
          onBlur={formik.handleBlur}
          placeholder={t("Contact")}
          height="50px"
          borderradius="10px"
          showCounting
          maxLength={30}
          bordercolor="#DEDBD5"
          disableboxshadow
          className="dynamic-textarea"
          type="dynamic-textarea"
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Memo・Notes")}</Label>
        </LabelContainer>
        <TextField
          name="memo_notes"
          value={formik.values.memo_notes}
          onChange={formik.handleChange}
          error={formik.touched.memo_notes && formik.errors?.memo_notes}
          onBlur={formik.handleBlur}
          placeholder={t("※「カード番号、有効期限は入力しないでください」")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>

      <ButtonWrapper>
        <Button
          htmlType="submit"
          loading={isLoading}
          disabled={isSuccess}
          width="200px"
          height="50px"
          background="#E26E30"
        >
          {id ? t("Update") : t("Add")}
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export { CreditForm }
