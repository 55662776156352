import React, { useEffect } from "react"
import { t } from "i18next"
import Router from "next/router"
import { useFormik } from "formik"
import { useMutation, useQuery } from "react-query"
import { TextField, Button, theme, Select } from "@project/shared"
import { notification, Spin } from "antd"
import { kindsOptions, validationSchema } from "./helper"
import {
  createAsset,
  getAssetById,
  updateAsset,
} from "../../../../services/asset"
import {
  ButtonWrapper,
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../RelativesFriendsForm/helper"

interface Props {
  id?: string | string[]
}

const ElectronicMoneyForm: React.FC<Props> = ({ id }) => {
  const {
    data: electronicMoneyData,
    isLoading: electronicMoneyLoading,
    isFetching: electronicMoneyFetching,
  } = useQuery(["electronicMoney", id], () => id && getAssetById(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: ({ data }) => {
      return {
        id: data?.id,
        kind: data?.electronic_money.kind,
        details: data?.electronic_money.details,
        location: data?.electronic_money.location,
        contact_on_lost: data?.electronic_money.contact_on_lost,
        memo_notes: data?.electronic_money.memo_notes,
      }
    },
  })

  const initialValues = electronicMoneyData || {
    kind: null,
    details: "",
    location: "",
    contact_on_lost: "",
    memo_notes: "",
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      if (id) {
        mutate({ assets_type: "電子マネー", electronic_money: values, id: id })
      } else {
        mutate({ assets_type: "電子マネー", electronic_money: values })
      }
    },
  })

  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])
  const handleKindsChange = (value) => {
    formik.setFieldValue("kind", value)
  }

  const { mutate, isLoading } = useMutation(id ? updateAsset : createAsset, {
    onSuccess: () => {
      if (id) {
        notification.success({
          message: t("Electronic money information has been updated."),
        })
      } else {
        notification.success({
          message: t("Electronic money information has been added."),
        })
      }
      Router.push("/friends-property/asset-management")
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("An error has occurred. Please try again later."),
      })
    },
  })

  if (electronicMoneyLoading || electronicMoneyFetching)
    return (
      <div className="loader-wrapper">
        <Spin />
      </div>
    )

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("kinds")}</Label>
        </LabelContainer>
        <Select
          name="kind"
          type="validate"
          className="select-component"
          value={formik.values.kind || null}
          options={kindsOptions}
          onChange={handleKindsChange}
          placeholder={t("kinds")}
          error={
            formik.touched.kind && formik.errors?.kind
              ? formik.errors?.kind
              : false
          }
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Details")}</Label>
        </LabelContainer>
        <TextField
          name="details"
          placeholder={t("Details")}
          value={formik.values.details}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.details && formik.errors?.details}
          showCounting
          className="dynamic-textarea"
          type="dynamic-textarea"
          maxLength={50}
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Location・Stored")}</Label>
        </LabelContainer>
        <TextField
          name="location"
          placeholder={t("Location・Stored")}
          className="dynamic-textarea"
          type="dynamic-textarea"
          value={formik.values.location}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.location && formik.errors?.location}
          showCounting
          maxLength={50}
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Contact when lost")}</Label>
        </LabelContainer>
        <TextField
          name="contact_on_lost"
          placeholder={t("Contact when lost")}
          className="text-field"
          value={formik.values.contact_on_lost}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.contact_on_lost && formik.errors?.contact_on_lost
          }
          showCounting
          maxLength={12}
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Memo・Notes")}</Label>
        </LabelContainer>
        <TextField
          name="memo_notes"
          placeholder={t("Memo・Notes")}
          type="textarea"
          rows={4}
          className="text-area"
          value={formik.values.memo_notes}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.memo_notes && formik.errors?.memo_notes}
          showCounting
          maxLength={300}
        />
      </TextFieldContainer>
      <ButtonWrapper>
        <Button
          htmlType="submit"
          background={theme.background.navBar.secondary}
          className="save-btn"
          loading={isLoading}
        >
          {id ? t("Update") : t("Add")}
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export { ElectronicMoneyForm }
