import { ReactNode } from "react"
import { LineShareButton } from "react-share"

interface LineProps {
  children?: ReactNode
  url?: string
  title?: string
  className?: any
}

const LineShare = ({ children, url, title, className }: LineProps) => {
  return (
    <LineShareButton title={title} url={url || ""} className={className}>
      {children}
    </LineShareButton>
  )
}

export { LineShare }
