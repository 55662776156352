import React from "react"
import styled from "styled-components"
import { TextField } from "@project/shared"

interface SearchBoxPropsTypes {
  label?: string
  suffix?: React.ReactNode
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
}

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  img {
    margin-right: 5px;
  }
  @media (max-width: 550px) {
    margin-top: 20px;
    .ant-input-affix-wrapper {
      width: 100%;
      height: 40px !important;
    }
  }
`

const Label = styled.div`
  margin-bottom: 5px;
  margin-right: 22px;
  font-size: 16px;
  width: 74px;
  @media (max-width: 550px) {
    font-size: 16px;
  }
`
const SearchBox: React.FC<SearchBoxPropsTypes> = (props) => {
  const { label, suffix, onChange, value } = props

  return (
    <SearchBar>
      <Label>{label}</Label>
      <TextField
        bgcolor="#fff"
        width="350px"
        height="50px"
        disableboxshadow
        borderradius="100px"
        className="search-input"
        suffix={suffix}
        value={value}
        onChange={onChange}
      />
    </SearchBar>
  )
}

export default SearchBox
