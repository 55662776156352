import { Button, TextField, theme } from "@project/shared"
import { Space } from "antd"
import { t } from "i18next"
import { NextPage } from "next"
import React, { useState } from "react"
import styled from "styled-components"

interface OtpProps {
  setCode?: (state: string) => void
  code?: string
  setPageNo?: (state: number) => void
  setUpdatePhoneNumber?: (state: boolean) => void
}

const Container = styled.div`
  background: ${(props) => props.theme.background?.primary};
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  align-items: center;

  @media screen and (max-width: 650px) {
    padding: 1.5rem;
    padding-top: 60px;
    padding-bottom: 60px;
    height: auto;
  }

  @media screen and (max-width: 360px) {
    padding-top: 60px;
    padding-bottom: 60px;
    height: auto;
  }
`

const RegisterBox = styled(Space)`
  justify-content: center;
  width: 80px;
  min-height: 80px;
  background: ${(props) => props.theme.background?.secondary};
  border-radius: 50%;
`
const RegisterTextWrapper = styled.div`
  font-size: ${(props) => props.theme.text?.size.m};
  margin: 16px 0 0 0;
  color: ${(props) => props.theme.text?.color.light};
  font-weight: ${(props) => props.theme.text?.weight.regular};
  font-family: ${(props) => props.theme.font?.BUPS};
`
const TextBox = styled.div`
  margin-top: 57px;
  text-align: center;
  .text {
    font-weight: 400;
    font-size: 16px;
  }
  .text-2 {
    font-weight: 400;
    font-size: 16px;
    margin-top: 25px;
  }
`
const InputFieldWrapper = styled.div`
  margin-top: 50px;
  .ant-input-affix-wrapper {
    padding: 4px 11px;
  }
  .ant-input-prefix {
    margin-right: 8px;
  }
`
const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  .label {
    font-size: 16px;
  }
`
const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-left: 20px;

    @media screen and (max-width: 360px) {
      height: 50px;
    }
  }
  .text-field {
    width: 350px;
  }
  @media screen and (max-width: 650px) {
    .text-field {
      margin: auto;
      width: 60vw;
    }
  }
`
const BottomSection = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ErrorCounterWrapper = styled.div`
  height: 5px;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
`
const ErrorStyled = styled.span`
  font-size: 16px;
  font-family: ${theme.font.NS};
  font-weight: ${theme.text.weight.regular};
  color: ${theme.alert};
  margin-top: 2px;
  margin-left: 2px;
`
const OtpComponent: NextPage<OtpProps> = ({
  code,
  setCode,
  setPageNo,
  setUpdatePhoneNumber,
}) => {
  const [error, setError] = useState("")

  const handleOtpSubmit = () => {
    if (code === "") {
      setError(t("Please enter the Otp number."))
    } else {
      setError("")
      setPageNo(1)
      setUpdatePhoneNumber(false)
    }
  }

  return (
    <>
      <Container>
        <RegisterBox align="center">
          <img
            src="/assets/icons/lock_hoso.svg"
            alt="pen"
            height={44}
            width={44}
          />
        </RegisterBox>
        <RegisterTextWrapper>{t("2 段階認証")}</RegisterTextWrapper>
        <TextBox>
          <div className="text">
            {t(
              "We have sent an verification code to the registered phone number to verify your identity"
            )}
          </div>
          <div className="text-2">
            {t(
              "Please enter the verification code below and press the submit button"
            )}
            s
          </div>
        </TextBox>
        <InputFieldWrapper>
          <LabelWrapper>
            <div className="label">{t("ワンタイムパスワード")}</div>
          </LabelWrapper>
          <TextFieldWrapper>
            <TextField
              bgcolor="#fff"
              value={code}
              onChange={(evt) => setCode(evt.target.value)}
              className="text-field"
              disableboxshadow
              height="60px"
              borderradius="10px"
            />
          </TextFieldWrapper>
          <ErrorCounterWrapper>
            <ErrorStyled>{error}</ErrorStyled>
          </ErrorCounterWrapper>
        </InputFieldWrapper>
        <BottomSection>
          <Button
            width="200px"
            height="50px"
            borderradius="100px"
            background="#E26E30"
            onClick={handleOtpSubmit}
          >
            {t("確認")}
          </Button>
        </BottomSection>
      </Container>
    </>
  )
}

export { OtpComponent }
