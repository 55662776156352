import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0;
  img {
    width: 210px;
    height: 61px;
  }
  @media (max-width: 750px) {
    padding: 20px 0;
  }
`

const Header = () => {
  return (
    <Container>
      <img src="/assets/imgs/footer-logo.svg" alt="logo" />
    </Container>
  )
}

export default Header
