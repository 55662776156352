import { theme } from "@project/shared"
import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-family:${theme.font.BUPS};
    line-height:22px;
  }

  button {
    cursor: pointer;
  }
  body{
    line-height:normal ;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export { GlobalStyles }
