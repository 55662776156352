import {
  BESTFRIEND,
  FRIEND,
  JUNIOR,
  NEIGHBOR,
  OTHERS,
  RELATIVE,
  ROOMMATE,
  SENOIR,
} from "@project/shared/src/utils/constant"
import { t } from "i18next"

/**
 * Relationship type options
 */
export const relationType = [
  { label: t("Relative"), value: RELATIVE },
  { label: t("Best friend"), value: BESTFRIEND },
  { label: t("Friend"), value: FRIEND },
  { label: t("Roommate"), value: ROOMMATE },
  { label: t("Senior"), value: SENOIR },
  { label: t("Junior"), value: JUNIOR },
  { label: t("Neighbor"), value: NEIGHBOR },
  { label: t("Others"), value: OTHERS },
]

/**
 * Approve status options
 */
export const approveStatusOptions = [
  { label: t("Approved"), value: "relation-approved" },
  { label: t("No access"), value: "relation-rejected" },
]
