import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation } from "react-query"
import * as yup from "yup"
import { notification, Spin } from "antd"
import styled from "styled-components"
import { TextField, Button, Select } from "@project/shared"
import { getFamilyById, updateFamily } from "../../../services/family"
import { relationType } from "../../../constants"
import { approveStatusOptions } from "../../../constants/family"
import { useEffect } from "react"

interface Props {
  id?: string | string[]
}

const Container = styled.div`
  padding: 10px 60px 50px 40px;
  background: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  @media (max-width: 600px) {
    padding: 0 20px 30px 20px;
  }

  & .loader-wrapper {
    display: grid;
    place-items: center;
    min-height: 50vh;
  }

  & .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }
`
const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  .text-field {
    width: 360px;
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  input {
    background-color: #f8f5ef;
  }

  .select-component {
    width: 360px;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    background: #f8f5ef;
    border: 1px solid #dedbd5;
    border-radius: 10px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    margin-top: 10px;
    font-size: 16px;

    @media (max-width: 786px) {
      font-size: 16px;
    }
  }
`
const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  max-width: 165px;
  align-items: center;
  min-width: 165px;
`

const Label = styled.div`
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
`

const RequiredLabel = styled.div`
  font-size: 16px;
  margin-right: 5px;
  color: rgba(226, 110, 48, 0.2);
`
const FamilyEditForm: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const { data: familyData, isLoading: companyLoading } = useQuery<any, Error>(
    ["family-edit", id],
    () => getFamilyById(id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) => {
        return {
          id: id,
          name: data?.viewer?.name,
          relation: data?.relation,
          email: data?.viewer?.email,
          phone: data?.viewer?.phone,
          status: data?.status,
        }
      },
    }
  )

  const handleRelationChangeOption = (value) => {
    formik.setFieldValue("relation", value)
  }

  const handleApprovalStatusChange = (value) => {
    formik.setFieldValue("status", value)
  }

  const initialValues = familyData || {
    id: "",
    name: "",
    relation: "",
    email: "",
    phone: "",
    status: "",
  }

  const validationSchema = yup.object().shape({
    name: yup.string(),
    relation: yup.string(),
    email: yup.string().nullable(),
    phone: yup.string(),
    established_date: yup.string(),
    status: yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate(values)
    },
  })

  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])

  const { mutate, isLoading, isSuccess } = useMutation(updateFamily, {
    onSuccess: () => {
      notification.success({
        message: t("Member information updated successfully"),
      })
      router.push("/family")
    },
    onError: (error?: any) => {
      const msg = error?.data?.error?.message
      notification.error({
        message: msg
          ? t(msg)
          : t("An error has occurred. Please try again later."),
      })
    },
  })
  return (
    <section>
      <Container>
        {companyLoading ? (
          <div className="loader-wrapper">
            <Spin />
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <TextFieldContainer className="name">
              <LabelContainer>
                <RequiredLabel>●</RequiredLabel>
                <Label>{t("Name")}</Label>
              </LabelContainer>
              <TextField
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && formik.errors?.name}
                onBlur={formik.handleBlur}
                placeholder={t("Name")}
                className="text-field"
                height="50px"
                borderradius="10px"
                disabled
                bordercolor="#DEDBD5"
                disableboxshadow
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <LabelContainer>
                <RequiredLabel>●</RequiredLabel>
                <Label>{t("Mobile number")}</Label>
              </LabelContainer>
              <TextField
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && formik.errors?.phone}
                onBlur={formik.handleBlur}
                placeholder={t("Mobile number")}
                className="text-field"
                height="50px"
                borderradius="10px"
                bordercolor="#DEDBD5"
                disableboxshadow
                disabled
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <LabelContainer>
                <RequiredLabel>●</RequiredLabel>
                <Label>{t("Email address")}</Label>
              </LabelContainer>
              <TextField
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && formik.errors?.email}
                onBlur={formik.handleBlur}
                placeholder={t("Email address")}
                height="50px"
                width="100%"
                borderradius="10px"
                bordercolor="#DEDBD5"
                disabled
                disableboxshadow
              />
            </TextFieldContainer>

            <TextFieldContainer>
              <LabelContainer>
                <RequiredLabel>●</RequiredLabel>
                <Label>{t("Relation")}</Label>
              </LabelContainer>
              <Select
                name="relation"
                type="validate"
                value={formik.values.relation || null}
                options={relationType}
                onChange={handleRelationChangeOption}
                placeholder={t("Relation")}
                className="select-component"
              />
            </TextFieldContainer>

            <TextFieldContainer>
              <LabelContainer>
                <RequiredLabel>●</RequiredLabel>
                <Label>{t("Approval status")}</Label>
              </LabelContainer>
              <Select
                name="status"
                type="validate"
                className="select-component"
                value={t(formik.values.status)}
                options={approveStatusOptions}
                onChange={handleApprovalStatusChange}
                placeholder={t("Approval status")}
              />
            </TextFieldContainer>

            <div className="button-wrapper">
              <Button
                htmlType="submit"
                loading={isLoading}
                disabled={isSuccess}
                width="200px"
                height="50px"
                background="#E26E30"
              >
                {t("Update")}
              </Button>
            </div>
          </form>
        )}
      </Container>
    </section>
  )
}

export { FamilyEditForm }
