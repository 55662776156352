import styled from "styled-components"

export const LockWrapper = styled.span`
  .home__icon path {
    stroke: red;
    transform: scale(1.5);
  }

  .home__icon_unlock path {
    transform: scale(1.5);
  }
`
