import { theme } from "@project/shared"
import { Dropdown } from "antd"
import React from "react"
import styled from "styled-components"

interface HeaderButtonProps {
  icon: React.ReactNode
  heading: string
  items?: React.ReactElement
  disable?: boolean
  isActive?: boolean
  onClick?: () => void
  badge?: number
}
interface StyledProps {
  isActive?: boolean
}

const DropDownWrapper = styled.div``
const IconButtonBox = styled.div`
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
  border-radius: 10px 10px 0px 0px;
  height: 90px;
  width: 140px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1024px) and (min-width: 768px) {
    width: 114px;
  }
  @media (max-width: 799px) and (min-width: 768px) {
    width: 114px;
  }
  &:hover {
    background: white;
  }
  background: ${(props: StyledProps) =>
    props.isActive ? "white" : "rgba(255, 255, 255, 0.4)"};
`

const TextBox = styled.div`
  margin-top: 5px;
  font-family: ${theme.font.BUS};
  font-weight: ${theme.text.weight.medium};
  font-size: 16px;
  @media (max-width: 1024px) and (min-width: 800px) {
    font-size: 16px;
  }
`

const overlayStyles = {
  width: "350px",
  boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.07)",
  borderRadius: "0px 0px 10px 10px !important",
  fontFamily: theme.font.BUS,
  fontWeight: theme.text.weight.regular,
  fontSize: "18px",
  color: theme.text.color.gray,
}
const TagWrapper = styled.span`
  border-radius: 11px;
  color: white;
  border: none;
  font-size: 16px !important;
  padding: 3px 15px;
  background: ${theme.button.primary};
  position: absolute;
  right: 5px;
  top: 5px;
`

const HeaderButton: React.FC<HeaderButtonProps> = (props) => {
  const {
    icon,
    heading,
    items,
    isActive = false,
    disable = false,
    badge,
  } = props

  return (
    <DropDownWrapper>
      <Dropdown
        overlay={items}
        disabled={disable}
        overlayStyle={overlayStyles}
        placement="bottomCenter"
        arrow
      >
        <IconButtonBox isActive={isActive}>
          {badge && badge > 0 ? (
            <TagWrapper>
              <span>{badge}件</span>
            </TagWrapper>
          ) : null}
          <span>{icon}</span>
          <TextBox>{heading}</TextBox>
        </IconButtonBox>
      </Dropdown>
    </DropDownWrapper>
  )
}
export default HeaderButton
