import React, { useEffect, useRef, useState } from "react"
import { t } from "i18next"
import Link from "next/link"
import styled from "styled-components"
import { CheckCircleOutlined, RightOutlined } from "@ant-design/icons"

const Container = styled.div`
  max-width: 1000px;
  margin: 80px auto 120px auto;
  @media (max-width: 1020px) {
    max-width: 90%;
  }
  @media (max-width: 786px) {
    margin: 52px auto 100px auto;
  }
  .ex-link {
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
    @media (max-width: 786px) {
      font-size: 18px;
    }
  }
`
const Title = styled.div`
  text-align: center;
  font-weight: 500;
  margin-bottom: 63px;
  border-bottom: 1px dashed #313131;
  line-height: 0em;
  span {
    background-color: #fff;
    padding: 0 38px;
    font-size: 20px;
    line-height: 0px;
  }
  @media (max-width: 786px) {
    margin-bottom: 35px;
    font-size: 20px;
    span {
      padding: 0 10px;
    }
  }
`
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .anticon svg {
    font-size: 20px;
  }

  .block {
    /* background-image: url("./assets/imgs/image1.jpg"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border: 1px solid #dddcdc;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    height: 220px;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
    .block-items {
      display: flex;
      align-items: center;
    }
    .sub-block {
      display: flex;
      justify-content: space-between;
      width: 100% !important;
      background-color: rgba(0, 0, 0, 0.4);
      height: 75px;
      align-items: center;
      padding: 20px;
      color: white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      > div {
        display: flex;

        > a {
          margin-left: 10px;
          text-decoration: none;
          white-space: break-spaces;
          text-align: center;
          :hover {
            color: white;
          }
        }
      }
    }
  }
  @media (max-width: 1021px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 786px) {
    grid-template-columns: 1fr;
    .block {
      text-align: left;
    }
  }
`

const getExternalLinkNode = (text: string, width: number) => {
  switch (text) {
    case "お葬式のご相談はこちら":
      if (width < 300 && width > 282) {
        return (
          <span>
            お葬式のオンライン
            <br />
            相談
          </span>
        )
      }
      return <span>{text}</span>

    case "80歳でも入れる死亡保険・少額短期保険":
      if (width < 390 && width > 287) {
        return (
          <span>
            80歳でも入れる死亡保険・
            <br />
            少額短期保険
          </span>
        )
      }
      return <span>{text}</span>

    default:
      return <span>{text}</span>
  }
}

const externalLinks = [
  {
    name: `お葬式のご相談はこちら`,
    image: "image1.jpg",
    link: "https://www.moshimo.net/inquiry/?utm_source=kakioki&utm_medium=social&utm_campaign=kakioki_link",
  },
  {
    name: "介護施設探しは「みつけるコンシェル",
    image: "image2_updated.png",
    link: "https://mitsukeru-kaigo.net/?utm_source=kakioki&utm_medium=social&utm_campaign=kakioki_link",
  },
  {
    name: "東京・神奈川でおすすめの霊園",
    image: "image3.jpg",
    link: "https://www.moshimo.net/after/grave/?utm_source=kakioki&utm_medium=social&utm_campaign=kakioki_link",
  },
  {
    name: "80歳でも入れる死亡保険・少額短期保険",
    image: "image4.jpg",
    link: "https://www.sunlife-family.com/",
  },
  {
    name: "「互助会」のことが良くわかるサイト",
    image: "image5.jpg",
    link: "https://www.sunlife.jp/members/?utm_source=kakioki&utm_medium=social&utm_campaign=kakioki_link",
  },
  {
    name: "相続・名義変更の無料相談",
    image: "image6.jpg",
    link: "/preparing",
  },
  {
    name: "エンバーミングで最良のお別れ",
    image: "image7.jpg",
    link: "https://embalming-sec.com/?utm_source=kakioki&utm_medium=social&utm_campaign=kakioki_link",
  },
  {
    name: "家族葬について詳しく知る",
    image: "image12.png",
    link: "https://www.moshimo.net/plan/family/?utm_source=kakioki&utm_medium=social&utm_campaign=kakioki_link",
  },
]
const ExternalMenus = () => {
  const widthRef = useRef(null)
  const [itemWidth, setItemWidth] = useState(widthRef?.current?.offsetWidth)
  window.onresize = () => {
    setItemWidth(widthRef?.current?.offsetWidth)
  }

  useEffect(() => {
    setItemWidth(widthRef?.current?.offsetWidth)
  }, [widthRef.current])
  return (
    <Container>
      <Title>
        <span>{t("お役立ちサイトのご案内")}</span>
      </Title>
      <Wrapper>
        {externalLinks.map((link, index) => (
          <Link href={link.link ? link.link : "/"} key={index}>
            <a target="_blank" rel="noopener noreferrer">
              <div
                className="block"
                style={{
                  backgroundImage: `url(/assets/imgs/${link.image})`,
                }}
              >
                <div className="sub-block" ref={widthRef}>
                  <div className="block-items">
                    <CheckCircleOutlined />
                    <div className="ex-link">
                      {getExternalLinkNode(link.name, itemWidth)}
                    </div>
                  </div>
                  <RightOutlined />
                </div>
              </div>
            </a>
          </Link>
        ))}
      </Wrapper>
    </Container>
  )
}
export { ExternalMenus }
