import {
  APARTMENT,
  BUILDING,
  CREDITCARD,
  CRYPTOCURRENCY,
  DEBT,
  ELECTRONICMONEY,
  GURANTEE_OBLIGATION,
  LAND,
  LOAN,
  MANSION,
  NEGATIVEASSETS,
  OTHERS,
  PARKING,
  REALSTATE,
  SAVING,
  STOCKANDBOND,
} from "@project/shared/src/utils/constant"
import { t } from "i18next"

/**
 * ASSETS TYPES OPTIONS
 */
export const assetsType = [
  { label: t("Real estate"), value: REALSTATE },
  { label: t("Savings"), value: SAVING },
  { label: t("Cryptocurrency"), value: CRYPTOCURRENCY },
  { label: t("Stocks & Bonds"), value: STOCKANDBOND },
  { label: t("Negative assets (debt / loans)"), value: NEGATIVEASSETS },
  { label: t("Credit cards"), value: CREDITCARD },
  { label: t("Electronic money"), value: ELECTRONICMONEY },
  { label: t("Others"), value: OTHERS },
]

/**
 * Realstate kinds options
 */
export const realstateKind = [
  { label: t("Land"), value: LAND },
  { label: t("Building"), value: BUILDING },
  { label: t("Mansion"), value: MANSION },
  { label: t("Apartment"), value: APARTMENT },
  { label: t("Parking"), value: PARKING },
  { label: t("Others"), value: OTHERS },
]

export const negativeAssetsKinds = [
  { label: t("Debt"), value: DEBT },
  { label: t("Loan"), value: LOAN },
  { label: t("Guarantee obligation"), value: GURANTEE_OBLIGATION },
  { label: t("Others"), value: OTHERS },
]
