import { t } from "i18next"
import styled from "styled-components"
import * as yup from "yup"

export const validationSchema = yup.object().shape({
  insurance_type: yup.string().required(t("Please select")).nullable(),
  company_name: yup.string(),
  contractor: yup.string(),
  receiver: yup.string(),
  contents: yup.string(),
  contact_pic: yup.string(),
  memo_notes: yup.string(),
})

export const Container = styled.div`
  border: 1px solid #dddcdc;
  border-radius: 10px;
  padding: 40px 50px;

  @media (max-width: 786px) {
    margin-top: 30px;
    border: none;
    padding: 0px;
  }
`

export const InputFieldWrapper = styled.div`
  margin-bottom: 35px;

  .dynamic-textarea {
    width: 360px !important;

    @media (max-width: 786px) {
      width: 100% !important;
    }
  }

  textarea {
    background: #f8f5ef;
  }
  .ant-input-affix-wrapper {
    padding: 4px 24px;
  }
  .ant-input-prefix {
    margin-right: 8px;
  }
  input {
    background-color: #f8f5ef;
  }

  .text-field {
    max-width: 360px;

    @media (max-width: 480px) {
      max-width: none;
    }
  }

  .select-field {
    max-width: 360px;
    background: #f8f5ef;

    @media (max-width: 480px) {
      max-width: none;
    }

    @media (max-width: 340px) {
      min-width: 0px;
    }
  }
`

export const TextFieldWrapper = styled.div`
  margin-top: 20px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 480px) {
    padding-bottom: 20px;
  }
`
