import styled from "styled-components"
import { theme } from "@project/shared"

export const Wrapper = styled.div`
  width: 100%;
  padding: 18px 20px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const ImageWrapper = styled.div`
  background-color: #e3e0d9;
  width: 100%;
  height: 166px;
  img {
    object-fit: contain;
    background-color: #e3e0d9;
  }
  .thumbnail {
    position: relative;
    .play {
      position: absolute;
      background-color: transparent;
      top: calc(50% - 25px);
      right: calc(50% - 22px);
      cursor: pointer;
      &:hover {
        transform: scale(1.5);
      }
    }
  }
`
export const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  word-break: break-all !important;

  .blank {
    color: ${theme.gray7};
  }
`

export const BottomComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 19px;
  .date {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #8d8686;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 11px;
    margin-right: 5px;
  }
`

export const Text = styled.p``
