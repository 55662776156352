import moment from "moment"
import { t } from "i18next"
import { Image, Modal, notification, Skeleton, Spin } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { DownloadOutlined } from "@ant-design/icons"
import ReactHlsPlayer from "react-hls-player"

import {
  Text,
  Wrapper,
  ImageWrapper,
  BottomComponent,
  TextFieldContainer,
} from "./helper"

import { downloadFileCRP, getSignedObjectUrl } from "@project/shared"
import styled from "styled-components"
import { useMutation } from "react-query"
import { videoFetch } from "../../../services/video-file"
interface iCard {
  data: any
  setIsModalVisible?: any
  setId?: any
  fileType?: string
}

const CustomModal = styled(Modal)`
  .ant-btn-primary {
    border-radius: 100px;
    padding: 0px 20px;
    background: rgb(226, 110, 48);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(226, 110, 48);
    width: 92px;
  }
  .ant-btn-default {
    border-radius: 100px;
    padding: 0px 20px;
    background: rgb(196, 196, 196);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(196, 196, 196);
    width: auto;
  }
`
const ButtonWrapper = styled.div`
  min-width: 40px;
  max-height: 40px;
  padding: 11px 0px 11px 0px;
  margin-left: 14px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .icon {
    color: #e26e30;
  }
`

const ViewerPictureCard: React.FC<iCard> = ({ data, fileType }) => {
  const playerRef = useRef<HTMLVideoElement>(null)
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false)
  const [isFileDownloading, setIsFileDownloading] = useState(false)
  const [thumbnail, setThumbnail] = useState<string>()
  const [loadImage, setLoadImage] = useState(false)

  const [url, setUrl] = useState<string | null>(null)

  const { mutate: getSignedVideoUrl, data: signedVideoURL } = useMutation(
    videoFetch,
    {
      onSuccess: () => {
        showModal()
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )

  const handleFileDownload = async (url, filename) => {
    try {
      setIsFileDownloading(true)
      let mimeType = ""
      const filenameArray = filename.split(".")
      const extension = filenameArray[filenameArray.length - 1]
      if (fileType === "image") {
        mimeType = "image/" + extension
      } else {
        mimeType = "video/" + extension
      }
      await downloadFileCRP({ url, filename, fileType: mimeType })
      setIsFileDownloading(false)
    } catch (error) {
      setIsFileDownloading(false)
      notification.error({
        message: t("An error has occurred. Please try again later."),
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      setLoadImage(true)
      if (data?.image?.url) {
        const imageUrl = await getSignedObjectUrl(data?.image?.url)
        setUrl(imageUrl)
      }
      setLoadImage(false)
    })()
  }, [data?.image])
  useEffect(() => {
    ;(async () => {
      setLoadImage(true)
      if (data?.video?.thumbnail) {
        const imageUrl = await getSignedObjectUrl(data?.video?.thumbnail)
        setThumbnail(imageUrl)
      }
      setLoadImage(false)
    })()
  }, [data?.video?.thumbnail])
  if (loadImage) {
    return <Skeleton />
  }
  const showModal = () => {
    setIsVideoModalVisible(true)
  }

  const handleOk = () => {
    playVideo()
  }

  const handleCancel = () => {
    setIsVideoModalVisible(false)
    pauseVideo()
  }
  function pauseVideo() {
    playerRef && playerRef?.current?.pause()
  }
  function playVideo() {
    playerRef && playerRef?.current?.play()
  }
  const renderImage = () => {
    return (
      <>
        <CustomModal
          visible={isVideoModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          okText={t("Play")}
          cancelText={t("Cancel")}
        >
          <ReactHlsPlayer
            playerRef={playerRef}
            src={signedVideoURL?.data?.signed_url}
            autoPlay={false}
            controls={true}
            width="100%"
            height="auto"
          />
        </CustomModal>
        <ImageWrapper>
          {data?.video && (
            <div className="thumbnail">
              <img
                width="100%"
                height={166}
                src={
                  thumbnail ||
                  "https://images.freeimages.com/images/large-previews/e4e/circulate-abstract-1562332.jpg"
                }
              />
              <img
                src="/assets/icons/play.png"
                className="play"
                onClick={() => {
                  getSignedVideoUrl(data?.video?.url)
                }}
              />
            </div>
          )}
          {data?.image && <Image src={url} width="100%" height={166} />}
        </ImageWrapper>
      </>
    )
  }

  const getTextData = () => {
    return (
      <>
        <TextFieldContainer className="name">
          {data?.title ? (
            <Text>{data?.title}</Text>
          ) : (
            <Text className="blank">{t("No Title")}</Text>
          )}
        </TextFieldContainer>

        <TextFieldContainer>
          {data?.caption ? (
            <Text>{data?.caption}</Text>
          ) : (
            <Text className="blank">{t("No Caption")}</Text>
          )}
        </TextFieldContainer>
      </>
    )
  }

  const getDownloadButton = () => {
    return (
      <>
        <ButtonWrapper
          onClick={() => {
            handleFileDownload(
              data?.image?.url || data?.video?.url,
              data?.video?.name || data?.image?.name
            )
          }}
        >
          {isFileDownloading ? (
            <Spin size="small" />
          ) : (
            <DownloadOutlined className="icon" />
          )}
        </ButtonWrapper>
      </>
    )
  }

  const getBottomPart = () => {
    return (
      <BottomComponent>
        <div className="date">
          アップロード: {moment(data?.created_at).format("YYYY/MM/DD")}
        </div>

        {getDownloadButton()}
      </BottomComponent>
    )
  }

  if (loadImage) {
    return <Skeleton />
  }

  return (
    <Wrapper>
      <div>
        {renderImage()}

        {getTextData()}
      </div>

      {getBottomPart()}
    </Wrapper>
  )
}

export { ViewerPictureCard }
