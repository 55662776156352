import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation } from "react-query"
import * as yup from "yup"
import { notification, Spin } from "antd"
import { TextField, Button, Select } from "@project/shared"
import {
  createAsset,
  getAssetById,
  updateAsset,
} from "../../../../services/asset"
import { realstateKind } from "../../../../constants/assets"
import { REALSTATE } from "@project/shared/src/utils/constant"
import {
  ButtonWrapper,
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../RelativesFriendsForm/helper"
import { useEffect } from "react"

interface Props {
  id?: string | string[]
}

const RealStateForm: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const {
    data: realstateData,
    isLoading: realStateLoading,
    isFetching: realStateFetching,
  } = useQuery<any, Error>(["realstate", id], () => id && getAssetById(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: ({ data }) => {
      return {
        kind: data?.real_state?.kind,
        nominee: data?.real_state?.nominee,
        location: data?.real_state?.location,
        memo_notes: data?.real_state?.memo_notes,
      }
    },
  })

  const handleKindsChangeOption = (value) => {
    formik.setFieldValue("kind", value)
  }

  const initialValues = realstateData || {
    kind: null,
    nominee: "",
    location: "",
    memo_notes: "",
  }

  const validationSchema = yup.object().shape({
    kind: yup.string().nullable().required(t("Required")),
    nominee: yup.string(),
    location: yup.string(),
    memo_notes: yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id) {
        mutate({ assets_type: REALSTATE, real_state: values, id: id })
      } else {
        mutate({ assets_type: REALSTATE, real_state: values })
      }
    },
  })

  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])

  const { mutate, isLoading, isSuccess } = useMutation(
    id ? updateAsset : createAsset,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: t("Real estate information has been updated"),
          })
        } else {
          notification.success({
            message: t("Real estate information has been successfully created"),
          })
        }
        router.push("/friends-property/asset-management")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )
  if (realStateFetching || realStateLoading)
    return (
      <div className="loader-wrapper">
        <Spin />
      </div>
    )

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Kinds")}</Label>
        </LabelContainer>
        <Select
          name="kind"
          type="validate"
          value={formik.values.kind || null}
          options={realstateKind}
          onChange={handleKindsChangeOption}
          placeholder={t("Kinds")}
          className="select-component"
          error={
            formik.touched.kind && formik.errors?.kind
              ? formik.errors?.kind
              : false
          }
        />
      </TextFieldContainer>

      <TextFieldContainer className="name">
        <LabelContainer>
          <Label>{t("Nominee")}</Label>
        </LabelContainer>
        <TextField
          name="nominee"
          value={formik.values.nominee}
          onChange={formik.handleChange}
          error={formik.touched.name && formik.errors?.nominee}
          onBlur={formik.handleBlur}
          placeholder={t("Nominee")}
          height="50px"
          borderradius="10px"
          showCounting
          maxLength={30}
          bordercolor="#DEDBD5"
          disableboxshadow
          className="dynamic-textarea"
          type="dynamic-textarea"
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Location")}</Label>
        </LabelContainer>
        <TextField
          name="location"
          value={formik.values.location}
          onChange={formik.handleChange}
          error={formik.touched.location && formik.errors?.location}
          onBlur={formik.handleBlur}
          placeholder={t("Location")}
          height="50px"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={50}
          showCounting
          disableboxshadow
          className="dynamic-textarea"
          type="dynamic-textarea"
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Memo・Notes")}</Label>
        </LabelContainer>
        <TextField
          name="memo_notes"
          value={formik.values.memo_notes}
          onChange={formik.handleChange}
          error={formik.touched.memo_notes && formik.errors?.memo_notes}
          onBlur={formik.handleBlur}
          placeholder={t("Memo・Notes")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>

      <ButtonWrapper>
        <Button
          htmlType="submit"
          loading={isLoading}
          disabled={isSuccess}
          width="200px"
          height="50px"
          background="#E26E30"
        >
          {id ? t("Update") : t("Add")}
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export { RealStateForm }
