import styled from "styled-components"
import { theme } from "@project/shared"

export const Container = styled.div.attrs((props: { type: string }) => props)`
  width: 100%;
  margin: ${(props) =>
    props.type === "top" ? "15px 0px 0px 0px" : "50px auto"};
  padding: ${(props) => (props.type === "top" ? "20px 20px" : "0 20px")};
  background: ${(props) => props.type === "top" && "none"};
  .ant-collapse-item {
    .ant-collapse-header {
      border-bottom: 1px solid #dddcdc;
      padding: 20px 12px;
    }
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }

  .ant-menu-submenu-inline {
    border-top: ${(props) =>
      props.type !== "top" && "1px solid #dddcdc !important"};
    padding: ${(props) => props.type === "top" && "3px"};
    background-color: ${(props) => props.type === "top" && "none"};
    :last-child {
      border-bottom: ${(props) =>
        props.type !== "top" && "1px solid #dddcdc !important"};
    }
  }

  .ant-menu-item {
    padding-left: 1.563rem !important;
    font-family: ${theme.font.BUS};
    height: 3.75rem !important;
    border-top: 1px solid #dddcdc !important;
  }

  .ant-menu-submenu-title {
    height: 70px !important;
    background-color: ${(props) => props.type === "top" && "#fef2ea"};
    border-radius: 6px;
    border: 1px solid #faccb4;
    &:hover {
      color: #000000;
    }
  }

  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }

  .ant-menu-title-content {
    font-family: ${theme.font.BUS};
    margin-left: 15px !important;
    font-weight: 500;
    @media (max-width: 380px) {
      font-size: 18px !important;
    }
    @media (min-width: 380px) and (max-width: 750px) {
      font-size: 18px !important;
    }
  }

  @media (min-width: 786px) {
    display: none;
  }
`

export const Title = styled.div`
  background: ${theme.background.primary};
  border: 1px solid #dddcdc;
  padding: 15px 0;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
`
export const Img = styled.div`
  text-align: center;
  img {
    height: 60px;
    text-align: center;
  }
`
