import { useTranslation } from "react-i18next"
import { Select } from "antd"
import { Button } from "@project/shared"
import { assetsType } from "../../../../constants"
import { useState } from "react"
import {
  ButtonWrapper,
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../RelativesFriendsForm/helper"

interface iAssetSelectionForm {
  setAssetType?: React.Dispatch<React.SetStateAction<string>> | any
  value?: string
}

const AssetSelectionForm: React.FC<iAssetSelectionForm> = ({
  setAssetType,
  value,
}) => {
  const { t } = useTranslation()
  const [asset, setAsset] = useState(value)

  const handleChangeOption = (value) => {
    setAsset(value)
  }

  return (
    <>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Type")}</Label>
        </LabelContainer>
        <Select
          value={asset || null}
          options={assetsType}
          onChange={handleChangeOption}
          placeholder={t("Type")}
          className="select-component"
        />
      </TextFieldContainer>
      <ButtonWrapper>
        <Button
          htmlType="button"
          width="200px"
          height="50px"
          background="#E26E30"
          onClick={() => setAssetType(asset)}
        >
          {t("Next")}
        </Button>
      </ButtonWrapper>
    </>
  )
}

export { AssetSelectionForm }
