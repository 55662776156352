import React from "react"
import Link from "next/link"
import styled from "styled-components"
import { CheckCircleOutlined, RightOutlined } from "@ant-design/icons"

interface IFormProps {
  menuItems?: any
}

interface StyledProps {
  length?: number
}
const Container = styled.div`
  max-width: 1000px;
  margin: 49px auto 0 auto;
  @media (max-width: 1020px) {
    max-width: 90%;
  }
  @media (max-width: 786px) {
    margin: 49px auto 0 auto;
  }
  .ex-link {
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
  }
`

const Wrapper = styled.div`
  display: ${(props: StyledProps) => (props?.length <= 1 ? "flex" : "grid")};
  justify-content: center;
  grid-template-columns: ${(props: StyledProps) =>
    props?.length <= 2 ? "repeat(2, 1fr)" : "repeat(3, 1fr)"};
  margin: 0 auto;
  .block {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border: 1px solid #dddcdc;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    height: 220px;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
    .block-items {
      display: flex;
      align-items: center;
    }
    .sub-block {
      display: flex;
      justify-content: space-between;
      width: 100% !important;
      background-color: rgba(0, 0, 0, 0.4);
      align-items: center;
      padding: 20px;
      color: white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      > div {
        display: flex;

        > a {
          margin-left: 10px;
          text-decoration: none;
          white-space: break-spaces;
          text-align: center;
          :hover {
            color: white;
          }
        }
      }
    }
  }
  @media (max-width: 970px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 786px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    .block {
      text-align: left;
      /* padding: 18px 20px; */
    }
  }
`
const FormExternalMenus: React.FC<IFormProps> = ({ menuItems = [] }) => {
  return (
    <Container>
      <Wrapper length={menuItems?.length}>
        {menuItems.map((items, index) => (
          <Link href={items?.path} key={index}>
            <a target="_blank" rel="noopener noreferrer">
              <div
                className="block"
                style={{
                  backgroundImage: `url(/assets/imgs/${items?.image})`,
                }}
              >
                <div className="sub-block">
                  <div className="block-items">
                    <CheckCircleOutlined />
                    <div className="ex-link">{items?.title}</div>
                  </div>
                  <RightOutlined />
                </div>
              </div>
            </a>
          </Link>
        ))}
      </Wrapper>
    </Container>
  )
}
export default FormExternalMenus
