import * as yup from "yup"
import { t } from "i18next"

export const othersOptions = [
  {
    target: "others",
    label: "自動車",
    value: "自動車",
  },
  {
    target: "others",
    label: "美術品",
    value: "美術品",
  },
  {
    target: "others",
    label: "貴金属",
    value: "貴金属",
  },
  {
    target: "others",
    label: "ブランド品 ",
    value: "ブランド品 ",
  },
  {
    target: "others",
    label: "ゴルフ会員権",
    value: "ゴルフ会員権",
  },
  {
    target: "others",
    label: "その他",
    value: "その他",
  },
]

export const validationSchema = yup.object().shape({
  kind: yup.string().nullable().required(t("Required")),
  details: yup.string(),
  location: yup.string(),
  memo_notes: yup.string(),
})
