import * as yup from "yup"
import { t } from "i18next"
import styled from "styled-components"
import { theme } from "@project/shared"

export const kindsOptions = [
  {
    target: "kinds",
    label: "電子マネー",
    value: "電子マネー",
  },
  {
    target: "kinds",
    label: "ポイントカード",
    value: "ポイントカード",
  },
]

export const validationSchema = yup.object().shape({
  kind: yup.string().nullable().required(t("Required")),
  details: yup.string(),
  location: yup.string(),
  contact_on_lost: yup
    .string()
    .matches(/^\d*$/, t("Contact can only be number")),
  memo_notes: yup.string(),
})

export const TextFieldContainer = styled.div`
  margin-top: 30px;

  .text-field {
    width: 360px;
  }

  .dynamic-textarea {
    width: 360px !important;

    @media (max-width: 786px) {
      width: 100% !important;
    }
  }

  input {
    background-color: ${theme.background.primary};
    border: 1px solid #dedbd5;
    border-radius: 10px;
    box-shadow: none;
  }

  .email_address {
    width: 100%;
  }

  .select-component {
    width: 360px;

    @media (max-width: 786px) {
      width: 100%;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    background: #f8f5ef;
    border: 1px solid #dedbd5;
    border-radius: 10px;

    @media (max-width: 786px) {
      height: 50px;
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    margin-top: 10px;
    font-size: 16px;

    @media (max-width: 786px) {
      font-size: 16px;
    }
  }

  .text-area {
    textarea {
      background-color: ${theme.background.primary};
      border: 1px solid #dedbd5;
      border-radius: 10px;
      box-shadow: none;
    }
  }

  @media (max-width: 786px) {
    .text-field {
      height: 65px;
      width: 100%;
    }
  }
`

export const LabelContainer = styled.div`
  margin-bottom: 20px;
  @media (max-width: 786px) {
    margin-bottom: 14px;
  }
`

export const Label = styled.div`
  font-weight: 500;
  font-size: 16px;

  ::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba(226, 110, 48, 0.2);
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  @media (max-width: 786px) {
    font-size: 16px;

    ::before {
      width: 12px;
      height: 12px;
    }
  }
`

export const ErrorStyled = styled.div`
  font-size: 16px;
  font-family: ${theme.font.NS};
  font-weight: ${theme.text.weight.regular};
  color: ${theme.alert};
  margin-top: 2px;
  margin-left: 2px;
  display: block;
`

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 35px;

  .confirm-btn {
    width: 200px;
    height: 50px;
    font-weight: 700;
    font-size: 18px;

    @media (max-width: 786px) {
      width: 240px;
      height: 60px;
    }
  }
`
