import { t } from "i18next"
import styled from "styled-components"
import React, { useRef, useState } from "react"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import PageHeaderIcon from "../../PageHeaderIcon"
import { Image } from "antd"
// import LockIcon from "../../../../public/assets/icons/menu_icon_lock.svg"
// import UnlockIcon from "../../../../public/assets/icons/unlock_icon.svg"

interface iProps {
  isNoDesktopItems?: boolean
}

// const { Panel } = Collapse
// const Icons = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-around;
//   margin-bottom: 30px;
//   color: #797979;
//   .anticon svg {
//     font-size: 32px;
//     color: #4696c4;
//   }
//   & .icon {
//     flex-wrap: wrap;
//     text-align: center;
//     svg {
//       transform: scale(1.5);
//       margin-bottom: 5px;
//     }
//   }
// `

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  @media (max-width: 600px) {
    text-align: center;
  }
  @media (max-width: 374px) {
    font-size: 18px;
  }
`

export const Content = styled.div`
  margin-top: 30px;
  width: 100%;

  .second-section {
    margin-top: 56px;
  }

  .ant-collapse {
    .ant-collapse-item {
      margin-bottom: 22px;
      background-color: #ffffff;
      border-radius: 10px;

      ul {
        margin-left: 20px;
        margin-top: 25px;
        li {
          margin-bottom: 20px;
        }
        a {
          margin-bottom: 25px;
        }
      }

      .ant-collapse-header {
        border-radius: 10px;
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        background-color: rgba(249, 249, 249, 0.45);
        border: 1px solid rgba(209, 209, 209, 0.6);
        padding: 12px 12px 12px 9px;
        .ant-collapse-arrow {
          @media (max-width: 410px) {
            right: 0px;
          }
        }
      }
    }

    .ant-collapse-item-active {
      border: 1px solid rgba(226, 110, 48, 0.7);
      .ant-collapse-header {
        background-color: #f8f5ef;
        border-radius: 10px 10px 0px 0px;
      }
    }
  }

  @media (min-width: 786px) {
    margin-top: 0;
    margin-left: 50px;
    .second-section {
      margin-top: 66px;
    }
  }
  @media (min-width: 950px) {
    margin-left: 100px;
  }
`
export const StyledPageHeader = styled(PageHeaderIcon)`
  @media (max-width: 570px) {
    font-size: 18px !important;
  }
`

const SeeMoreButton = styled.div`
  background: #f8f5ef;
  height: 88px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  position: relative;
  cursor: pointer;
  .icon-wrapper {
    min-width: 30px;
    min-height: 30px;
    background: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
  }
  .see-more {
    font-size: 22px;
  }
`
const Grid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`

const ThumbnailImg = styled(Image)`
  height: auto;
  width: 100%;
  border-radius: 10px;
  object-fit: contain;
  filter: brightness(85%);
`
const ThumbnailCard = styled.a`
  text-decoration: "none";
  color: "#000000";
  text-align: center;
  word-break: break-all;
`
const ThumbnailWrapper = styled.div`
  position: relative;
  cursor: pointer;

  .ant-image-placeholder {
    position: static;
    margin-top: -24px;
    img {
      width: 100%;
    }
  }
  .ant-image {
    width: 100%;
  }
  .play-icon {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
`
const TextOnlyContainer = styled.div`
  border-radius: 10px;
  background: #f6f6f6;
  display: flex;
  padding: 16px 8px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
`
const TitleWrapper = styled.div`
  font-family: "BIZ UDGothic", sans-serif;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #313131;
  line-height: 20px;
`
const DescriptionWrapper = styled.ul`
  font-family: "BIZ UDGothic", sans-serif;
  width: 100%;
  color: rgba(49, 49, 49, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0px 8px 0px 20px;
`

const HowToUseComponent: React.FC<iProps> = () => {
  const myDivRef = useRef(null)
  // const [expanded, setExpanded] = useState(false)
  const [expandedVideoItems, setExpandedVideoItems] = useState(false)

  const howToUserData = [
    {
      id: 1,
      image: "note-edit.svg",
      title: "新規登録",
      thumbnail: "https://i.ytimg.com/vi/cQhNr79UBtk/mqdefault.jpg",
      videoSrc: "https://youtu.be/cQhNr79UBtk",
    },
    {
      id: 2,
      image: "note-edit.svg",
      title: "Kaki-Okiって何ができるの？",
      thumbnail: "https://i.ytimg.com/vi/2gMFZ8gcqp0/mqdefault.jpg",
      videoSrc: "https://youtu.be/2gMFZ8gcqp0",
    },
    {
      id: 3,
      image: "user.svg",
      title: "ノートを書く",
      thumbnail: "https://i.ytimg.com/vi/B7hstjrXCBM/mqdefault.jpg",
      videoSrc: "https://youtu.be/B7hstjrXCBM",
    },
    {
      id: 4,
      image: "mail_user.svg",
      title: "家族にシェア",
      thumbnail: "https://i.ytimg.com/vi/6MCq5OhHFpI/mqdefault.jpg",
      videoSrc: "https://youtu.be/6MCq5OhHFpI",
    },
    {
      id: 5,
      image: "note-edit.svg",
      title: "Kaki-Okiをホーム画面に追加するには",
      thumbnail: "https://i.ytimg.com/vi/FNTwruHhIL8/mqdefault.jpg",
      videoSrc: "https://youtu.be/FNTwruHhIL8",
    },
    {
      id: 6,
      image: "note-edit.svg",
      title: "活用方法",
      thumbnail: "https://i.ytimg.com/vi/riR3a-w-Lgk/mqdefault.jpg",
      videoSrc: "https://youtu.be/riR3a-w-Lgk",
    },
    {
      id: 7,
      image: "note-edit.svg",
      title: "進捗レポート",
      thumbnail: "https://i.ytimg.com/vi/cQhdVVB2uug/mqdefault.jpg",
      videoSrc: "https://youtu.be/cQhdVVB2uug",
    },
    {
      id: 8,
      image: "note-edit.svg",
      title: "カギ付き情報の開示請求",
      thumbnail: "https://i.ytimg.com/vi/twJkp0weRFw/mqdefault.jpg",
      videoSrc: "https://youtu.be/twJkp0weRFw",
    },
    {
      id: 9,
      image: "note-edit.svg",
      title: "相談メモの使い方",
      thumbnail: "/assets/imgs/video_thumbnail9.webp",
      videoSrc: "https://www.youtube.com/watch?v=mwRG0hPPLmg",
    },

    {
      id: 10,
      image: "mail_user.svg",
      title: "「サポートデスクに問い合わせるには？」",
      body: [
        "右上のメニューよりお問い合わせいただけます。",
        "非公開情報の開示請求、その他お困りごとについてのご相談窓口です。",
        "営業時間は祝日除く10:00～17:00となっております。",
      ],
    },

    {
      id: 11,
      image: "settings.svg",
      title: "「設定とは？」",
      body: ["あなたの登録している情報の変更ができます。"],
    },
    {
      id: 12,
      image: "bar.svg",
      title: "「進捗レポートとは？」",
      body: [
        "あなたがノートに情報を入力したり、家族にシェアをしたり、積極的に活用することで花が成長します。",
      ],
    },
    {
      id: 13,
      image: "user.svg",
      title: "「家族の情報を確認したい時は？」",
      body: [
        "「家族の情報を見る」を押すと、自分を招待してくれた家族の一覧とそれぞれの内容を確認できます。",
        "「家族の情報を見る」はKaki-Okiの招待を受けた相手にだけ表示されるメニューです。",
        "家族の情報を開き、上部の「〇〇さんのシェアした家族」を押すと、ノートの作成者が自分のほかに誰を招待しているかを確認できます。",
      ],
    },
    {
      id: 14,
      image: "delete-account.svg",
      title: "「Kaki-Okiをやめたいときは？」",
      body: [
        "右上のメニュー内の「お問い合わせ」からKaki-Okiをやめることができます。",
        "サポートデスクで退会申請を確認でき次第、退会手続きを開始します。",
        "Kaki-Okiをやめると、Kaki-Oki内にあるあなたの情報はすべて削除されます。",
      ],
    },
  ]
  const dataSource = expandedVideoItems
    ? howToUserData
    : howToUserData.slice(0, 6)

  return (
    <Content>
      <Title>{"すぐわかるKaki-Okiの 使い方～"}</Title>
      <Grid ref={myDivRef}>
        {dataSource?.map((data) => {
          return data?.videoSrc ? (
            <ThumbnailCard
              href={data?.videoSrc}
              style={{ textDecoration: "none", color: "#000000" }}
              target="_blank"
            >
              <ThumbnailWrapper>
                <ThumbnailImg
                  src={data?.thumbnail}
                  preview={false}
                  loading="lazy"
                  fallback="/assets/imgs/fallback-video.png"
                  placeholder={<img src="/assets/imgs/fallback-video.png" />}
                />
                <img
                  src="/assets/icons/play-icon.svg"
                  width={60}
                  height={60}
                  className="play-icon"
                />
              </ThumbnailWrapper>
              <div className="title">{data?.title}</div>
            </ThumbnailCard>
          ) : (
            <TextOnlyContainer>
              <TitleWrapper>{data?.title}</TitleWrapper>

              <DescriptionWrapper>
                {data?.body?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </DescriptionWrapper>
            </TextOnlyContainer>
          )
        })}
      </Grid>
      <SeeMoreButton
        onClick={() => {
          setExpandedVideoItems((prev) => !prev)
          if (expandedVideoItems) {
            myDivRef.current.scrollIntoView({ behavior: "smooth" })
          }
        }}
      >
        <div className="see-more">
          {expandedVideoItems ? t("See less") : t("See more")}
        </div>
        <div className="icon-wrapper">
          {expandedVideoItems ? <UpOutlined /> : <DownOutlined />}
        </div>
      </SeeMoreButton>
    </Content>
  )
}

export { HowToUseComponent }
