import React from "react"
import styled from "styled-components"
import Link from "next/link"

const FooterWrapper = styled.div`
  height: 100px;
  background-color: ${(props) => props.theme.background.primary};
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding-right: 40px;

  .link {
    margin-left: 40px;
    font-size: 16px;
    text-decoration: none;
    border-bottom: black 1px solid;
    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 750px) {
    height: 80px;
    justify-content: center;
    padding-right: 0px;
    padding-left: 0px;

    .link {
      margin-left: 15px;
      font-size: 16px;
    }
  }
  @media (max-width: 375px) {
    .link {
      margin-left: 10px;
      font-size: 16px;
    }
  }
  @media (max-width: 325px) {
    .link {
      font-size: 15px;
    }
  }
  & a {
    :hover {
      color: rgba(0, 0, 0, 0.85);
    }
  }
`

const HomepageFooter = () => {
  return (
    <>
      <FooterWrapper>
        <Link href="https://www.sunlife.jp/privacy_policy/">
          <a target="_blank" rel="noopener noreferrer">
            <div className="link">プライバシポリシー</div>
          </a>
        </Link>
        <Link href="https://www.sunlife.jp/kakioki/kiyaku.html">
          <a target="_blank" rel="noopener noreferrer">
            <div className="link">利用規約</div>
          </a>
        </Link>
        <Link href="https://www.sunlife.jp/company">
          <a target="_blank" rel="noopener noreferrer">
            <div className="link">運営会社情報</div>
          </a>
        </Link>
      </FooterWrapper>
    </>
  )
}

export default HomepageFooter
