import React, { useContext } from "react"
import { LineShare } from "../../organisms"
import { AuthContext } from "../../../utils"
import { useTranslation } from "react-i18next"
import {
  Title,
  BodyWrapper,
  TitleWrapper,
  ButtonWrapper,
  MobileButtonWrapper,
} from "./helper"

interface Props {
  title: string
  body?: boolean
  link?: string
}

const CommonSuccessPage: React.FC<Props> = ({ title, body, link }) => {
  const { t } = useTranslation()

  const { user } = useContext(AuthContext)

  const openShare = () => {
    window.open(
      `http://line.me/R/msg/text/?${user?.displayName}様よりデジタルエンディングノート「Kaki-Oki」（かきおき）の招待が届いています。ノートの閲覧、作成はこちらからURL「Kaki-Oki（かきおき）」とは、自分にもしものことがあったときに残された家族が困ることが無いよう、必要な情報を書き残しておくデジタルエンディングノートです。困らせたくない家族を「招待」することで、元気なうちから情報共有ができるシニア向け終活ツールです。%0A${link}`,
      "_blank"
    )
  }

  return (
    <div>
      <TitleWrapper>
        <Title>{t(`${title}`)}</Title>
      </TitleWrapper>
      {body && (
        <BodyWrapper>
          <p className="text">
            {t(
              "If you had entered email address, we sent you an invitation email. The invitation URL can also be sent directly by clicking the &quot;Share from line&quot; button below."
            )}
          </p>
          <ButtonWrapper>
            <LineShare
              title={`${user?.displayName}様よりデジタルエンディングノート「Kaki-Oki」（かきおき）の招待が届いています。ノートの閲覧、作成はこちらからURL「Kaki-Oki（かきおき）」とは、自分にもしものことがあったときに残された家族が困ることが無いよう、必要な情報を書き残しておくデジタルエンディングノートです。困らせたくない家族を「招待」することで、元気なうちから情報共有ができるシニア向け終活ツールです。`}
              url={link}
              className="share-btn"
            >
              <img src="/assets/icons/line.svg" alt="line" />
              <span>{t("Copy and share on LINE")}</span>
            </LineShare>
          </ButtonWrapper>
          <MobileButtonWrapper>
            <div className="share-btn" onClick={openShare}>
              <img src="/assets/icons/line.svg" alt="line" />
              <span>{t("Copy and share on LINE")}</span>
            </div>
          </MobileButtonWrapper>
          <p className="small-text">
            {t("Tap the button to open the LINE app.")}
          </p>
        </BodyWrapper>
      )}
    </div>
  )
}

export { CommonSuccessPage }
