import { API } from "@project/shared"

export interface IReturnFamily {
  count?: number
  data?: any
  nextPage?: number
}

interface IFamilyQueryParam {
  page?: string | number
  pageSize?: string
  role?: string
  keyword?: string
  status?: string
  user_id?: string
}

export const fetchFamily = async ({ queryKey }): Promise<IReturnFamily> => {
  const params = {} as IFamilyQueryParam
  params.page = queryKey[1].page
  params.role = queryKey[1].role
  params.pageSize = queryKey[1].pageSize
  params.keyword = queryKey[1].keyword
  params.status = queryKey[1].status
  return API.get("/user-relation", {
    params,
  })
}

export const fetchInfiniteFamily = async ({
  queryKey,
  pageParam = 1,
}): Promise<IReturnFamily> => {
  const params = {} as IFamilyQueryParam
  params.page = pageParam
  params.role = queryKey[1].role
  params.pageSize = queryKey[1].pageSize
  params.keyword = queryKey[1].keyword
  params.status = queryKey[1].status
  if (queryKey[1].userId) {
    params.user_id = queryKey[1].userId
  }
  const result = await API.get("/user-relation", {
    params,
  })
  result["nextPage"] = Number(pageParam) * Number(queryKey[1].pageSize) + 1
  return result
}

export const getFamilyById = async (id: string | string[]) => {
  return API.get(`/user-relation/${id}`)
}

export const createFamily = async (values): Promise<any> => {
  return API.post(`/user-relation`, values)
}

export const updateFamily = async (values) => {
  const id = values.id
  return API.put(`/user-relation/${id}`, values)
}

export const getPendingApprovalCount = async (): Promise<any> => {
  return API.get(`/user-relation-pending-count`)
}

interface IFamilyListReturnType {
  data: any
  count: number
}

interface IInvitedFamilyQueryParam {
  keyword?: string
  user_id?: string
}

export const getInvitedFamilyMember = async ({
  queryKey,
}): Promise<IFamilyListReturnType> => {
  const params = {} as IInvitedFamilyQueryParam
  params.keyword = queryKey[1].keyword
  if (queryKey[1].userId) {
    params.user_id = queryKey[1].userId
  }
  return API.get("/family-invites", {
    params,
  })
}
