import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import { message, notification } from "antd"
import { useTranslation } from "react-i18next"
import { API, auth } from "@project/shared"
import { v4 as uuidv4 } from "uuid"
import {
  IMAGE_FILE_MAX_SIZE_BYTES,
  VIDEO_FILE_MAX_SIZE_BYTES,
} from "../../../constants/file"
import styled from "styled-components"
import Uppy from "@uppy/core"
import Tus from "@uppy/tus"
import { FileUploadState } from "../../../types/global"
import { authContext } from "../../../utils"

interface ChunkFileUploadProps {
  onUploadComplete: Dispatch<SetStateAction<IFileObject>>
  supportedFileTypes: string[]
  show?: boolean
  childNode: React.ReactNode
  fileUploadState?: string
  setFileUploadState?: (state: FileUploadState) => void
}

export interface IFileObject {
  name: string
  url: string
  signedUrl?: string
}

const Wrapper = styled.div`
  & input {
    display: none;
  }
`

const ChunkFileUpload: React.FC<ChunkFileUploadProps> = (props) => {
  const {
    onUploadComplete,
    show = true,
    childNode,
    supportedFileTypes,
    fileUploadState,
    setFileUploadState,
  } = props
  const { t } = useTranslation()
  const fileUploadRef = useRef(null)
  const [chunkSize, setChunkSize] = useState(0)
  const [fileToBeUploaded, setFileToBeUploaded] = useState(null)
  const [counter, setCounter] = useState(0)
  const [progress, setProgress] = useState(0)
  const [totalChunkCount, setTotalChunkCount] = useState(0)
  const [chunkStart, setChunkStart] = useState(0)
  const [chunkEnd, setChunkEnd] = useState(0)

  const [uniqueFilename, setUniqueFilename] = useState("")
  const [fileType, setFileType] = useState("")
  const { userId } = authContext()

  const resetChunkProperties = () => {
    setProgress(0)
    setFileUploadState(FileUploadState.Uploading)
    setCounter(1)
    setChunkStart(0)
  }
  const uppy = new Uppy({
    id: "memories",
    debug: true,
    autoProceed: true,
    restrictions: {
      maxFileSize: VIDEO_FILE_MAX_SIZE_BYTES,
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: supportedFileTypes,
    },
  }).use(Tus, {
    endpoint: `${process.env.NEXT_PUBLIC_APP_API_URL}/tus-files/`,
    removeFingerprintOnSuccess: true,
    storeFingerprintForResuming: true,
    chunkSize: 20 * 1024 * 1024,
    onBeforeRequest: async (req) => {
      const token = await auth.currentUser?.getIdToken(true)
      req.setHeader("authorization", token)
    },
  })

  uppy.on("upload-success", (file, response) => {
    setFileUploadState(FileUploadState.DefaultState)
    const objectId = response.uploadURL.split("/tus-files/")[1]

    const url = `https://storage.googleapis.com/${process?.env?.STORAGE_BUCKET_NAME}/${userId}/videos/${objectId}`
    onUploadComplete({
      name: file.name,
      url: url,
    })

    uppy.cancelAll()
    fileUploadRef.current.value = null
    setFileToBeUploaded(null)
  })

  uppy.on("upload-error", (file, error, response) => {
    setFileUploadState(FileUploadState.Paused)
  })

  const getSupportedFileTypesString = (fileTypes: string[]) => {
    const initialValue = ""
    const supportedFileTypes = fileTypes.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.split("/")[1] + ",",
      initialValue
    )
    return supportedFileTypes.slice(0, -1)
  }

  const getAcceptedFileTypesString = (fileTypes: string[]) => {
    const initialValue = ""
    const supportedFileTypes = fileTypes.reduce(
      (previousValue, currentValue) => previousValue + currentValue + ", ",
      initialValue
    )
    return supportedFileTypes.slice(0, -2)
  }

  const uploadChunk = async (bodyFormData) => {
    try {
      const headers = {
        "Content-Range": `bytes ${chunkStart}-${chunkEnd}/${fileToBeUploaded.size}`,
      }
      const response = await API.post("/utils/chunk-upload", bodyFormData, {
        headers,
      })
      if (!response) {
        setFileUploadState(FileUploadState.DefaultState)
        notification.error({
          message: t("Failed to upload a file"),
        })
        fileUploadRef.current.value = null
        return
      }
      setChunkStart(chunkEnd)
      setChunkEnd(chunkEnd + chunkSize)
      if (counter == totalChunkCount) {
        const urlArray = response?.data?.data.split(".")
        const filenameArray = fileToBeUploaded?.name.split(".")
        filenameArray[filenameArray.length - 1] = urlArray[urlArray.length - 1]
        setFileUploadState(FileUploadState.DefaultState)
        onUploadComplete({
          name: filenameArray.join("."),
          url: response?.data?.data,
        })
        setCounter(0)
        fileUploadRef.current.value = null
        setFileToBeUploaded(null)
        setFileUploadState(FileUploadState.DefaultState)
      } else {
        setProgress((counter / totalChunkCount) * 100)
      }
    } catch (error) {
      setFileUploadState(FileUploadState.DefaultState)
      fileUploadRef.current.value = null
      setFileToBeUploaded(null)
      const msg = error?.data?.error?.message
      notification.error({
        message: msg ? t(`${msg}`) : t("Failed to upload a file"),
      })
    }
  }

  const beforeUpload = (file) => {
    return new Promise((resolve, reject) => {
      if (
        !file.type.includes("video/") &&
        !supportedFileTypes.includes(file.type)
      ) {
        reject(
          new Error(
            t(
              `${t("Files only with")} ${getSupportedFileTypesString(
                supportedFileTypes
              )} ${t("are supported")}`
            )
          )
        )
      }
      if (file.type.includes("video/")) {
        setFileType("video")
        const isLt500M = file.size < VIDEO_FILE_MAX_SIZE_BYTES
        if (!isLt500M)
          reject(new Error(t("Video within 500mb can only be uploaded.")))
        resolve(true)
      } else if (file.type.includes("image/")) {
        setFileType("image")
        const isLt10M = file.size < IMAGE_FILE_MAX_SIZE_BYTES
        if (!isLt10M)
          reject(new Error(t("Image within 10mb can only be uploaded.")))
        resolve(true)
      }
      reject(new Error(t("Invalid file.")))
    })
  }

  const calculateChunkSize = (totalSize: number) => {
    if (totalSize > 100 * 1024 * 1024) {
      return 10 * 1024 * 1024
    } else if (totalSize > 10 * 1024 * 1024) {
      return 5 * 1024 * 1024
    } else if (totalSize > 5 * 1024 * 1024) {
      return 1 * 1024 * 1024
    }
    return 500 * 1024
  }

  const handleFileChange = async (e) => {
    try {
      const myFile = e.target.files[0]
      await beforeUpload(myFile)
      setFileUploadState(FileUploadState.Uploading)
      if (myFile.type.includes("image/")) {
        resetChunkProperties()
        const fNameArray = myFile?.name?.split(".")
        const uniqueFilename = `${uuidv4()}.${
          fNameArray[fNameArray?.length - 1]
        }`
        setUniqueFilename(uniqueFilename)
        const chunkSize = calculateChunkSize(myFile.size)
        setChunkSize(chunkSize)
        setChunkEnd(chunkSize)
        const totalChunks =
          myFile?.size % chunkSize == 0
            ? myFile?.size / chunkSize
            : Math.floor(myFile?.size / chunkSize) + 1
        setTotalChunkCount(totalChunks)
        setFileToBeUploaded(myFile)
      } else if (myFile.type.includes("video/")) {
        uppy.cancelAll()
        uppy.addFile({
          source: "file-input",
          name: myFile.name,
          type: myFile.type,
          data: myFile,
        })
        setFileToBeUploaded(myFile)
      }
    } catch (error) {
      fileUploadRef.current.value = null
      setFileToBeUploaded(null)
      setFileUploadState(FileUploadState.DefaultState)
      message.error(error?.message)
    }
  }

  useEffect(() => {
    if (
      fileToBeUploaded?.size > 0 &&
      fileToBeUploaded.type.includes("image/")
    ) {
      setCounter(counter + 1)
      if (counter <= totalChunkCount) {
        const chunk = fileToBeUploaded.slice(chunkStart, chunkEnd)
        const bodyFormData = new FormData()
        bodyFormData.append("file", chunk, uniqueFilename)
        bodyFormData.append("file_type", fileType)
        uploadChunk(bodyFormData)
      }
    }
  }, [fileToBeUploaded, progress])

  useEffect(() => {
    if (fileUploadState == FileUploadState.Resume) {
      uppy.addFile({
        source: "file-input",
        name: fileToBeUploaded.name,
        type: fileToBeUploaded.type,
        data: fileToBeUploaded,
      })
      setFileUploadState(FileUploadState.Uploading)
    } else if (fileUploadState == FileUploadState.Cancel) {
      uppy.cancelAll()
      fileUploadRef.current.value = null
      setFileToBeUploaded(null)
      setFileUploadState(FileUploadState.DefaultState)
    }
  }, [fileUploadState])

  return (
    <Wrapper>
      <label htmlFor="file-input">{show && childNode}</label>
      <input
        id="file-input"
        type="file"
        ref={fileUploadRef}
        onChange={handleFileChange}
        accept={getAcceptedFileTypesString(supportedFileTypes)}
      />
    </Wrapper>
  )
}

export { ChunkFileUpload }
