import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation } from "react-query"
import * as yup from "yup"
import { notification, Spin } from "antd"
import { TextField, Button } from "@project/shared"
import {
  createAsset,
  getAssetById,
  updateAsset,
} from "../../../../services/asset"
import { STOCKANDBOND } from "@project/shared/src/utils/constant"
import {
  ButtonWrapper,
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../../RelativesFriendsForm/helper"
import { useEffect } from "react"

interface Props {
  id?: string | string[]
}

const StockBondForm: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const {
    data: stockNBondData,
    isLoading: stockNBondLoading,
    isFetching: stockNBondFetching,
  } = useQuery<any, Error>(["stockNBond", id], () => id && getAssetById(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: ({ data }) => {
      return {
        company_name: data?.stocks_bonds?.company_name,
        nominee: data?.stocks_bonds?.nominee,
        contact: data?.stocks_bonds?.contact,
        memo_notes: data?.stocks_bonds?.memo_notes,
      }
    },
  })

  const initialValues = stockNBondData || {
    company_name: "",
    nominee: "",
    contact: "",
    memo_notes: "",
  }

  const validationSchema = yup.object().shape({
    company_name: yup.string(),
    nominee: yup.string(),
    contact: yup.string(),
    memo_notes: yup.string(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id) {
        mutate({ assets_type: STOCKANDBOND, stocks_bonds: values, id: id })
      } else {
        mutate({ assets_type: STOCKANDBOND, stocks_bonds: values })
      }
    },
  })

  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])

  const { mutate, isLoading, isSuccess } = useMutation(
    id ? updateAsset : createAsset,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: t("Stock and Bond information has been updated"),
          })
        } else {
          notification.success({
            message: t(
              "Stock and Bond information has been successfully created"
            ),
          })
        }
        router.push("/friends-property/asset-management")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )
  if (stockNBondLoading || stockNBondFetching) {
    return (
      <div className="loader-wrapper">
        <Spin />
      </div>
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldContainer className="name">
        <LabelContainer>
          <Label>{t("Company name")}</Label>
        </LabelContainer>
        <TextField
          name="company_name"
          value={formik.values.company_name}
          onChange={formik.handleChange}
          error={formik.touched.name && formik.errors?.company_name}
          onBlur={formik.handleBlur}
          placeholder={t("Company name")}
          height="50px"
          borderradius="10px"
          showCounting
          maxLength={30}
          bordercolor="#DEDBD5"
          disableboxshadow
          className="dynamic-textarea"
          type="dynamic-textarea"
        />
      </TextFieldContainer>

      <TextFieldContainer className="name">
        <LabelContainer>
          <Label>{t("Nominee")}</Label>
        </LabelContainer>
        <TextField
          name="nominee"
          value={formik.values.nominee}
          onChange={formik.handleChange}
          error={formik.touched.name && formik.errors?.nominee}
          onBlur={formik.handleBlur}
          placeholder={t("Nominee")}
          height="50px"
          borderradius="10px"
          showCounting
          maxLength={30}
          bordercolor="#DEDBD5"
          disableboxshadow
          className="dynamic-textarea"
          type="dynamic-textarea"
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Contact")}</Label>
        </LabelContainer>
        <TextField
          name="contact"
          value={formik.values.contact}
          onChange={formik.handleChange}
          error={formik.touched.contact && formik.errors?.contact}
          onBlur={formik.handleBlur}
          placeholder={t("Contact")}
          height="50px"
          borderradius="10px"
          showCounting
          maxLength={30}
          bordercolor="#DEDBD5"
          disableboxshadow
          className="dynamic-textarea"
          type="dynamic-textarea"
        />
      </TextFieldContainer>

      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Memo・Notes")}</Label>
        </LabelContainer>
        <TextField
          name="memo_notes"
          value={formik.values.memo_notes}
          onChange={formik.handleChange}
          error={formik.touched.memo_notes && formik.errors?.memo_notes}
          onBlur={formik.handleBlur}
          placeholder={t("Memo・Notes")}
          height="50px"
          width="100%"
          type="textarea"
          borderradius="10px"
          bordercolor="#DEDBD5"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>
      <ButtonWrapper>
        <Button
          htmlType="submit"
          loading={isLoading}
          disabled={isSuccess}
          width="200px"
          height="50px"
          background="#E26E30"
        >
          {id ? t("Update") : t("Add")}
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export { StockBondForm }
