import { API } from "@project/shared"

export const getSingleUserDetails = async (id: string | string[]) => {
  return API.get(`/users/${id}`)
}

export const updateSingleUserDetails = async (values) => {
  return API.put(`/self-user`, values)
}

export const getOtpSelf = async (values) => {
  return API.post(`/send-otp-self`, values)
}

export const deleteAccount = async (values) => {
  return API.post("/self-user", { reason: values.reason })
}
