import styled from "styled-components"
import { theme } from "@project/shared"

export const RowContainer = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid #dddcdc;
  margin-bottom: 18px;
`

export const LabelContainer = styled.div`
  margin-bottom: 10px;

  @media (max-width: 786px) {
    margin-bottom: 0px;
  }
`

export const Label = styled.div`
  font-weight: 500;
  font-size: 16px;

  ::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba(226, 110, 48, 0.2);
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  @media (max-width: 786px) {
    font-size: 16px;
  }
`

export const Text = styled.span`
  margin-left: 25px;

  @media (max-width: 786px) {
    font-size: 16px;
  }
`
export const Notes = styled.div`
  background: rgba(238, 152, 24, 0.08);
  border-radius: 10px;
  padding: 20px 0;
  text-align: center;
  color: ${theme.alert};
  font-weight: 500;
  font-size: 16px;
  margin: 30px 0;

  span {
    margin-left: 8px;
  }
`

export const ButtonWrapper = styled.div`
  text-align: center;

  .btn {
    height: 50px;
    width: 160px;

    :first-child {
      margin-right: 20px;
    }
  }

  @media (max-width: 786px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      width: 240px;
      font-size: 16px;
    }

    .btn:first-child {
      margin-bottom: 10px;
    }
  }
`
