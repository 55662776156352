import router from "next/router"
import React from "react"
import styled from "styled-components"

interface iHeaderProps {
  title?: string
  link?: string
  image?: string
}

const HeaderWrapper = styled.div`
  background-color: ${(props) => props.theme.background.primary};
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  line-height: 22px;
  .log-in {
    display: flex;
    align-items: center;
    padding: 25px 10px;
    &:hover {
      cursor: pointer;
    }
  }

  .login-icon {
    margin-left: 9px;
    margin-right: 19px;

    & img {
      width: 22px;
      height: 22px;
    }
  }
  @media (max-width: 375px) {
    .text {
      font-size: 18px;
      word-break: break-all;
      padding-left: 10px;
    }
    .login-icon {
      margin-left: 9px;
      margin-right: 10px;
    }
  }
`

const Header: React.FC<iHeaderProps> = ({ title, link, image }) => {
  return (
    <HeaderWrapper>
      <div className="log-in" onClick={() => router.push(link)}>
        <div className="text">{title}</div>
        <div className="login-icon">{image && <img src={image} />}</div>
      </div>
    </HeaderWrapper>
  )
}

export { Header }
