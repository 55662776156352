import {
  ACCESSORIES,
  ALBUMS,
  ALTERNMORTUARY,
  ANTIQUE,
  ARTS,
  COLLECTIONS,
  INSTRUMENTS,
  OTHERS,
  PICTURES,
  WEARS,
  WILL,
} from "@project/shared/src/utils/constant"
import { t } from "i18next"

/**
 * Storage kinds options
 */
export const storageKind = [
  { label: t("Will"), value: WILL },
  { label: t("Pictures"), value: PICTURES },
  { label: t("Albums"), value: ALBUMS },
  { label: t("Wears"), value: WEARS },
  { label: t("Accessories"), value: ACCESSORIES },
  { label: t("Instruments"), value: INSTRUMENTS },
  { label: t("Arts"), value: ARTS },
  { label: t("Antique"), value: ANTIQUE },
  { label: t("Collections"), value: COLLECTIONS },
  { label: t("Altar and mortuary"), value: ALTERNMORTUARY },
  { label: t("Others"), value: OTHERS },
]
