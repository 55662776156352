import { t } from "i18next"
import { Spin } from "antd"
import React, { useEffect, useState } from "react"
import { CheckSquareOutlined } from "@ant-design/icons"

import { StyledPageHeader } from "../../molecules/TopPage/HowToUse"
import {
  List,
  Body,
  Title,
  Footer,
  Content,
  CheckBox,
  ImageWrapper,
  Notification,
  LoaderWrapper,
  DescriptionWrapper,
} from "./helper"

interface Props {
  progressReportData: any
  defaultView: string
  isLoadingProgressReportData: boolean
  isFetchingProgressReportData: boolean
}

const ProgressReport: React.FC<Props> = ({
  defaultView,
  progressReportData,
  isLoadingProgressReportData,
  isFetchingProgressReportData,
}) => {
  const [count, setCount] = useState(0)
  const [chronology, setChronology] = useState([])
  const [notification, setNotification] = useState("")

  useEffect(() => {
    let number = 0
    const array = []
    progressReportData?.map((data) => {
      if (Object.values(data)[0] === 1) {
        number = number + 1
        setNotification(Object.keys(data)[0])
      }
      array.push(Object.keys(data))
    })
    setCount(number)
    setChronology(array.flat())
  }, [progressReportData])

  const getPageHeader = () => {
    return (
      <StyledPageHeader
        icon={
          <img
            src="/assets/icons/bar.svg"
            alt="note edit icon"
            height={32}
            width={32}
          />
        }
        alignment="flex-start"
        headerTitle={t("Progress report")}
      />
    )
  }

  const getNotificationOnTop = () => {
    switch (notification) {
      case "login":
        return (
          <Notification>{t("You logged in for the first time !")}</Notification>
        )
      case "family_invitation":
        return <Notification>家族を招待したとき</Notification>
      case "ten_items":
        return (
          <Notification>ノートに「10項目の回答」がありました！</Notification>
        )
      case "thirty_items":
        return (
          <Notification>ノートに「30項目の回答」がありました！</Notification>
        )
    }
  }

  const getProgressReportImage = () => {
    if (count === 1) {
      return <img src="/assets/imgs/pr.png" alt="progress-report" />
    } else if (count === 2) {
      return <img src="/assets/imgs/pr2.png" alt="progress-report" />
    } else if (count === 3) {
      return <img src="/assets/imgs/pr3.png" alt="progress-report" />
    }
    return <img src="/assets/imgs/pr4.png" alt="progress-report" />
  }

  const getImage = () => {
    return <ImageWrapper>{getProgressReportImage()}</ImageWrapper>
  }

  const getListItems = () => {
    if (count === 0) {
      return (
        <div>
          <div>
            <span>1. {t("First login")}</span>
          </div>
          <div>
            <span>
              2. {t("Add 10 items or Invite family members (Either one)")}
            </span>
          </div>
          <div>
            <span>
              3. {t("Add 30 items or Invite family members (Either one)")}
            </span>
          </div>
          <div>
            <span>4. {t("Invite family members")}</span>
          </div>
        </div>
      )
    } else if (count === 1) {
      return (
        <div>
          <CheckBox>
            <CheckSquareOutlined className="icon" />
            <span>{t("First login")}</span>
          </CheckBox>
          <div>
            <span>
              2. {t("Add 10 items or Invite family members (Either one)")}
            </span>
          </div>
          <div>
            <span>
              3. {t("Add 30 items or Invite family members (Either one)")}
            </span>
          </div>
          <div>
            <span>4. {t("Invite family members")}</span>
          </div>
        </div>
      )
    } else if (count === 2) {
      return (
        <div>
          <CheckBox>
            <CheckSquareOutlined className="icon" />
            <span>{t("First login")}</span>
          </CheckBox>
          {chronology[1] === "family_invitation" ? (
            <>
              <CheckBox>
                <CheckSquareOutlined className="icon" />
                <span>{t("Invite family members")}</span>
              </CheckBox>
              <div>
                <span>3. {t("Add 10 items")}</span>
              </div>{" "}
              <div>
                <span>4. {t("Add 30 items")}</span>
              </div>
            </>
          ) : (
            <>
              <CheckBox>
                <CheckSquareOutlined className="icon" />
                <span>{t("Add 10 items")}</span>
              </CheckBox>
              <div>
                <span>
                  3. {t("Add 30 items or Invite family members (Either one)")}
                </span>
              </div>
              <div>
                <span>4. {t("Invite family members")}</span>
              </div>
            </>
          )}
        </div>
      )
    } else if (count === 3) {
      return (
        <div>
          <CheckBox>
            <CheckSquareOutlined className="icon" />
            <span>{t("First login")}</span>
          </CheckBox>
          {chronology[1] === "family_invitation" ? (
            <>
              <CheckBox>
                <CheckSquareOutlined className="icon" />
                <span>{t("Invite family members")}</span>
              </CheckBox>
              <CheckBox>
                <CheckSquareOutlined className="icon" />
                <span>{t("Add 10 items")}</span>
              </CheckBox>
              <div>
                <span>4. {t("Add 30 items")}</span>
              </div>
            </>
          ) : chronology[2] === "thirty_items" ? (
            <>
              <CheckBox>
                <CheckSquareOutlined className="icon" />
                <span>{t("Add 10 items")}</span>
              </CheckBox>
              <CheckBox>
                <CheckSquareOutlined className="icon" />
                <span>
                  {t("Add 30 items or Invite family members (Either one)")}
                </span>
              </CheckBox>
              <div>
                <span>4. {t("Invite family members")}</span>
              </div>
            </>
          ) : (
            <>
              <CheckBox>
                <CheckSquareOutlined className="icon" />
                <span>{t("Add 10 items")}</span>
              </CheckBox>
              <CheckBox>
                <CheckSquareOutlined className="icon" />
                <span>{t("Invite family members")}</span>
              </CheckBox>
              <div>
                <span>
                  4. {t("Add 30 items or Invite family members (Either one)")}
                </span>
              </div>
            </>
          )}
        </div>
      )
    } else if (count === 4) {
      return (
        <div>
          <CheckBox>
            <CheckSquareOutlined className="icon" />
            <span>{t("First login")}</span>
          </CheckBox>
          {chronology[1] === "family_invitation" ? (
            <CheckBox>
              <CheckSquareOutlined className="icon" />
              <span>{t("Invite family members")}</span>
            </CheckBox>
          ) : (
            <CheckBox>
              <CheckSquareOutlined className="icon" />
              <span>{t("Add 10 items")}</span>
            </CheckBox>
          )}

          {chronology[2] === "thirty_items" ? (
            <CheckBox>
              <CheckSquareOutlined className="icon" />
              <span>{t("Add 30 items")}</span>
            </CheckBox>
          ) : (
            <CheckBox>
              <CheckSquareOutlined className="icon" />
              <span>{t("Add 10 items")}</span>
            </CheckBox>
          )}
          {chronology[3] === "thirty_items" ? (
            <CheckBox>
              <CheckSquareOutlined className="icon" />
              <span>{t("Add 30 items")}</span>
            </CheckBox>
          ) : (
            <CheckBox>
              <CheckSquareOutlined className="icon" />
              <span>{t("Invite family members")}</span>
            </CheckBox>
          )}
        </div>
      )
    }
  }

  const getDescription = () => {
    return (
      <DescriptionWrapper>
        <Title>
          {t(
            "If you do the following, the flowers will bloom as the Kakioki notebook progresses!"
          )}
        </Title>

        <List>{getListItems()}</List>

        <Footer>
          {count === 4
            ? t(
                "Congratulations, you have completed all the required steps and the flower has fully bloomed"
              )
            : t("Please complete all the items and let the flowers bloom!")}
        </Footer>
      </DescriptionWrapper>
    )
  }

  if (
    (defaultView === "progress-report" && isLoadingProgressReportData) ||
    isFetchingProgressReportData
  ) {
    return (
      <Body>
        <LoaderWrapper>
          <Spin size="large" className="loader" />
        </LoaderWrapper>
      </Body>
    )
  }

  return (
    <Content>
      {getPageHeader()}

      <Body>
        {getNotificationOnTop()}

        {getImage()}

        {getDescription()}
      </Body>
    </Content>
  )
}

export { ProgressReport }
