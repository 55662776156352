import { useFormik } from "formik"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation } from "react-query"
import * as yup from "yup"
import { Image, notification, Spin } from "antd"
import {
  TextField,
  Button,
  getSignedObjectUrl,
  deleteFileGCS,
  UploadImageNode,
  Select,
} from "@project/shared"
import {
  createStorage,
  getStorageById,
  updateStorage,
} from "../../../services/storage"
import { storageKind } from "../../../constants/storage"
import FormExternalMenus from "../FormExternalMenus"
import {
  ButtonWrapper,
  Label,
  LabelContainer,
  TextFieldContainer,
} from "../RelativesFriendsForm/helper"
import { ChunkFileUpload } from "../../molecules"
import { IFileObject } from "../../molecules/ChunkFileUpload"
import { useEffect, useState } from "react"
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { FileUploadState } from "../../../types/global"

interface Props {
  id?: string | string[]
}

const ImagePreviewContainer = styled.div`
  display: flex;
  align-items: flex-end;
  .img-wrapper {
    margin-top: 9px;
    background-color: #e3e0d9;
    width: 275px;
    height: 166px;
    img {
      object-fit: contain;
      background-color: #e3e0d9;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delete-btn {
    width: 40px;
    padding: 11px 0px 11px 0px;
    height: 40px;
    border: 1px solid;
    border-color: #e0e0e0;
    margin-left: 10px;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (max-width: 390px) {
      margin-left: 6px;
    }

    .icon {
      color: #e26e30;
    }
  }
`

const StorageForm: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const [image, setImage] = useState<IFileObject>(null)
  const [imageUploadState, setImageUploadState] = useState<FileUploadState>(
    FileUploadState.DefaultState
  )

  const {
    data: storageData,
    isLoading: storageLoading,
    isFetching: storageFetching,
  } = useQuery<any, Error>(["storage", id], () => id && getStorageById(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 1,
    select: ({ data }) => {
      return {
        kind: data?.kind,
        contents: data?.contents,
        stored_location: data?.stored_location,
        desire: data?.desire,
        memo_notes: data?.memo_notes,
        image: data?.image,
      }
    },
    onSuccess(data) {
      setImage({ name: data?.image?.name, url: data?.image?.url })
    },
  })

  const initialValues = storageData || {
    kind: null,
    contents: "",
    stored_location: "",
    desire: "",
    image: {
      url: "",
      name: "",
    },
    memo_notes: "",
  }

  const validationSchema = yup.object().shape({
    kind: yup.string().nullable().required(t("Required")),
    contents: yup.string(),
    stored_location: yup.string(),
    desire: yup.string(),
    memo_notes: yup.string(),
    image: yup.object().shape({
      url: yup.string(),
      name: yup.string(),
    }),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (id) {
        mutate({ ...values, id: id })
      } else {
        mutate(values)
      }
    },
  })
  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])

  useEffect(() => {
    ;(async () => {
      if (image?.url) {
        formik.setFieldValue("image.name", image?.name ? image?.name : "")
        formik.setFieldValue("image.url", image?.url ? image?.url : "")
        const signedUrl = await getSignedObjectUrl(image?.url)
        setImage((prev) => {
          return {
            ...prev,
            signedUrl,
          }
        })
      }
    })()
  }, [image?.url])

  const { mutate, isLoading, isSuccess } = useMutation(
    id ? updateStorage : createStorage,
    {
      onSuccess: () => {
        if (id) {
          notification.success({
            message: t(
              "Storage place for important things information has been updated"
            ),
          })
        } else {
          notification.success({
            message: t(
              "Storage place for important things information has been successfully created"
            ),
          })
        }
        router.push("/friends-property/storage")
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    }
  )

  const { mutate: deleteUploadedImage, isLoading: isImageDeleting } =
    useMutation(deleteFileGCS, {
      onSuccess: () => {
        setImage({ name: "", url: "", signedUrl: "" })
        notification.success({
          message: t("Image delete successful"),
        })
      },
      onError: (error?: any) => {
        const msg = error?.data?.error?.message
        notification.error({
          message: msg
            ? t(msg)
            : t("An error has occurred. Please try again later."),
        })
      },
    })
  /**
   * External link menusItems.
   */
  const externalMenusItem = [
    {
      title: "生前整理・遺品整理はダスキンスキル",
      path: "/preparing",
      image: "image11.jpg",
    },
    {
      title: "エンバーミングで最良のお別れ",
      path: "https://embalming-sec.com/",
      image: "image7.jpg",
    },
  ]

  const handleKindsChangeOption = (value) => {
    formik.setFieldValue("kind", value)
  }

  const handleImageDeleteClick = (image_url) => {
    if (!id) {
      deleteUploadedImage({ files: [image_url] })
    } else {
      setImage({ name: "", url: "", signedUrl: "" })
    }
  }

  if (storageFetching || storageLoading)
    return (
      <div className="loader-wrapper">
        <Spin />
      </div>
    )

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Kinds")}</Label>
        </LabelContainer>
        <Select
          type="validate"
          name="kind"
          value={formik.values.kind || null}
          options={storageKind}
          onChange={handleKindsChangeOption}
          placeholder={t("Kinds")}
          className="select-component"
          error={
            formik.touched.kind && formik.errors?.kind
              ? formik.errors?.kind
              : false
          }
        />
      </TextFieldContainer>
      <TextFieldContainer className="name">
        <LabelContainer>
          <Label>{t("Contents")}</Label>
        </LabelContainer>
        <TextField
          name="contents"
          value={formik.values.contents}
          onChange={formik.handleChange}
          error={formik.touched.contents && formik.errors?.contents}
          onBlur={formik.handleBlur}
          placeholder={t("Contents")}
          height="50px"
          width="100%"
          borderradius="10px"
          showCounting
          type="textarea"
          maxLength={300}
          rows={4}
          bordercolor="#DEDBD5"
          disableboxshadow
        />
      </TextFieldContainer>
      <TextFieldContainer className="name">
        <LabelContainer>
          <Label>{t("Stored location")}</Label>
        </LabelContainer>
        <TextField
          name="stored_location"
          value={formik.values.stored_location}
          onChange={formik.handleChange}
          error={formik.touched.name && formik.errors?.stored_location}
          onBlur={formik.handleBlur}
          placeholder={t("Stored location")}
          height="50px"
          borderradius="10px"
          showCounting
          maxLength={50}
          bordercolor="#DEDBD5"
          disableboxshadow
          className="dynamic-textarea"
          type="dynamic-textarea"
        />
      </TextFieldContainer>
      <TextFieldContainer className="name">
        <LabelContainer>
          <Label>{t("What i want to do")}</Label>
        </LabelContainer>
        <TextField
          name="desire"
          value={formik.values.desire}
          onChange={formik.handleChange}
          error={formik.touched.desire && formik.errors?.desire}
          onBlur={formik.handleBlur}
          placeholder={t("例　遺影に使ってほしい\n例　処分して構わない")}
          height="50px"
          width="100%"
          borderradius="10px"
          showCounting
          type="textarea"
          maxLength={300}
          rows={4}
          bordercolor="#DEDBD5"
          disableboxshadow
        />
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Image upload")}</Label>
        </LabelContainer>
        <ChunkFileUpload
          onUploadComplete={setImage}
          setFileUploadState={setImageUploadState}
          supportedFileTypes={[
            "image/jpg",
            "image/jpeg",
            "image/png",
            "image/tiff",
          ]}
          show={!image?.url}
          childNode={UploadImageNode(t("Upload image"))}
        />
        {(image?.url || imageUploadState === FileUploadState.Uploading) && (
          <ImagePreviewContainer>
            <div className="img-wrapper">
              {imageUploadState === FileUploadState.Uploading ||
              isImageDeleting ? (
                <Spin />
              ) : (
                <Image
                  src={image?.signedUrl}
                  width="100%"
                  height={166}
                  preview={{
                    mask: (
                      <span>
                        <EyeOutlined />
                        &nbsp;
                        {t("Preview")}
                      </span>
                    ),
                  }}
                />
              )}
            </div>
            <div
              className="delete-btn"
              onClick={() => {
                handleImageDeleteClick(image?.url)
              }}
            >
              <DeleteOutlined className="icon" />
            </div>
          </ImagePreviewContainer>
        )}
      </TextFieldContainer>
      <TextFieldContainer>
        <LabelContainer>
          <Label>{t("Memo・Notes")}</Label>
        </LabelContainer>
        <TextField
          name="memo_notes"
          value={formik.values.memo_notes}
          onChange={formik.handleChange}
          error={formik.touched.memo_notes && formik.errors?.memo_notes}
          onBlur={formik.handleBlur}
          placeholder={t("※「カード番号、有効期限は入力しないでください」")}
          height="50px"
          width="100%"
          borderradius="10px"
          bordercolor="#DEDBD5"
          type="textarea"
          maxLength={300}
          rows={4}
          showCounting
          disableboxshadow
        />
      </TextFieldContainer>
      <ButtonWrapper>
        <Button
          htmlType="submit"
          loading={isLoading}
          disabled={isSuccess}
          width="200px"
          height="50px"
          background="#E26E30"
        >
          {id ? t("Update") : t("Add")}
        </Button>
      </ButtonWrapper>
      {/**
       * External menus
       */}
      <FormExternalMenus menuItems={externalMenusItem} />
    </form>
  )
}

export { StorageForm }
