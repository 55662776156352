import React, { useEffect } from "react"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { IRelativesFriends } from "../../../interface"
import { Select, TextField, Button, theme } from "@project/shared"
import {
  Label,
  Container,
  ButtonWrapper,
  LabelContainer,
  validationSchema,
  TextFieldContainer,
} from "./helper"

interface Props {
  handleSubmit: (values) => void
  loading: any
  data?: any
}

const RelativesFriendsForm: React.FC<Props> = ({
  handleSubmit,
  loading,
  data,
}) => {
  const { t } = useTranslation()

  const initialValues = data
    ? data
    : {
        relation: null,
        name: "",
        nick_name: "",
        phone: "",
        email: "",
        on_hospitalization: null,
        on_death: null,
        message_on_meet: "",
        memo: "",
      }

  const formik = useFormik<IRelativesFriends>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const death_data = [
    { value: "ぜひしてほしい", label: t("Inform") },
    { value: "できればしてほしい", label: t("If possible") },
    { value: "しなくてもいい", label: t("Not necessary") },
    { value: "してほしくない", label: t("Don’t") },
  ]

  const relation_data = [
    { value: "親戚", label: t("Relative") },
    { value: "親友", label: t("Best friend") },
    { value: "友人", label: t("Friend") },
    { value: "同僚", label: t("Roommate") },
    { value: "上司", label: t("Senior") },
    { value: "部下", label: t("Junior") },
    { value: "近所", label: t("Neighbor") },
    { value: "その他", label: t("Others") },
  ]

  useEffect(() => {
    if (!formik.isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const elementByName = document.getElementsByName(
          Object.keys(formik.errors)?.[0]
        )[0]
        elementByName?.scrollIntoView({
          block: "center",
        })
        elementByName?.focus()
      }
    }
  }, [!!formik && formik.isSubmitting])
  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Your relation")}</Label>
          </LabelContainer>
          <Select
            type="validate"
            name="relation"
            value={formik?.values.relation || null}
            className="select-field"
            placeholder={t("Please select")}
            allowClear
            onChange={(value) => {
              formik.setFieldValue("relation", value)
            }}
            error={formik.touched.relation && formik.errors?.relation}
            options={relation_data}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Name")}</Label>
          </LabelContainer>
          <TextField
            name="name"
            placeholder={t("Name")}
            className="dynamic-textarea"
            type="dynamic-textarea"
            maxLength={30}
            showCounting
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && formik.errors?.name}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Nickname")}</Label>
          </LabelContainer>
          <TextField
            name="nick_name"
            placeholder={t("Nickname")}
            className="dynamic-textarea"
            type="dynamic-textarea"
            maxLength={30}
            showCounting
            value={formik.values.nick_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.nick_name && formik.errors?.nick_name}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Phone number")}</Label>
          </LabelContainer>
          <TextField
            name="phone"
            placeholder={t("Phone number")}
            className="text-field"
            maxLength={12}
            showCounting
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && formik.errors?.phone}
            type="dynamic-textarea"
          />
          <div
            className={
              formik.touched.phone && formik.errors?.phone
                ? "optionalDown"
                : "optional"
            }
          >
            {t("※半角数字で入力してください")}
          </div>
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Email address")}</Label>
          </LabelContainer>
          <TextField
            name="email"
            placeholder={t("Email address")}
            className="text-field address"
            maxLength={50}
            showCounting
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors?.email}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Inform when hospitalization or long term care")}</Label>
          </LabelContainer>
          <Select
            type="validate"
            name="on_hospitalization"
            value={formik?.values.on_hospitalization || null}
            className="select-field"
            placeholder={t("Please select")}
            allowClear
            onChange={(value) => {
              formik.setFieldValue("on_hospitalization", value)
            }}
            error={
              formik.touched.on_hospitalization &&
              formik.errors.on_hospitalization
            }
            options={death_data}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Inform when you die")}</Label>
          </LabelContainer>
          <Select
            type="validate"
            name="on_death"
            value={formik?.values.on_death || null}
            className="select-field"
            placeholder={t("Please select")}
            allowClear
            onChange={(value) => {
              formik.setFieldValue("on_death", value)
            }}
            error={formik.touched.on_death && formik.errors.on_death}
            options={death_data}
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Tell this when you meet them")}</Label>
          </LabelContainer>
          <TextField
            name="message_on_meet"
            type="textarea"
            rows={4}
            showCounting
            maxLength={300}
            placeholder={t("Please enter what you want to tell")}
            className="text-area"
            value={formik.values.message_on_meet}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.message_on_meet && formik.errors?.message_on_meet
            }
          />
        </TextFieldContainer>
        <TextFieldContainer>
          <LabelContainer>
            <Label>{t("Memo・Notes")}</Label>
          </LabelContainer>
          <TextField
            name="memo"
            type="textarea"
            rows={4}
            showCounting
            maxLength={300}
            placeholder={t("Please enter a memo /notes")}
            className="text-area"
            value={formik.values.memo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.memo && formik.errors?.memo}
          />
        </TextFieldContainer>
        <ButtonWrapper>
          <Button
            background={theme.background.navBar.secondary}
            className="save-btn"
            loading={loading}
            htmlType="submit"
          >
            {data ? t("Update") : t("Save")}
          </Button>
        </ButtonWrapper>
      </form>
    </Container>
  )
}

export { RelativesFriendsForm }
