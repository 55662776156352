import React from "react"
import { t } from "i18next"
import Router from "next/router"
import { Buttons } from "./helper"
import { Button, theme } from "@project/shared"
import {
  Body,
  Label,
  Title,
  Container,
  TitleWrapper,
  ButtonWrapper,
  MobileButtonWrapper,
} from "./styles"

interface Props {
  title?: string
  id?: string
  text1: string
  text2: string
  setIsModalVisible?: any
  setID?: any
  label1: string
  label2: string
  editRoute?: string
  index?: any
  isViewer?: boolean
  detailRoute?: string
  noIndex?: boolean
}

const ListingCardComponent: React.FC<Props> = ({
  title,
  id,
  setIsModalVisible,
  setID,
  text1,
  text2,
  label1,
  label2,
  editRoute,
  index,
  isViewer,
  detailRoute,
  noIndex,
}) => {
  return (
    <Container>
      <TitleWrapper>
        {title && noIndex ? (
          <Title>{title}</Title>
        ) : title && !noIndex ? (
          <Title>
            {index}. {title}
          </Title>
        ) : (
          <Title>No. {index}</Title>
        )}
        <ButtonWrapper>
          {!isViewer ? (
            <Buttons
              id={id}
              setIsModalVisible={setIsModalVisible}
              setID={setID}
              editRoute={editRoute}
            />
          ) : (
            <Button
              background={theme.background.navBar.secondary}
              className="btn"
              onClick={() => Router.push(`${detailRoute}`)}
            >
              <div>{t("Detail")}</div>
            </Button>
          )}
        </ButtonWrapper>
      </TitleWrapper>
      <Body>
        <div className="eachBody">
          <Label>{t(`${label1}`)}</Label>
          <p className="text">{text1 || "-"}</p>
        </div>
        <div className="eachBody">
          <Label>{t(`${label2}`)}</Label>
          <p className="text">{text2 || "-"}</p>
        </div>
      </Body>
      <MobileButtonWrapper>
        {!isViewer ? (
          <Buttons
            id={id}
            setIsModalVisible={setIsModalVisible}
            setID={setID}
            editRoute={editRoute}
          />
        ) : (
          <Button
            background={theme.background.navBar.secondary}
            className="btn"
            onClick={() => Router.push(`${detailRoute}`)}
          >
            {t("Detail")}
          </Button>
        )}
      </MobileButtonWrapper>
    </Container>
  )
}

export { ListingCardComponent }
