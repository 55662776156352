import { API } from "@project/shared"

interface IGravesQueryParams {
  page?: string | number
  pageSize?: string
  user_id?: string
}

export const fetchInfiniteGraves = async ({
  queryKey,
  pageParam = 1,
}): Promise<any> => {
  const params = {} as IGravesQueryParams
  params.page = pageParam
  params.pageSize = queryKey[1].pageSize
  if (queryKey[1].user_id) {
    params.user_id = queryKey[1]?.user_id
  }
  const result = await API.get("/graves", {
    params,
  })
  result["nextPage"] = Number(pageParam) * Number(queryKey[1].pageSize) + 1
  return result
}

export const getGraveAndOfferingById = async (id) => {
  return API.get(`/graves/${id}`)
}

export const createGraveAndOffering = async (values): Promise<any> => {
  return API.post("/graves", values)
}

export const updateGraveAndOffering = async (values) => {
  const id = values.id
  return API.put(`/graves/${id}`, values)
}

export const deleteGraves = async (id) => {
  return API.delete(`/graves/${id}`)
}
